
import React, { Fragment, useContext, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import star from '../../assets/img/Star.png';
import OrdersContext from "../../_helper/Orders";
import Loading from './../../Components/Common/Loading';
import Seo from './../../Components/seo';
import Status from './../../Components/Common/Status';
const Bookings = () => {
    const { t } = useTranslation();
    const [allItems, setAllItems] = useState(null);
    const [reservedItems, setReservedItems] = useState(null);
    const [completedItems, setCompletedItems] = useState(null);
    const [pendingItems, setPendingItems] = useState(null);
    const [inProgressItems, setInProgressItems] = useState(null);

    const {
        getBookings,
        bookings,
    } = useContext(OrdersContext)

    useEffect(() => {
        getBookings();
    }, [])

    useEffect(() => {
        if (bookings != null) {
            setAllItems(bookings);
            setReservedItems(bookings.filter((item) => 'reserved' == item?.order_stage));
            setCompletedItems(bookings.filter((item) => 'completed' == item?.order_stage));
            setPendingItems(bookings.filter((item) => 'pending' == item?.order_stage));
            setInProgressItems(bookings.filter((item) => 'in_progress' == item?.order_stage));
        }
    }, [bookings])

    return (
        <Fragment>
            <Seo title={'Bookings'} schema={{}} />
            <section className="author-page-section trips-section active-section" section-name="all-trips">
                <h2>{t("TRIPS.bookings_of_my_cars")}</h2>

                <Tabs
                    defaultActiveKey="all"
                    id="uncontrolled-tab-example"
                    className="mb-3 trips-tab-wrapper"
                >
                    <Tab eventKey="all" title={`${t("TRIPS.all")} (${allItems?.length ?? 0})`}>
                        {null == allItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {allItems?.length > 0
                                    ? <>
                                        {allItems.map((t, key) => {
                                            return <Booking trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Bookings'
                                }
                            </>
                        }
                    </Tab>
                    <Tab eventKey="reserved" title={`${t("TRIPS.reserved")} (${reservedItems?.length ?? 0})`}>
                        {null == reservedItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {reservedItems?.length > 0
                                    ? <>
                                        {reservedItems.map((t, key) => {
                                            return <Booking trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Reserved Bookings'
                                }
                            </>
                        }
                    </Tab>
                    <Tab eventKey="in_progress" title={`${t("TRIPS.in_progress")} (${inProgressItems?.length ?? 0})`}>
                        {null == inProgressItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {inProgressItems?.length > 0
                                    ? <>
                                        {inProgressItems.map((t, key) => {
                                            return <Booking trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Bookings In Progress'
                                }
                            </>
                        }
                    </Tab>
                    <Tab eventKey="completed" title={`${t("TRIPS.completed")} (${completedItems?.length ?? 0})`}>
                        {null == completedItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {completedItems?.length > 0
                                    ? <>
                                        {completedItems.map((t, key) => {
                                            return <Booking trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Completed Bookings'
                                }
                            </>
                        }
                    </Tab>
                    <Tab eventKey="pending" title={`${t("TRIPS.pending")} (${pendingItems?.length ?? 0})`}>
                        {null == pendingItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {pendingItems?.length > 0
                                    ? <>
                                        {pendingItems.map((t, key) => {
                                            return <Booking trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Pending Bookings'
                                }
                            </>
                        }
                    </Tab>
                </Tabs>
            </section>
        </Fragment >
    );
}

const Booking = ({ trip }) => {
    const { t } = useTranslation();

    const {
        updateBookingStage,
    } = useContext(OrdersContext)

    const [opened, setOpened] = useState(false);
    const [stageUpdating, setStageUpdating] = useState(false);

    const setBookingInProgress = async () => {
        setStageUpdating(true);
        await updateBookingStage(trip.id, 'in_progress');
        setStageUpdating(false);
    }

    const setBookingCompleted = async () => {
        setStageUpdating(true);
        await updateBookingStage(trip.id, 'completed');
        setStageUpdating(false);
    }


    return <>
        <div className="trips-item w-100">
            <div className="item-header d-flex justify-content-between w-100" onClick={() => { setOpened(!opened) }}>
                <div className="left-block d-flex">
                    <div className="trip-date-wrapper d-flex">
                        <div className="trip-start d-flex flex-column">
                            <h4>{trip?.items[0].pickup_date}</h4>
                            <h5>{trip?.items[0].pickup_time}</h5>
                        </div>
                        <div>
                            <div className="divider"></div>
                        </div>
                        <div className="trip-end d-flex flex-column">
                            <h4>{trip?.items[0].return_date}</h4>
                            <h5>{trip?.items[0].return_time}</h5>
                        </div>
                    </div>
                    <div className="trip-price-wrapper d-flex flex-column">
                        <h4>{t("TRIPS.total")}</h4>
                        <h5>${trip?.total}</h5>
                    </div>
                </div>
                <div className="right-block d-flex">
                    <div className="d-flex gap-2 align-items-baseline">
                        <Status status={trip?.order_stage} />
                    </div>
                    <div>
                        {/* <Link target="_blank" className="text-decoration-none" to={`${process.env.PUBLIC_URL}/order/${trip?.id}`}> */}
                        <h5 className="order-number">{t("TRIPS.order_n")} {trip?.id}</h5>
                        {/* </Link> */}
                        {!opened && <h5 className="item-name">{trip?.items[0].name}</h5>}
                    </div>
                </div>
            </div>
            {opened && <div className="item-body">
                <div className="d-flex item-body-wrapper justify-content-between">
                    <div className="d-flex gap-3">
                        <div className="vehicle-img">
                            <img src={trip?.items[0].image} alt="" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex vehicle-name-wrapper">
                                <h2>{trip?.items[0].name}</h2>
                            </div>
                            {trip?.items[0].rate > 0 &&
                                <div className="rate d-flex align-items-center">
                                    <img src={star} alt="star" />
                                    <span>{trip?.items[0].rate}</span>
                                    <span className="votes">({trip?.items[0].votes})</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="align-self-end">
                        {('reserved' == trip?.order_stage && trip?.delivery_allowed) && <>
                            {stageUpdating
                                ? <Loading style={{ width: '54px', height: '54px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                : <button className="item-btn" onClick={setBookingInProgress}>Mark as Delivered</button>
                            }
                        </>}
                        {('in_progress' == trip?.order_stage && trip?.receive_allowed) && <>
                            {stageUpdating
                                ? <Loading style={{ width: '54px', height: '54px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                : <button className="item-btn" onClick={setBookingCompleted}>Mark as Received</button>
                            }
                        </>}

                    </div>
                </div>
            </div>
            }
        </div >
    </>
}

export default Bookings;
