import parse from 'html-react-parser';
import React from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import star from '../../assets/img/reviews/Star.png';
import { useState } from 'react';
import Loading from './../Common/Loading';
const AddReview = ({ onAddReview }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [review, setReview] = useState('');
    const [rate, setRate] = useState(0);

    const stars = [1, 2, 3, 4, 5];

    const handleOnAdd = async () => {
        setLoading(true);
        let valid = true;
        if (!review) {
            valid = false;
            toast.error(t("REVIEW.fill_text"));
        }
        if (!rate) {
            valid = false;
            toast.error(t("REVIEW.select_rate"));
        }
        if (valid) {
            let res = await onAddReview(review, rate);
            if (true == res) {
                toast.success(t("REVIEW.review_published"));
                setReview('');
                setRate(0);
            } else {
                toast.error(t("REVIEW.went_wrong"));
            }
        }
        setLoading(false);
    }

    return (
        <div className="reviews">
            <h5>{t("REVIEW.add_review")}</h5>
            <div className="reviews-wrapper">
                <div className='rate d-flex mb-3 align-items-center' style={{ gap: '5px' }}>
                    <b className='me-2'>{t("REVIEW.rate")}</b>
                    {stars.map((item, key) => {
                        return <div key={key} className={`star cursor-pointer ${rate >= item ? 'active' : 'disabled'}`} onClick={() => { setRate(item) }}>
                            <img src={star} alt="" />
                        </div>
                    })}
                </div>
                <div>
                    <div className='mb-2'>
                        <b>{t("REVIEW.your_review")}</b>
                    </div>
                    <textarea rows="4" value={review} onChange={(e) => { setReview(e.target.value) }} style={{
                        padding: '10px 15px',
                        border: '1px solid #D8D8D8',
                        borderRadius: '5px',
                        width: '100%',
                        resize: 'none',
                    }}></textarea>
                </div>
                <div className='d-flex align-items-center' style={{
                    marginTop: '20px',
                    gap: '15px',
                }}>
                    <button style={{

                        display: 'inline-block',
                        backgroundColor: '#3C3C3C',
                        color: '#fff',
                        borderRadius: '5px',
                        padding: '10px 28px',
                        textDecoration: 'none',
                        userSelect: 'none'
                    }} onClick={handleOnAdd}>{t("REVIEW.submit")}</button>
                    {loading == true
                        ? <div className="d-inline-block">
                            <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                        </div>
                        : ''
                    }
                </div>
                {/* {items.map((item, key) => {
                    return (<div className="review-item" key={key}>
                        <div className="d-flex review-item-wrapper">
                            <div className="review-author-image">
                                <img src={item.image} alt="" />
                            </div>
                            <div className="d-flex flex-column review-info">
                                <div>
                                    <div className="stars d-flex">
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="review-name">{item.author}</p>
                                        <span className="review-date">{item.date}</span>
                                    </div>
                                </div>
                                <div>
                                    <p className="review-text">{item.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>)
                })} */}
            </div>
        </div >
    );
}

export default AddReview;
