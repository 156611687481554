
import React, { Fragment, useContext, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import star from '../../assets/img/Star.png';
import OrdersContext from "../../_helper/Orders";
import Loading from './../../Components/Common/Loading';
import Seo from './../../Components/seo';
import Status from './../../Components/Common/Status';
const Trips = () => {
    const { t } = useTranslation();

    // const [allItems, setAllItems] = useState(null);
    const [activeItems, setActiveItems] = useState(null);
    const [bookedItems, setBookedItems] = useState(null);
    const [historyItems, setHistoryItems] = useState(null);
    const [pendingItems, setPendingItems] = useState(null);
    const {
        getTrips,
        trips,
    } = useContext(OrdersContext)

    useEffect(() => {
        getTrips();
    }, [])

    useEffect(() => {
        if (trips != null) {
            // setAllItems(trips);
            setActiveItems(trips.filter((item) => 'in_progress' == item?.order_stage));
            setBookedItems(trips.filter((item) => 'reserved' == item?.order_stage));
            setHistoryItems(trips.filter((item) => 'completed' == item?.order_stage));
            setPendingItems(trips.filter((item) => 'pending' == item?.order_stage));
        }
    }, [trips])

    return (
        <Fragment>
            <Seo title={'Trips'} schema={{}} />
            <section className="author-page-section trips-section active-section" section-name="all-trips">
                <h2>{t("TRIPS.trips")}</h2>

                <Tabs
                    defaultActiveKey="active"
                    id="uncontrolled-tab-example"
                    className="mb-3 trips-tab-wrapper"
                >
                    <Tab eventKey="active" title={`${t("TRIPS.active")} (${activeItems?.length ?? 0})`}>
                        {null == activeItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {activeItems?.length > 0
                                    ? <>
                                        {activeItems.map((t, key) => {
                                            return <Trip trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Active trips'
                                }
                            </>
                        }
                    </Tab>
                    <Tab eventKey="booked" title={`${t("TRIPS.booked")} (${bookedItems?.length ?? 0})`}>
                        {null == bookedItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {bookedItems?.length > 0
                                    ? <>
                                        {bookedItems.map((t, key) => {
                                            return <Trip trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Active trips'
                                }
                            </>
                        }
                    </Tab>
                    <Tab eventKey="history" title={`${t("TRIPS.history")} (${historyItems?.length ?? 0})`}>
                        {null == historyItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {historyItems?.length > 0
                                    ? <>
                                        {historyItems.map((t, key) => {
                                            return <Trip trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Active trips'
                                }
                            </>
                        }
                    </Tab>
                    <Tab eventKey="pending" title={`${t("TRIPS.pending")} (${pendingItems?.length ?? 0})`}>
                        {null == pendingItems
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                {pendingItems?.length > 0
                                    ? <>
                                        {pendingItems.map((t, key) => {
                                            return <Trip trip={t} key={key} />
                                        })}
                                    </>
                                    : 'No Active trips'
                                }
                            </>
                        }
                    </Tab>
                </Tabs>
            </section>
        </Fragment >
    );
}

const Trip = ({ trip }) => {
    const { t } = useTranslation();

    const [opened, setOpened] = useState(false);
    return <>
        <div className="trips-item w-100">
            <div className="item-header d-flex justify-content-between w-100" onClick={() => { setOpened(!opened) }}>
                <div className="left-block d-flex">
                    <div className="trip-date-wrapper d-flex">
                        <div className="trip-start d-flex flex-column">
                            <h4>{trip?.items[0].pickup_date}</h4>
                            <h5>{trip?.items[0].pickup_time}</h5>
                        </div>
                        <div>
                            <div className="divider"></div>
                        </div>
                        <div className="trip-end d-flex flex-column">
                            <h4>{trip?.items[0].return_date}</h4>
                            <h5>{trip?.items[0].return_time}</h5>
                        </div>
                    </div>
                    <div className="trip-price-wrapper d-flex flex-column">
                        <h4>{t("TRIPS.total")}</h4>
                        <h5>${trip?.total}</h5>
                    </div>
                </div>
                <div className="right-block d-flex">
                    <div className="d-flex gap-2 align-items-baseline">
                        <Status status={trip?.order_stage} />
                    </div>
                    <div>
                        {/* <h5 className="order-number">{t("TRIPS.order_n")} {trip?.id}</h5> */}
                        <Link target="_blank" className="text-decoration-none" to={`${process.env.PUBLIC_URL}/order/${trip?.id}`}>
                            <h5 className="order-number">{t("TRIPS.order_n")} {trip?.id}</h5>
                        </Link>
                        {!opened && <h5 className="item-name">{trip?.items[0].name}</h5>}
                    </div>
                </div>
            </div>
            {opened && <div className="item-body">
                <div className="d-flex item-body-wrapper justify-content-between">
                    <div className="d-flex gap-3">
                        <div className="vehicle-img ">
                            <img src={trip?.items[0].image} alt="" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex vehicle-name-wrapper">
                                <h2>{trip?.items[0].name}</h2>
                            </div>
                            {trip?.items[0].rate > 0 &&
                                <div className="rate d-flex align-items-center">
                                    <img src={star} alt="star" />
                                    <span>{trip?.items[0].rate}</span>
                                    <span className="votes">({trip?.items[0].votes})</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </div >
    </>
}

export default Trips;
