import React, { Fragment, useRef  } from "react";
import Footer from "../Layout/Footer/Footer";
// import "../assets/css/home-map.css"
import "../assets/css/privacy.css";
import "../assets/css/privacy-page.css";
import "../assets/css/custom.css";
import "../assets/css/style.css";
import Header from "../Layout/Header/Header";
import Seo from './../Components/seo';


const Terms = () => {
    const contentRef = useRef();

    return (
        <Fragment>
            <Seo title={'Terms and Conditions'} schema={{}} />
            <Header />
            <main className="p-0">
                <section class="privacy-policy">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-6 text-center text-white">
                                <h2>Terms of service</h2>
                                <p>Last revised: November 14, 2022</p>
                                <p>Please read these terms of service carefully as they contain important information that
                                    affects your rights, remedies, and obligations. They include an agreement to arbitrate
                                    (unless you opt out). These terms also include a prohibition of class and representative
                                    actions and non-individualized relief for all matters in either court or arbitration,
                                    various limitations and exclusions, a clause that governs the jurisdiction, venue, and
                                    governing law of disputes, except where prohibited, and obligations to comply with
                                    applicable laws and regulations.</p>
                                {/* <Link to="/terms-of-service">Reach more</Link> */}
                                <button onClick={() => {contentRef.current.scrollIntoView()}} className="ab-1-content-button">Reach More</button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <main ref={contentRef}>
                {/* <section className="privacy-policy-page-header">
                </section> */}
                <section className="privacy-chapters">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7">
                                <h3>Chapters</h3>
                                <div className="row ">
                                    <div className="col-md-6">
                                        <a href="#introduction">Introduction</a>
                                        <a href="#eligibility">Eligibility, registration, verification</a>
                                        <a href="#fees-taxes">Fees, taxes</a>
                                        <a href="#your-commitments">Your commitments</a>
                                        <a href="#content">Content</a>
                                        <a href="#prohibited-activities">Prohibited activities</a>
                                    </div>
                                    <div className="col-md-6">
                                        <a href="#other-legal">Other legal matters</a>
                                        <a href="#specific-terms">Specific terms for guests</a>
                                        <a href="#specific-terms-for">Specific terms for hosts</a>
                                        <a href="#dispute-resolution">Dispute resolution</a>
                                        <a href="#general-provisions">General provisions</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="privacy-policy-text" id="privacy-policy-text">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7">
                                <h4 id="introduction" className="privacy-text-header">
                                    Introduction
                                </h4>
                                <p>Utrip Inc. and its subsidiaries (collectively, “Utrip”, “we”, or “us”), provide an online car
                                    sharing platform that connects vehicle owners with travelers and locals seeking to book
                                    those vehicles. Utrip is accessible online including at utrip.com and as an application for
                                    mobile devices. The Utrip websites, blog, mobile applications, and associated services are
                                    collectively referred to as “the Services”. By accessing or using the Services, including by
                                    communicating with us or other Utrip users, you agree to comply with, and be legally bound
                                    by, the provisions of these Terms of Service (these “Terms”), whether or not you become a
                                    registered user of the Services. These Terms govern your access to and use of the Services
                                    and constitute a binding legal agreement between you and Utrip.</p>
                                <p>These Terms, together with the Utrip privacy policy, cancellation policy, nondiscrimination
                                    policy, applicable insurance terms and certificates, roadside assistance terms, and the
                                    additional policies (together, the “Policies”) constitute the “Agreement” between you and
                                    Utrip (each a “Party” and together, “the Parties”).</p>
                                <p><b>Modification.</b> Utrip reserves the right, at our sole discretion, to modify the Services
                                    or to modify the Agreement, including these Terms, at any time. If we modify these Terms, we
                                    will post the modification on the Services. We will also update the “Last Revised” date at
                                    the top of these Terms. If you continue to access or use the Services after we have posted a
                                    modification or have provided you with notice of a modification, you are indicating that you
                                    agree to be bound by the modified terms. If the modified terms are not acceptable to you,
                                    your sole recourse is to stop using and accessing the Services and close your Utrip Account
                                    within 30 days. If you choose to close your Utrip Account, the previous effective version of
                                    these Terms will apply to you, unless you use the Services during the intervening 30 day
                                    period, in which case the new version of these Terms will apply to you.</p>
                                <h4 id="eligibility">Eligibility, registration, verification</h4>
                                <h5>Eligibility</h5>
                                <p>The Services are intended solely for guests who meet our age requirements in the location
                                    where the vehicle is booked and hosts who are 21 or older, except in the United Kingdom
                                    where we permit hosts age 18 to list eligible vehicles. Any use of the Services by anyone
                                    that does not meet these age requirements is expressly prohibited.</p>
                                <h5>Registration</h5>
                                <p>To access certain features of the Services, you must sign up for an account with us (a “Utrip
                                    Account”). You can create a Utrip Account by providing us your first and last name, email
                                    address, and creating a password or connecting through an account with a third-party site or
                                    service (including Apple, Facebook, and Google). When you book a vehicle as a traveler or
                                    guest (“guest”), you provide us with certain additional information about yourself.
                                    Similarly, when you list a vehicle as a vehicle owner or host (“host”), you provide us with
                                    certain additional information about yourself and your vehicle(s) (if applicable). You must
                                    provide accurate, current, and complete information during the registration, booking, and/or
                                    listing process. You must keep your Utrip Account up to date at all times. Based on
                                    information you provide, Utrip may impose additional requirements for you to book a trip
                                    (e.g., providing a deposit, adding a second form of payment, buying a certain level of
                                    protection plan, or other requirements). To fight spam and abuse of the Services, Utrip has
                                    implemented reCAPTCHA Enterprise, and your use of reCAPTCHA is subject to a separate Privacy
                                    Policy and Terms of Use.</p>
                                <h5>Verification</h5>
                                <p>Where permitted, Utrip has the right, but not the obligation, to undertake screenings,
                                    checks, and engage in processes designed to (1) help verify the identities or check the
                                    backgrounds of users, including driving history and driver’s license validity and (2) verify
                                    vehicle details. Utrip does not endorse any vehicle, user, or a user’s background, or commit
                                    to undertake any specific screening process. Utrip may in its sole discretion use
                                    third-party services to verify the information you provide to us and to obtain additional
                                    related information and corrections where applicable, and you hereby authorize Utrip to
                                    request, receive, use, and store such information. Utrip may permit or refuse your request
                                    to book or list a vehicle in its sole and absolute discretion. Utrip may, but does not
                                    commit to, undertake efforts to ensure the safety of vehicles shared through the Services.
                                    We do not make any representations about, confirm, or endorse the safety, roadworthiness, or
                                    legal status of any vehicles beyond our policies that require hosts to ensure their vehicles
                                    are in safe and operable condition, legally registered to be driven on public roads, have a
                                    clean title (e.g., non-salvaged/non-branded/non-washed/non-written off), not subject to any
                                    applicable safety recalls, and otherwise satisfy our eligibility requirements.</p>
                                <p><b>Consumer Report Authorization.</b> When you attempt to book or list a vehicle, or at any
                                    time after where Utrip reasonably believes there may be an increased level of risk
                                    associated with your Utrip Account, you hereby provide Utrip with written instructions and
                                    authorize Utrip, in accordance with the Fair Credit Reporting Act, applicable consumer
                                    reporting laws, or any similar laws to obtain your personal and/or business auto insurance
                                    score, credit report, and/or conduct a background check, including a criminal background
                                    check where permissible under applicable law.</p>
                                <h4 id="fees-taxes">Fees, taxes</h4>
                                <h5>Fees</h5>
                                <p>The fees we charge for using the Services and other cost structures will be itemized at
                                    checkout for guests. You can verify the amount for your trip at checkout before you submit
                                    your trip request. Hosts can view earnings on the Host Hub and learn more about earnings
                                    breakdown here. When you provide Utrip a payment method, you authorize Utrip, or third-party
                                    service providers acting on behalf of Utrip, to store your payment credential for future use
                                    in the event you owe Utrip any money. You authorize Utrip to use stored payment credentials
                                    for balances, including for Trip costs, host fees, and guest fees (e.g., late fees, security
                                    deposits, processing fees and claims costs, and related administrative fees). In some cases,
                                    our payment processors have arrangements with card networks to automatically update stored
                                    payment credentials whenever you receive a new card (e.g., replacing an expired card or one
                                    that was reported lost or stolen) and we will rely on such updates to stored payment
                                    credentials for balances. Any use of referral travel credit is governed by the terms and
                                    conditions outlined here.</p>
                                <h5>Collection of fees</h5>
                                <p>Utrip and its service providers will employ all legal methods available to collect amounts
                                    due, including the engagement of collection agencies or legal counsel. Utrip, or the
                                    collection agencies we retain, may also report information about your Utrip Account to
                                    credit bureaus. As a result, late payments, missed payments, or other defaults on your Utrip
                                    Account may be reflected in your credit report. In addition to the amount due, delinquent
                                    accounts or chargebacks will be charged with fees and/or charges that are incidental to the
                                    collection of delinquent accounts or chargebacks including, but not limited to, collection
                                    fees, convenience fees, and/or other third party charges. If you wish to dispute the
                                    information Utrip reported to a credit bureau (i.e., Experian, Equifax, or TransUnion),
                                    please contact help.utrip.com. If you wish to dispute the information a collection agency
                                    reported to a credit bureau regarding your Utrip Account, you must contact the collection
                                    agency directly.</p>
                                <h5>Taxes</h5>
                                <p>In certain jurisdictions, Utrip may enable the collection and remittance of certain taxes
                                    from or on behalf of guests or hosts, based on existing and future tax regulations,
                                    including marketplace facilitator or car sharing regulations. The amount of taxes, if any,
                                    collected and remitted by Utrip will be visible to, and separately stated, to both guests
                                    and hosts on their respective trip related documents and invoices. Where Utrip is
                                    facilitating the collection and remittance of taxes, hosts are not permitted to collect the
                                    same taxes on the Services in relation to their vehicle sharing in that jurisdiction.</p>
                                <h4 id="your-commitments">Your commitments</h4>
                                <p>You agree that you will always use your Utrip Account and the Services in compliance with
                                    these Terms, applicable law, and any other policies and standards provided to you by Utrip.
                                </p>
                                <p><b>Account Activity.</b> You are, and will be solely responsible for, all of the activity
                                    that occurs through your Utrip Account. Keep your Utrip Account information, including your
                                    password, secure. You agree that you will not disclose your password to any third party and
                                    that you will take sole responsibility for any activities or actions under your Utrip
                                    Account, whether or not you have authorized such activities or actions. You will immediately
                                    notify Utrip of any actual or suspected unauthorized use of your Utrip Account. We are not
                                    responsible for your failure to comply with this clause, or for any delay in shutting down
                                    or protecting your Utrip Account unless you have reported unauthorized access to us.</p>
                                <h4 id="content">Content</h4>
                                <p><b>Utrip Content and User Content License.</b> Subject to your compliance with the provisions
                                    of these Terms, Utrip grants you a limited, revocable, non-exclusive, non-transferable
                                    license, to access and view any Utrip and/or user content to which you are permitted access,
                                    solely for your personal and non-commercial purposes. You have no right to sublicense the
                                    license rights granted in this section. No licenses or rights are granted to you by
                                    implication or otherwise under any intellectual property rights owned or controlled by Utrip
                                    or its licensors, except for the licenses and rights expressly granted in these Terms.</p>
                                <p><b>User Content.</b> We may, in our sole discretion, permit you to post, upload, publish,
                                    submit or transmit content through the Services such as photographs of you and your
                                    vehicle(s), reviews, feedback, and descriptions of you, your vehicle, or trip. By making
                                    available any content on or through the Services, or through Utrip promotional campaigns,
                                    you grant Utrip a worldwide, irrevocable, perpetual (or for the term of the protection),
                                    non-exclusive, transferable, royalty-free license, with the right to sublicense, to use,
                                    view, copy, adapt, modify, distribute, transfer, publicly display, publicly perform,
                                    transmit, stream, broadcast, access, view, and otherwise exploit such content on, through,
                                    by means of, or to promote or market the Services. Except as described above with respect to
                                    Utrip photography provided to hosts, Utrip does not claim any ownership rights in any such
                                    content and nothing in these Terms will be deemed to restrict any rights that you may have
                                    to use and exploit any such content.</p>
                                <p><b>Copyright Protection.</b> We respond to notices of alleged copyright infringement and
                                    terminate Utrip Accounts of repeat infringers according to the process set out in the US
                                    Digital Millennium Copyright Act and similar laws. If you think a user is violating your
                                    copyright(s) and want to notify us, you can find information about submitting notices here.
                                </p>
                                <h4 id="prohibited-activities">Prohibited activities</h4>
                                <p>In connection with your use of or access to the Services, you agree that you will not, nor
                                    advocate, encourage, request, or assist any third party to:</p>
                                <h5>Violate any law, including:</h5>
                                <p>Breach, violate, and/or circumvent any local, state, provincial/territorial, regional, or
                                    national law or other law or regulation, or any order of a court, including, without
                                    limitation, airport regulations and tax regulations, licensing or registration requirements,
                                    or third-party rights</p>
                                <p>Post false, inaccurate, misleading, defamatory, or libelous content</p>
                                <p>Infringe, reproduce, perform, display, distribute, reverse engineer, or prepare derivative
                                    works from content that belongs to or is licensed to Utrip, or that comes from the Services
                                    and belongs to another Utrip user or to a third party, including works covered by any
                                    copyrights, trademark, patent, or other intellectual property, privacy, publicity, moral, or
                                    contractual rights, except with prior express written permission of Utrip</p>
                                <h5>Dilute, tarnish, or otherwise harm the Utrip brand in any way, including:</h5>
                                <p>Through unauthorized use of the Services and/or user content</p>
                                <p>Registering and/or using "Utrip" or derivative terms in domain names, trade names,
                                    trademarks, or otherwise</p>
                                <p>Registering and/or using domain names, trade names, trademarks, social media account names,
                                    or other means of identification that closely imitate or are confusingly similar to Utrip
                                    domains, trademarks, taglines, promotional campaigns, or Utrip and/or user content</p>
                                <h5>Provide or submit any false or misleading information, including:</h5>
                                <p>False name, date of birth, driver’s license details, payment method, insurance, or other
                                    personal information</p>
                                <p>In relation to a claim (for example about damage to a vehicle)</p>
                                <p>By registering for a Utrip Account on behalf of an individual other than yourself</p>
                                <p>Impersonating any person or entity, or falsifying or otherwise misrepresenting yourself or
                                    your affiliation with any person or entity</p>
                                <h5>Fail to honor your commitments, including:</h5>
                                <p>Fail to pay fees, penalties, or other amounts owed to Utrip or another user</p>
                                <p>Fail, as either a guest or host, to timely deliver, make available, or return any vehicle and
                                    optional Extras, unless you have a valid reason</p>
                                <p>Use the Services to find a host or guest, and then complete a transaction partially or wholly
                                    independent of the Services, for any reason including but not limited to circumventing the
                                    obligation to pay any fees related to the provision of the Services by Utrip (aka, gray
                                    market transactions, which do not necessarily require the exchange of money)</p>
                                <p>Transfer your Utrip Account and/or user ID to another party without our consent</p>
                                <p>Leave a vehicle unlocked or running with the keys inside, except where instructed to do so
                                    directly by Utrip in certain limited circumstances</p>
                                <h5>Harm or threaten to harm users of our community, including:</h5>
                                <p>Harass, stalk, or defame any other Utrip user or collect or store any personally identifiable
                                    information about any other user other than for purposes of transacting as a host or guest
                                    in accordance with these Terms</p>
                                <p>Engage in physically or verbally abusive or threatening conduct</p>
                                <p>Use the Services to transmit, distribute, post, or submit any information concerning any
                                    other person or entity, including without limitation, photographs of others without their
                                    permission, personal contact information, payment method details, or account numbers</p>
                                <p>Treat anyone differently based on the way they look, who they love, what they believe, how
                                    they self-identify, where they are from, or when they were born. Discrimination of any kind
                                    is not tolerated in the Utrip community</p>
                                <p>Sue or assert legal claims against Utrip or a Utrip user in any manner prohibited or waived
                                    by these Terms</p>
                                <h5>Use the Services for your own unrelated purposes, including to:</h5>
                                <p>Contact another Utrip user for any purpose other than in relation to a booking, vehicle,
                                    listing, or the use of the Services by such user</p>
                                <p>Commercialize any content found on the Services or software associated with the Services,
                                    including reviews</p>
                                <p>Harvest or otherwise collect information about users without their and our consent</p>
                                <p>Recruit or otherwise solicit any user to join third-party services or websites that are
                                    competitive to Utrip, without our prior written approval</p>
                                <h5>Interfere with the operation of the Services, including by:</h5>
                                <p>Interfering with any other user’s listings</p>
                                <p>Using the Services in connection with the distribution or posting of unsolicited commercial
                                    messages (e.g., spam)</p>
                                <p>Distributing viruses or any other technologies such as cancel bots, Trojan horses, harmful
                                    code, flood pings, denial-of-service attacks, backdoors, packet or IP spoofing, forged
                                    routing or e-mail address information, or similar methods or technology that may disrupt or
                                    interfere with the operation or provision of the Services, or harm Utrip or the interests or
                                    property of others</p>
                                <p>Bypassing robot exclusion headers, interfering with the working of the Services, or imposing
                                    an unreasonable or disproportionately large load on our infrastructure</p>
                                <p>Systematically retrieving data or other content from the Services to create or compile,
                                    directly or indirectly, a collection, compilation, database, directory, or the like, whether
                                    by manual methods, or through the use of bots, crawlers, spiders, or otherwise</p>
                                <p>Using, displaying, mirroring, or framing the Services or any individual element within the
                                    Services, the Utrip name, any Utrip trademark, logo, or other proprietary information, or
                                    the layout and design of any page or form contained on a page in the Services, without the
                                    express written consent of Utrip</p>
                                <p>Accessing, tampering with, or using non-public areas of the Services, our computer systems,
                                    or the technical delivery systems of our service providers</p>
                                <p>Attempting to probe, scan, or test the vulnerability of any of our system or network or
                                    breach any security or authentication measures</p>
                                <p>Avoiding, bypassing, removing, deactivating, impairing, descrambling, or otherwise
                                    circumventing any technological measure implemented by Utrip or any of our service providers
                                    or any other third party (including another user) to protect the Services</p>
                                <p>Forging any TCP/IP packet header or any part of the header information in any email or
                                    newsgroup posting, or in any way using the Services to send altered, deceptive, or false
                                    source-identifying information</p>
                                <p>Attempting to decipher, decompile, disassemble, or reverse engineer any of the software used
                                    to provide the Services</p>
                                <p>Endeavoring to circumvent a suspension, termination, or closure of your Utrip Account or the
                                    account of another Utrip user, including, but not limited to, creating a new Utrip Account
                                    or listing vehicles affiliated with or registered to a Utrip Account holder that has been
                                    suspended, terminated, or closed</p>
                                <h4 id="other-legal">Other legal matters</h4>
                                <p><b>Violations.</b> Utrip has the right, but not the obligation, to investigate, pursue, and
                                    seek to prosecute, litigate, or refer to law enforcement, violations of the Agreement to the
                                    fullest extent permissible by the law.</p>
                                <p>Utrip reserves the right, at any time and without prior notice, in accordance with applicable
                                    law, to remove or disable access to any content that Utrip, at its sole discretion,
                                    considers to be objectionable for any reason, in violation of these Terms, or otherwise
                                    harmful to the Services or our community. If we believe you are abusing Utrip, our users, or
                                    any other person in any way or violating the letter or spirit of any of these Terms, we may,
                                    in our sole discretion and without limiting other remedies, limit, suspend, or terminate
                                    your Utrip Account and access to the Services, remove hosted content, deny a damage claim,
                                    remove or demote your listings, reduce or eliminate any discounts, and take technical and/or
                                    legal steps to prevent you from using the Services. Additionally, we reserve the right to
                                    refuse or terminate access to the Services to anyone for any reason at our discretion to the
                                    full extent permitted under applicable law.</p>
                                <p><b>Policy enforcement.</b> When an issue arises, we may consider the user’s performance
                                    history and the specific circumstances in applying our Policies. We may choose to be more
                                    lenient with policy enforcement in an effort to do the right thing, subject to our sole and
                                    absolute discretion.</p>
                                <p><b>Communications with you.</b> You agree that Utrip may contact you by electronic means
                                    (e.g., electronic mail; notifications via Utrip messaging; app notification) in lieu of any
                                    requirement for mailed notices. To contact you more efficiently, you agree that we may at
                                    times also contact you using autodialed or prerecorded message calls or text messages at
                                    your phone number(s). We may place such calls or texts primarily to confirm your signup,
                                    provide notices regarding your Utrip Account or Utrip Account activity, investigate or
                                    prevent fraud, collect a debt owed to us, or communicate urgent messages. We may share your
                                    phone number(s) with service providers with whom we contract to assist us in pursuing these
                                    interests. We will not share your phone number(s) with third parties for their own purposes
                                    without your consent. Standard telephone minute and text and data charges may apply. Where
                                    Utrip is required to obtain your consent for such communications, you may choose to revoke
                                    your consent.</p>
                                <p>You authorize Utrip and its service providers, without further notice or warning and in our
                                    discretion, to monitor or record telephone conversations you or anyone acting on your behalf
                                    has with Utrip or its agents for quality control and training purposes. You acknowledge and
                                    understand that your communications with Utrip may be overheard, monitored, or recorded
                                    without further notice or warning. If you do not wish to have your call recorded, please
                                    contact us instead in writing through help.utrip.com.</p>
                                <p><b>Non-disparagement.</b> The Parties agree that they will not take any action that will harm
                                    the reputation of the other Party, or which would reasonably be expected to lead to unwanted
                                    or unfavorable publicity to either of the other Party.</p>
                                <p><b>Insurance and protection plans.</b> Utrip is not an insurance company and does not insure
                                    hosts or guests. Host and guest protection plans made available through the Services are in
                                    no way related. To be eligible for the benefits of a protection plan, hosts and guests must
                                    comply with these Terms. Protection plans are available through the Services only in the
                                    United States, the United Kingdom, Canada (excluding any of the territories and the
                                    provinces of Manitoba and Saskatchewan), and Australia. Please refer to the specific terms
                                    for guests and specific terms for hosts sections below for additional information based on
                                    the nature of your use of the Services.</p>
                                <h4 id="specific-terms">Specific terms for guests</h4>
                                <p>The following sections also apply if you book a vehicle using the Services:</p>
                                <h5>Guest commitments</h5>
                                <p>As a guest, you commit that you will be a legally licensed driver and provide proof to the
                                    host or via the Services of a current, valid driver’s license. You will treat the vehicle
                                    and any applicable Extras well and will take all reasonable measures to return the vehicle
                                    and any applicable Extras on time and in essentially the same condition as received. You
                                    will not allow anyone other than a person listed in the trip details as an Approved Driver
                                    to drive the vehicle you booked.</p>
                                <h5>Guest financial responsibility for physical damage to the vehicle</h5>
                                <p>The guest that booked the trip (“primary guest”) is financially responsible for all physical
                                    damage to or theft of a booked vehicle that occurs during a trip, plus any additional costs
                                    and fees resulting from damage of any kind to the vehicle, regardless of who is found to be
                                    at fault. This responsibility applies whether the primary guest has their own auto insurance
                                    or not.<br />
                                    Primary guests may be insured against damage to the booked vehicle under their own
                                    automobile policies. When you book a vehicle on Utrip, you agree that if any damage occurs
                                    to the booked vehicle during the booked trip, you will work with Utrip to make a claim for
                                    coverage under any policy of insurance that applies to the loss.<br />
                                    The primary guest can limit the amount they are obligated to pay out of their own pocket in
                                    the event there is damage to the booked vehicle during the booked trip by choosing a
                                    protection plan on the Services. The limitation on the amount a primary guest may have to
                                    pay out of pocket included in any protection plan only applies (1) if the primary guest and
                                    any Approved Driver abide by these Terms and (2) to physical damage that is not mechanical
                                    or interior damage.<br />
                                    Learn more about Guest protection plans including insurance in the US, the UK, and Canada.
                                    Guest protection plan details for Australia are described here.</p>
                                <h5>Auto liability insurance</h5>
                                <p>Most hosts offering a vehicle for sharing on the Services cannot offer liability insurance to
                                    you. All protection plans offered on the Services include coverage under an automobile
                                    liability insurance policy, except in Australia. In the US, the policy is issued to Utrip by
                                    Travelers Excess and Surplus Lines Company and does not provide a defense or indemnification
                                    for any claim asserted by Utrip. In the UK, this policy is arranged by Aon UK Ltd. and
                                    purchased from Aioi Nissay Dowa Insurance UK Ltd. In Canada (excluding any of the
                                    territories and the provinces of British Columbia, Manitoba, and Saskatchewan) this policy
                                    is purchased from Economical Insurance and in British Columbia, Insurance Corporation of
                                    British Columbia.</p>
                                <h5>Booking with Commercial Hosts</h5>
                                <p>Where a host can offer guests at least legally required minimum insurance through their own
                                    commercial or rental policy, the host may choose to decline insurance on the Services and
                                    provide its own policy directly to you as the guest. We refer to these hosts as “Commercial
                                    Hosts.” You can determine directly in the vehicle listing whether the vehicle is offered by
                                    a Commercial Host.</p>
                                <p><b>If you book with a Commercial Host, no third-party automobile liability insurance,
                                    financial responsibility limitations for physical damage, or roadside assistance is made
                                    available via the Services by Utrip or any third-party insurance partners, brokers, or
                                    producers. The Commercial Host will bill you directly for any applicable protection plan
                                    after booking, not through the Services. In these circumstances, a Commercial Host may
                                    require you to sign additional paperwork, provide a deposit, or pay additional fees,
                                    costs, or taxes after booking, for instance when picking up the vehicle. A Commercial
                                    Host may also have additional requirements that differ from Utrip requirements (like
                                    requiring a credit card deposit).</b> By booking a vehicle where a Commercial Host is
                                    providing protection, you agree that the Commercial Host may impose additional terms and
                                    fees after booking.</p>
                                <h4 id="specific-terms-for">Specific terms for hosts</h4>
                                <p>The following sections also apply if you share your vehicle through the Services:</p>
                                <h5>Host commitments</h5>
                                <p>As a host, you commit that you will provide a safe and legally registered and insured
                                    vehicle, with current license plates, with a clean (non-salvage/branded/written off) title,
                                    and in good mechanical condition. You will provide such vehicle on time but only to a guest
                                    who is listed on the Services as an Approved Driver for the trip. You commit that your
                                    listings will be complete and accurate and you will honor all representations made in your
                                    listings, including honoring the price quoted to a guest. You will not cancel a booking for
                                    the purpose of seeking a higher price from a guest. You will not offer any vehicle or
                                    optional Extra that you do not yourself own or have authority to share or that may not be
                                    shared for compensation pursuant to the terms and conditions of any agreement with a third
                                    party, including, but not limited to, a lease or financing agreement.</p>
                                <h5>Vehicle availability</h5>
                                <p>Once a trip is booked, you must make the vehicle available or deliver the vehicle as expected
                                    by the guest. If you offer the guest the option to pick up your vehicle at a persistent
                                    specified location, you must supply the location of the vehicle accurately to Utrip and
                                    ensure that the vehicle is available at that location at the beginning of the reservation
                                    period. In order to qualify for available protection plans, you must verify that a
                                    prospective guest has a current, valid driver’s license before you provide the guest your
                                    vehicle, and ensure the driver’s license matches the name on the reservation and that the
                                    person picking up the vehicle appears to match the photograph on a facially valid driver’s
                                    license.</p>
                                <h5>Airport delivery; personal vehicle sharing regulations</h5>
                                <p>Some airports where you offer delivery may take the position that you must have a permit to
                                    use airport premises and remit fees. While Utrip does not believe that rental car permits
                                    should apply to peer-to-peer car sharing, not all airport authorities agree with this
                                    position. Learn more about airport delivery here.</p>
                                <h4 id="dispute-resolution">Dispute resolution</h4>
                                <p><b>Applicable law.</b> The below Agreement to Arbitrate evidences a transaction involving
                                    interstate commerce and is therefore governed by the Federal Arbitration Act and the
                                    applicable procedural rules of FairClaims or AAA, as applicable (see "Arbitration
                                    procedures" below). To the extent state law is applicable to the Agreement to Arbitrate, the
                                    Parties agree that the substantive law of the state of Arizona will apply, without regard to
                                    its conflict of law provisions.</p>
                                <p><b>Costs of arbitration.</b> If you initiate arbitration proceedings, you will be responsible
                                    for certain costs. Where the relief sought is $25,000 or less (not including attorneys’ fees
                                    and expenses), you will be responsible for the initial filing fee, capped at $375, and Utrip
                                    will cover any additional fees or costs. Where the relief sought is $25,001 or more (not
                                    including attorneys’ fees and expenses), you and Utrip will each pay your own arbitration
                                    fees consistent with the rules set by FairClaims (or AAA, as applicable) at the time the
                                    claim was made, unless otherwise stated in these Terms.</p>
                                <p><b>Severability.</b> With the exception of the provisions in the below section “Prohibition
                                    of class and representative actions and non-individualized relief,” if an arbitrator or
                                    court decides that any part of the Agreement to Arbitrate is invalid or unenforceable, the
                                    other parts of the Agreement to Arbitrate shall still apply. If an arbitrator or court
                                    decides that any of the provisions in the section “Prohibition of class and representative
                                    actions and non-individualized relief” is invalid or unenforceable, then the entirety of the
                                    Agreement to Arbitrate shall be null and void.</p>
                                <h4 id="general-provisions">General provisions</h4>
                                <h5>Termination</h5>
                                <p>You may discontinue your use of the Services at any time and Utrip may terminate your access
                                    to the Services and remove any listings for any reason or no reason to the extent
                                    permissible under applicable law. Termination of access to the Services will not release a
                                    Party from any obligations it incurred prior to the termination and Utrip may retain and
                                    continue to use any information, including but not limited to photography, previously
                                    provided by you. Termination of the Agreement will not have any effect on the disclaimers,
                                    waiver or liability limitations, or legal disputes provisions under the Agreement and/or any
                                    fees due, and all of those terms will survive any termination of the Agreement.</p>
                                <h5>No vehicle transfer or assignment</h5>
                                <p>Except as otherwise provided herein, guests and hosts agree that nothing in these Terms
                                    constitutes an actual or purported transfer or assignment of any right or interest in a
                                    vehicle or optional Extras shared through the Services.</p>
                                <h5>Disclaimers</h5>
                                <p>UTRIP PROVIDES SERVICES THAT ENABLE THE SHARING OF VEHICLES AND OPTIONAL EXTRAS BETWEEN HOSTS
                                    AND GUESTS. EXCEPT AS OTHERWISE PROVIDED IN THESE TERMS, UTRIP DOES NOT ITSELF PROVIDE
                                    VEHICLE SHARING, RENTAL SERVICES, AND/OR INSURANCE SERVICES AND IS NOT RESPONSIBLE FOR ANY
                                    OF THE ACTS OR OMISSIONS OF ANY OF THE USERS OF ITS SERVICES, THE MANUFACTURER OF THE
                                    VEHICLE OR ANY OPTIONAL EXTRAS, OR ANY THIRD PARTY PROVIDER OF SERVICES (E.G. IN-VEHICLE GPS
                                    OR OTHER SYSTEMS).<b> THE SERVICES ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND,
                                        EITHER EXPRESS OR IMPLIED. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WITHOUT LIMITING
                                        THE FOREGOING, UTRIP EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                                        A PARTICULAR PURPOSE, QUIET ENJOYMENT, OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
                                        OUT OF COURSE OF DEALING OR USAGE OF TRADE.</b> Utrip makes no warranty that the
                                    Services, including, but not limited to, the listing and/or any vehicle or optional Extra,
                                    will meet your requirements or be available on an uninterrupted, secure, or error-free
                                    basis. Utrip makes no warranty regarding the quality of any listings, vehicles, hosts,
                                    guests, Extras, the Services, or any content or the accuracy, timeliness, truthfulness,
                                    completeness, or reliability of any content obtained through the Services. No advice or
                                    information, whether oral or written, obtained from Utrip, Utrip Insurance Agency, or its
                                    service providers or through the Services or content, will create any warranty not expressly
                                    made herein.</p>
                                <h5>Not a rental car company</h5>
                                <p>Utrip is not a rental car company. It does not own a fleet of vehicles, and is not in the
                                    business of renting vehicles to the public. Utrip provides an online platform where vehicle
                                    owners and those in need of a vehicle can meet and share vehicles amongst themselves subject
                                    to these Terms.</p>
                                <h5>General</h5>
                                <p>Utrip does not appoint you or any other user as its employee, mandatory, legal agent, or form
                                    any kind of legal partnership or joint venture. You are not authorized to make any
                                    commitments on behalf of Utrip and Utrip will not make commitments on your behalf, except as
                                    contemplated by the Services or expressly stated in these Terms.</p>
                                <p>The Agreement, including these Terms, states the entire understanding between you and Utrip
                                    concerning your access to and use of the Services and supersedes any earlier verbal or
                                    written communications between us. With the exception of appointing a custodian to manage
                                    your vehicles on your behalf, you may not delegate your legal obligations or transfer any of
                                    your rights, in whole or in part, to any third party without advance written consent of
                                    Utrip. You will remain responsible for your obligations hereunder in any event. A Utrip
                                    Director or Officer must agree to any modification or waiver of any term of the Agreement in
                                    writing. Headings are for reference purposes only and do not limit the scope or extent of
                                    such section.</p>
                            </div>
                        </div>
                    </div>
                </section >
            </main >
            <Footer />
        </Fragment >
    );
}

export default Terms;
