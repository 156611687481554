import React, { Fragment, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ConfigDB from '../Config/ThemeConfig';
import CustomizerContext from '../_helper/Customizer';
import Loader from './Loader';

const AppLayout = ({ children, classNames, ...rest }) => {
  const { layout } = useContext(CustomizerContext);
  const { sidebarIconType } = useContext(CustomizerContext);

  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const sideBarIcon = localStorage.getItem('sidebar_icon_type') || sidebarIconType;
  const location = useLocation();
  const animationTheme = localStorage.getItem('animation') || ConfigDB.data.router_animation;

  return (
    <Fragment>
      {/* <Loader /> */}
      <TransitionGroup {...rest}>
        <CSSTransition key={location.key} timeout={100} classNames={animationTheme} unmountOnExit>
          <Outlet />
        </CSSTransition>
      </TransitionGroup>
      <ToastContainer theme="colored" />
    </Fragment>
  );
};
export default AppLayout;
