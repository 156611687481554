import React, { Fragment } from "react";
import Slider from "react-slick";
import Header2 from "../Layout/Header/Header2";
import Footer from "../Layout/Footer/Footer";
import Seo from '../Components/seo';
// import "../assets/css/home-map.css"
import "../assets/css/custom.css";
import "../assets/css/contact.css";
import "../assets/css/style.css";

import sanFrancisco from "../assets/img/contact/sanFrancisco.png";
import london from "../assets/img/contact/london.png";
import delhi from "../assets/img/contact/delhi.png";
import { useTranslation } from "react-i18next";

const Contact = () => {


    const sliderSettings = {
        dots: true,
        arrows: false,
        slidesToShow: 1,
        infinite: true,
        // centerMode: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 6000,
        lazyLoad: true,
    };
    const contacts = [
        {
            image: sanFrancisco,
            city: 'San Francisco',
            email: {
                value: 'sanfrancisco@utrip.co',
                label: 'sanfrancisco@utrip.co',
            },
            phone: {
                value: ':+14159311616',
                label: '(415) 931-1616',
            },
        },
        {
            image: london,
            city: 'London',
            email: {
                value: 'london@utrip.co',
                label: 'london@utrip.co',
            },
            phone: {
                value: ':+14159311616',
                label: '(415) 931-1616',
            },
        },
        {
            image: delhi,
            city: 'New Delhi',
            email: {
                value: 'newdelhi@utrip.co',
                label: 'newdelhi@utrip.co',
            },
            phone: {
                value: ':+14159311616',
                label: '(415) 931-1616',
            },
        }
    ];
    const { t } = useTranslation();
    return (
        <Fragment>
            <Seo title={'Contact'} schema={{}} />
            <Header2 />
            <main className="contact-main">
                <section className="contact">
                    <div className="wrapper">
                        <div className="container">
                            <div className="contact-main-text-content">
                                <div className="contact-main-h1">
                                    <h1>{t("CONTACT.title")}</h1>
                                </div>
                                <div className="contact-main-content-text">
                                    <p>
                                    {t("CONTACT.description")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="contact-main-help-centers">
                            <div className="container">
                                <div className="help-center-contacts row">
                                    {contacts.map((item) => {
                                        return (
                                            <div className="contact-city col-md-3 px-0" key={item.city}>
                                                <div>
                                                    <img src={item.image} alt="" className="px-0 mw-100" />
                                                </div>
                                                <div className="help-center-section-content">
                                                    <p className="center-city-name">{item.city}</p>
                                                    <div className="help-center-section-border"></div>
                                                    <a href={`mailto:${item.email.value}`} className="help-center-mail">
                                                        {item.email.label}
                                                    </a>
                                                    <a href={`tel:${item.phone.value}`} className="help-center-number">{item.phone.label}</a>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>
    );
}

export default Contact;
