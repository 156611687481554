import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import { Spinner } from '../AbstractElements';
import OrdersContext from "../_helper/Orders";
import "../assets/css/order-result.css";
import Loading from './../Components/Common/Loading';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
const PaymentResult = () => {
    const history = useNavigate();

    const [status, setStatus] = useState(null);
    const [statusText, setStatusText] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    let { id } = useParams();
    const { checkOrderStatus, orderData } = useContext(OrdersContext);
    useEffect(() => {
        if (!!id) {
            checkOrderStatus(id);
        }
    }, [id]);

    const returnToTrips = () => {
        history(`${process.env.PUBLIC_URL}/trips`);
    }
    const { t } = useTranslation();
    useEffect(() => {
        if (orderData != null) {
            switch (orderData.status) {
                case 'completed':
                case 'processing':
                    setStatus('Paid!');

                    setStatusText(<>{t("PAYMENT_RESULT.your_order_has_been")} <span class="status">{t("PAYMENT_RESULT.paid")}</span></>);
                    setShowDetails(true);
                    break;
                case 'failed':
                    setStatus('Failed!');
                    setStatusText(<><span class="status">{t("PAYMENT_RESULT.failed")}</span> {t("PAYMENT_RESULT.to_create_order")}</>);
                    break;
                case 'cancelled':
                    setStatus('Cancelled!');
                    setStatusText(<>{t("PAYMENT_RESULT.your_order_has_been")} <span class="status">{t("PAYMENT_RESULT.cancelled")}</span></>);
                    break;
                case 'rejected':
                    setStatus('Rejected!');
                    setStatusText(<>{t("PAYMENT_RESULT.your_order_has_been")} <span class="status">{t("PAYMENT_RESULT.rejected")}!</span></>);
                    break;
                case 'checkout-draft':
                case 'pending':
                    setStatus('Pending!');
                    setStatusText(<>{t("PAYMENT_RESULT.your_order_is")} <span class="status">{t("PAYMENT_RESULT.awaiting_payment")}</span></>);
                    break;
                case false:
                default:
                    setStatus('Not Found!');
                    setStatusText(<>{t("PAYMENT_RESULT.your_order_was")} <span class="status">{t("PAYMENT_RESULT.not_found")}</span></>);
                    break;
            }
        }
    }, [orderData])

    return (
        <Fragment>
            <section class="hero-block">
                <div class="order-n">
                    <h2>{t("PAYMENT_RESULT.order_n")} {id}</h2>
                    {
                        status == null
                            ? <div className="d-flex align-items-center justify-content-center my-5">
                                <Loading style={{ width: '90px', height: '90px' }} spinnerStyle={{ borderWidth: '5px' }} />
                            </div>
                            : <>
                                <p class="status-text">
                                    {statusText}
                                    {!!showDetails && <><br /> {t("PAYMENT_RESULT.your_journey_start")} <span class="clock-p">{orderData.dates.pickup_date}</span> {t("PAYMENT_RESULT.at")} <span class="clock-p"> {orderData.dates.pickup_time}</span></>}
                                </p>
                                <div class="line"></div>
                                <p class="find-more">{parse(t("PAYMENT_RESULT.find_more"))}</p>
                                <Link class="learn-more" to="/trips">{t("PAYMENT_RESULT.learn_more")}</Link>
                            </>
                    }

                </div>
            </section>
        </Fragment>
    );
}

export default PaymentResult;
