import React, { useState, useContext, useCallback } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DatePickerInline from "../VehicleForm/datepicker-inline";
import TimepickerInline from "../VehicleForm/timepicker-inline";
import dateTimePickerPng from "../../assets/img/lucide_calendar-clock.png";
import './booked-date.css';
import ProductsContext from "../../_helper/Products";
import { useEffect } from "react";
import DatePicker from "../VehicleForm/datepicker";
import SelectMenu from "../VehicleForm/selectmenu";
import { useTranslation } from "react-i18next";

const AddDate = ({ onPublish }) => {
    const { t } = useTranslation();

    const {
        defaultTimeOptions,
    } = useContext(ProductsContext);

    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');

    const [errors, setErrors] = useState({
        startDate: false,
        startTime: false,
        endDate: false,
        endTime: false,
    });
    const handleOnUpdate = () => {
        if (isFormValid()) {
            onPublish({
                startDate,
                startTime,
                endDate,
                endTime,
            });
            setStartDate('');
            setStartTime('');
            setEndDate('');
            setEndTime('');
        }
    }

    const isFormValid = () => {
        let newErrors = { ...errors };
        let isValid = true;
        if (!startDate) {
            newErrors.startDate = true;
            isValid = false;
        } else {
            newErrors.startDate = false;
        }
        if (!startTime) {
            newErrors.startTime = true;
            isValid = false;
        } else {
            newErrors.startTime = false;
        }
        if (!endDate) {
            newErrors.endDate = true;
            isValid = false;
        } else {
            newErrors.endDate = false;
        }
        if (!endTime) {
            newErrors.endTime = true;
            isValid = false;
        } else {
            newErrors.endTime = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const clearValues = () => {
        setStartDate('');
        setStartTime('');
        setEndDate('');
        setEndTime('');
        setErrors({
            startDate: false,
            startTime: false,
            endDate: false,
            endTime: false,
        });
    }

    return (
        <>
            <section className="busyDaysWrapper">
                <div className="d-flex date-wrapper mb-2">
                    <div className="d-flex-flex-column w-100">
                        <div>
                            <div className="d-flex busy-days-body add-busy-days">
                                <label>
                                    <span className="label">{t("BOOKED_DATE.start_date")}</span>
                                    <DatePicker
                                        handleOnChange={(value) => { setStartDate(value) }}
                                        selected={startDate}
                                        placeholder={t("BOOKED_DATE.start_date")}
                                        max={endDate}
                                        className={errors?.startDate ? 'invalid' : ''}
                                    />
                                </label>

                                <label className="time" htmlFor={`time-picker-add`}>
                                    <span className="label">{t("BOOKED_DATE.start_date")}</span>
                                    <SelectMenu
                                        id={`time-picker-add`}
                                        name={`time-picker-add`}
                                        className={errors?.startTime ? 'invalid' : ''}
                                        options={defaultTimeOptions}
                                        handleOnChange={(value) => { setStartTime(value) }}
                                        selected={startTime}
                                        selectValue={t("BOOKED_DATE.start_time")}
                                    />
                                </label>
                                <label>
                                    <span className="label">{t("BOOKED_DATE.end_date")}</span>
                                    <DatePicker
                                        handleOnChange={(value) => { setEndDate(value) }}
                                        selected={endDate}
                                        placeholder={t("BOOKED_DATE.end_date")}
                                        min={startDate}
                                        className={errors?.endDate ? 'invalid' : ''}
                                    />
                                </label>
                                <label className="time" htmlFor={`time-picker-add`}>
                                    <span className="label">{t("BOOKED_DATE.end_time")}</span>
                                    <SelectMenu
                                        id={`time-picker-add`}
                                        name={`time-picker-add`}
                                        className={errors?.endTime ? 'invalid' : ''}
                                        options={defaultTimeOptions}
                                        handleOnChange={(value) => { setEndTime(value) }}
                                        selected={endTime}
                                        selectValue={t("BOOKED_DATE.end_time")}
                                    />
                                </label>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <button className="update-btn" onClick={handleOnUpdate}>{t("BOOKED_DATE.add")}</button>
                                {(!!startDate || !!startTime || !!endDate || !!endTime) &&
                                    <button className="btn btn-danger" onClick={clearValues}>{t("BOOKED_DATE.clear")}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default AddDate