
import React, { Fragment } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import visa from "../../assets/img/profile/visa.png";
import applePay from "../../assets/img/profile/apple-pay.png";
import paypal from "../../assets/img/profile/paypal.png";

import visaCard from "../../assets/img/profile/visa-card.png";
import masterCard from "../../assets/img/profile/master-card.png";
import Seo from './../../Components/seo';
import { useTranslation } from "react-i18next";
const Payments = () => {
    const { t } = useTranslation();

    const [showAddPaymentModal, setShowAddPaymentModal] = React.useState(false);
    const handleCloseAddPaymentModal = () => setShowAddPaymentModal(false);
    const handleShowAddPaymentModal = () => setShowAddPaymentModal(true);

    const [showCoupondForm, setShowCoupondForm] = React.useState(false);
    const handleCloseCoupondForm = () => setShowCoupondForm(false);
    const handleShowCoupondForm = () => setShowCoupondForm(true);

    const [payments, setPayments] = React.useState([]);

    React.useEffect(() => {
        setPayments([
            {
                id: 1,
                type: 'MasterCard',
                number: '···· 2345',
                default: true,
                expiration: '07/2022',
                image: masterCard,
            },
            {
                id: 2,
                type: 'Visa ',
                number: '···· 1950',
                default: false,
                expiration: '05/2023',
                image: visaCard,
            },
        ]);
    }, []);

    const makeDefault = (id) => {
        console.log(id);
        let newPayments = payments.map((item) => {
            console.log(id, item.id);
            let newItem = { ...item };
            if (newItem.id == id) {
                newItem.default = true;
            } else {
                newItem.default = false;
            }
            return newItem;
        });
        setPayments(newPayments);
    };

    return (
        <Fragment>
            <Seo title={'Payments & payouts'} schema={{}} />
            <section className="author-page-section review-payments active-section" section-name="review-payments">
                <h2>{t("PAYMENTS.payments_payouts")}</h2>
                <div className="info-wrapper">
                    <h3 className="mt-30">{t("PAYMENTS.payment_methods")}</h3>
                    <div className="wrapper-header">
                        <p>{t("PAYMENTS.description")}</p>
                        <div className="added-cards-wrapper">
                            {payments?.length > 0 ? <>
                                {payments?.map((item, key) => {
                                    return <div className="added-card d-flex justify-content-between" key={key}>
                                        <div className="d-flex card-info-wrapper">
                                            <div><img src={item.image} alt="" /></div>
                                            <div>
                                                <h6>{item.type} {item.number}</h6>
                                                <p>{t("PAYMENTS.expiration")}: {item.expiration}</p>
                                            </div>
                                            <div>
                                                <div className={`${item.default ? '' : 'd-none'} default`}>{t("PAYMENTS.default")}</div>
                                            </div>
                                        </div>
                                        <Dropdown drop='start'>
                                            <Dropdown.Toggle className="def-btn" variant="" id="dropdown-basic" title="">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="">
                                                <li>
                                                    <button className="def-btn-item w-100" onClick={() => { makeDefault(item.id) }}>{t("PAYMENTS.default")}</button>
                                                </li>
                                                <li>
                                                    <button className="del-card-btn w-100">{t("PAYMENTS.delete")}</button>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                })}
                            </> : ''}
                        </div>
                        <div className="buttons-wrapper">
                            <button className="save-btn info-btn mt-50" onClick={handleShowAddPaymentModal}>{t("PAYMENTS.add_payment_method")}</button>
                        </div>
                    </div>
                    <Modal id="addPaymentMethodModal" className="" show={showAddPaymentModal} onHide={handleCloseAddPaymentModal} centered scrollable>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className="modal-title" id="addPaymentMethodModalCenteredScrollableTitle">
                            {t("PAYMENTS.add_card_details")}
                            </h5>
                            <div className="d-flex justify-content-center option-wrapper">
                                <label className="option-group">
                                    <img src={visa} />
                                    <input type="radio" name="card_type" value="visa" />
                                </label>
                                <label className="option-group">
                                    <img src={applePay} />
                                    <input type="radio" name="card_type" value="apple-pay" />
                                </label>
                                <label className="option-group">
                                    <img src={paypal} />
                                    <input type="radio" name="card_type" value="paypal" />
                                </label>
                            </div>
                            <div className="card-info-group d-flex flex-column">
                                <label>{t("PAYMENTS.card_number")}</label>
                                <input type="text" placeholder="0000 0000 0000 0000" name="card-number" />
                            </div>
                            <div className="card-info-group d-flex flex-column">
                                <label>{t("PAYMENTS.card_holder")}</label>
                                <input type="text" placeholder="" name="card-holder" />
                            </div>
                            <div className="d-flex input-group-wrapper">
                                <div className="card-info-group d-flex flex-column">
                                    <label>{t("PAYMENTS.expiration")}</label>
                                    <input type="text" placeholder="MM / YY" name="expiration-date" />
                                </div>
                                <div className="card-info-group d-flex flex-column">
                                    <label>{t("PAYMENTS.cvv")}</label>
                                    <input type="text" placeholder="XXX" name="cvv" />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="" className="img-btn" onClick={handleCloseAddPaymentModal}>
                            {t("PAYMENTS.cancel")}
                            </Button>
                            <Button variant="" className="save-btn" onClick={handleCloseAddPaymentModal}>
                            {t("PAYMENTS.done")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="info-wrapper">
                    <h3 className="mt-30">{t("PAYMENTS.coupons")}</h3>
                    <div className="wrapper-header">
                        <p>{t("PAYMENTS.add_coupon_and_save")}</p>
                        <div className="added-coupon d-flex justify-content-between">
                            <p>{t("PAYMENTS.your_coupons")}</p>
                            <p>$0</p>
                        </div>
                        <div className={`${showCoupondForm ? 'd-none' : ''} buttons-wrapper`}>
                            <button className="save-btn info-btn add-coupon" onClick={handleShowCoupondForm}>{t("PAYMENTS.add_coupon")}</button>
                        </div>
                        <div className={`${showCoupondForm ? '' : 'd-none'} coupon-add-wrapper`}>
                            <input type="text" name="coupon" placeholder={t("PAYMENTS.enter_coupon")} />
                            <div className="coupon-buttons-wrapper">
                                <button className="save-btn info-btn use-coupon" onClick={handleCloseCoupondForm}>{t("PAYMENTS.use_coupon")}</button>
                                <button className="img-btn cancel-btn" onClick={handleCloseCoupondForm}>{t("PAYMENTS.cancel")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    );
}

export default Payments;
