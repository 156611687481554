import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Context from './index';


const ChatProvider = (props) => {

    const [show, setShow] = useState(false);
    const [otherUser, setOtherUser] = useState(null);
    

    const openChat = () => {
        setShow(true);
    }

    const closeChat = () => {
        setShow(false);
    }

    return (
        <Context.Provider
            value={{
                ...props,
                show,
                otherUser,
                openChat,
                closeChat,
                setOtherUser,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default ChatProvider;