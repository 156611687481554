
import React, { Fragment, useContext, useEffect, useState } from "react";
import GooglePlacesAutocomplete, { geocode, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import mapMarker from "../../assets/img/majesticons_map-marker-line.png";
import ProductsContext from "../../_helper/Products";
import MapsJsApiContext from "../../_helper/MapsJsApi";
import { useTranslation } from "react-i18next";

const HeaderPlacesInput = () => {
    const { t } = useTranslation();

    const {
        productListFilters,
        setProductFilter,
    } = useContext(ProductsContext);

    const {
        isLoaded,
    } = useContext(MapsJsApiContext);

    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        if (!!productListFilters?.place) {
            setSearchValue(productListFilters?.place);
            geocodeByPlaceId(productListFilters?.place?.value?.place_id)
                .then(results => {
                    console.log(results);
                    return getLatLng(results[0]);
                })
                .then(({ lat, lng }) => {
                    console.log('Successfully got latitude and longitude', `${lat}, ${lng}`);
                    setProductFilter({ 'lat': lat, 'lng': lng });
                }
                );
        } else {
            setSearchValue('');
        }
    }, [productListFilters?.place])

    const setPlaceValue = (value) => {
        setProductFilter({ 'place': value });
    }

    return <>
        {!!isLoaded &&
            <GooglePlacesAutocomplete
                apiOptions={{ language: 'en', region: 'AM' }}
                onLoadFailed={(error) => (
                    console.error("Could not inject Google script", error)
                )}
                selectProps={{
                    placeholder: t("HEADER.search_placeholder"),
                    className: 'addres-filter-icon',
                    value: searchValue,
                    onChange: setPlaceValue,
                    styles: {
                        container: (provided) => ({
                            ...provided,
                            width: '240px',
                        }),
                        control: (provided) => ({
                            ...provided,
                            borderColor: 'blue',
                            border: '1px solid #D8D8D8',
                            borderRadius: '30px',
                            padding: '10px 15px 10px 30px',
                            backgroundImage: `url(${mapMarker})`,
                            backgroundPosition: '10px center',
                            backgroundRepeat: 'no-repeat',
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            display: 'none',
                        }),
                    }
                }}
            />
        }
    </>
}

export default HeaderPlacesInput