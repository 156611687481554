import React, { Fragment, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "../Layout/Loader";
import Signin from "../Pages/Signin";

import AppLayout from "../Layout/Layout";
import ErrorPage404 from "../Pages/ErrorPage404";
import ProfileLayout from "../Pages/Profile/ProfileLayout";
import Signup from "../Pages/Signup";
import ProtectedRoute from './ProtectedRoute';
import { privateRoutes, profileRoutes, routes } from './Routes';

// setup fake backend

const Routers = () => {

  const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")));
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    localStorage.setItem("authenticated", authenticated);
    localStorage.setItem("login", login);
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes.map(({ path, Component }, i) => (
            <Fragment key={i}>
              <Route element={<AppLayout />} key={i}>
                <Route path={path} element={Component} />
              </Route>
            </Fragment>
          ))}

          {privateRoutes.map(({ path, Component }, i) => (
            <Fragment key={i}>
              <Route element={<AppLayout />} key={i}>
                <Route path={path} element={
                  <ProtectedRoute redirect={path}>
                    {Component}
                  </ProtectedRoute>
                } />
              </Route>
            </Fragment>
          ))}

          {profileRoutes.map(({ path, Component }, i) => (
            <Fragment key={i}>
              <Route element={<AppLayout />} key={i}>
                <Route element={<ProfileLayout />} key={i}>
                  <Route path={path} element={
                    <ProtectedRoute redirect={path}>
                      {Component}
                    </ProtectedRoute>
                  } />
                </Route>
              </Route>
            </Fragment>
          ))}
          <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
          <Route exact path={`${process.env.PUBLIC_URL}/login/:redirect`} element={<Signin />} />
          <Route exact path={`${process.env.PUBLIC_URL}/register`} element={<Signup />} />
          <Route exact path={`${process.env.PUBLIC_URL}/register/:redirect`} element={<Signup />} />
          <Route path={`${process.env.PUBLIC_URL}/404`} element={<ErrorPage404 />} />
          <Route path={`*`} element={<ErrorPage404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
