import React, { Fragment, useContext, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Spinner } from '../../AbstractElements';
import { Save } from '../../Constant';
import UserContext from '../../_helper/User';

const EditBankData = ({ closeBankDataModal }) => {
  const { bankName, bankAccount, updateCurrentUserBankData } = useContext(UserContext);
  const [bankNameValue, setBankName] = useState('');
  const [bankAccountValue, setBankAccount] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBankName(bankName || '');
    setBankAccount(bankAccount || '');
  }, [bankName]);

  const onSubmit = async () => {
    setLoading(true);
    let result = await updateCurrentUserBankData(bankNameValue, bankAccountValue);
    setLoading(false);
    if (true == result) {
      toast.success("Bank Data updated");
      closeBankDataModal();
    } else {
      toast.error('Something went wrong...');
    }
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form>
            <FormGroup className='mb-3'>
              <Label className='form-label'>Bank Name</Label>
              <Input className='form-control' placeholder='Bank Name' value={bankNameValue} onChange={(e) => { setBankName(e.target.value) }} />
            </FormGroup>
            <FormGroup className='mb-3'>
              <Label className='form-label'>Bank Account</Label>
              <Input className='form-control' placeholder='0000 0000 0000 0000' value={bankAccountValue} onChange={(e) => { setBankAccount(e.target.value) }} />
            </FormGroup>
            <div className='form-footer d-flex align-items-center'>
              <Btn attrBtn={{ onClick: onSubmit, className: 'btn-block', color: 'primary' }}>{Save}</Btn>
              {loading ?
                <div className="loader-box" style={{ width: '40px', height: '40px' }}>
                  <Spinner attrSpinner={{ style: { width: '25px', height: '25px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                </div>
                : ''}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default EditBankData;
