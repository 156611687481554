
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';

const Status = ({ status }) => {
    const { t } = useTranslation();
    const [statusClass, setStatusClass] = useState('');
    const [statusString, setStatusString] = useState('');
    useEffect(() => {
        switch (status) {
            case 'pending':
                setStatusClass('yellow');
                setStatusString('STATUSES.pending');
                break;
            case 'reserved':
                setStatusClass('blue');
                setStatusString('STATUSES.reserved');
                break;
            case 'in_progress':
                setStatusClass('purple');
                setStatusString('STATUSES.in_progress');
                break;
            case 'failed':
                setStatusClass('red');
                setStatusString('STATUSES.failed');
                break;
            case 'rejected':
                setStatusClass('red');
                setStatusString('STATUSES.rejected');
                break;
            case 'canceled':
                setStatusClass('red');
                setStatusString('STATUSES.cancelled');
                break;
            case 'confirmed':
            case 'reserved':
                setStatusClass('blue');
                setStatusString('STATUSES.confirmed');
                break;
            case 'completed':
                setStatusClass('');
                setStatusString('STATUSES.completed');
                break;
            default:
                break;
        }
    }, [])

    return <>
        {!!statusString && <span className={`custom-pill ${statusClass}`}>{t(statusString)}</span>}
    </>
}

export const CustomStatus = ({color, text}) => {
    const { t } = useTranslation();
    return <>
        <span className={`custom-pill ${color} px-2`}>{!!text && parse(text)}</span>
    </>
}

export default Status;