import React, { Fragment } from "react";
import FindCarForm from "../Components/Home/FindCarForm";
import HomeSlider from "../Components/Home/HomeSlider";
import HeaderHome from "../Layout/Header/HeaderHome";
import Header2 from "../Layout/Header/Header2";
import Seo from './../Components/seo';
import HomeFeaturedCar from "../Components/Home/HomeFeaturedCar";

// import "../assets/css/home-map.css"
import "../assets/css/custom.css";
import "../assets/css/home.css";
import "../assets/css/home-mobile.css";
import "../assets/css/style.css";
import FindCarFormMobile from "../Components/Home/FindCarFormMobile";
import ChatPopup from './../Layout/Header/ChatPopup';
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Seo title={'Home'} schema={{}} />
      <div className="d-none d-lg-block">
        <HeaderHome />
      </div>
      <div className="d-lg-none">
        <Header2 />
      </div>
      <div className="home d-flex">
        <HomeSlider />
        <section className="homepage-find-section justify-content-center d-flex flex-column">
          <div className="d-none d-lg-flex flex-column flex-grow-1 find-wraper find-now justify-content-center p-e-container">
            <h5>{t("HOME_FIND_YOUR_CAR.section_title")}</h5>
            <FindCarForm />
          </div>
          <div className="d-flex d-lg-none">
            <FindCarFormMobile />
          </div>
          <div className="rent-footer d-none d-lg-flex">
            <HomeFeaturedCar />
          </div>
        </section>
      </div>
      <ChatPopup />
    </Fragment>
  );
}

export default Home;
