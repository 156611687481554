import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ErrorPage404 from "../Pages/ErrorPage404";
import { useContext } from "react";
import UserContext from "../_helper/User";

const OwnerRoute = ({ children }) => {

    const {id, role} = useContext(UserContext);

    if (!!id && (!role || 'owner' != role)) {
        let to = `${process.env.PUBLIC_URL}/404`;
        return <Navigate exact to={to} />;
    }

    return children;
};
export default OwnerRoute;