import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import "../../assets/jquery-ui/jquery-ui.css";
import "../../assets/css/header-with-search.css";
import "../../assets/css/vehilce-header.css";


import logoLight from "../../assets/img/Logo-light.png";
import closeBtn from "../../assets/img/carbon_close.png"
import headerMove from "../../assets/img/header-move.png"

import HeaderMessages from "./HeaderMessages";
import HeaderNavigationMenu from './HeaderNavigationMenu';
import HeaderNotifications from "./HeaderNotifications";
import HeaderProfileMenu from "./HeaderProfileMenu";


import ProductsContext from "../../_helper/Products";
import SelectMenu from './../../Components/VehicleForm/selectmenu';
import DatePicker from "../../Components/VehicleForm/datepicker";
import HeaderPlacesInput from "./HeaderPlacesInput";
import HeaderNavigationMenuOffcanvas from "./HeaderNavigationMenuOffcanvas";
import VerificationBanner from "../../Components/Common/VerificationBanner";
import { useTranslation } from "react-i18next";

const Header2 = () => {
    const history = useNavigate();
    const { t } = useTranslation();

    const {
        defaultTimeOptions,
        productListFilters,
        setProductFilter,
    } = useContext(ProductsContext);

    const handleOnSearch = () => {
        history(`${process.env.PUBLIC_URL}/vehicle-list`);
    }

    return <>
        <VerificationBanner />
        <header className="vehilce-header">
            <div className="container">
                <div className="row vehicle-header">
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <div className="flex-grow-1 d-flex header-left align-items-center">
                                <Link to="/" className="logo"><img src={logoLight} alt="utrip-logo" /></Link>
                                <div className="filters-wrap d-flex">
                                    <HeaderPlacesInput />
                                    <div className="filter">
                                        <DatePicker
                                            handleOnChange={(value) => { setProductFilter({ 'book_date_from': value }) }}
                                            selected={productListFilters.book_date_from}
                                            placeholder={t("HEADER.take_date")}
                                            max={productListFilters.book_date_to}
                                        />
                                        <div className="divider"></div>
                                        <label className="time" htmlFor="time-picker">
                                            <SelectMenu
                                                id="time-picker-1"
                                                name="time-picker-1"
                                                className={``}
                                                options={defaultTimeOptions}
                                                handleOnChange={(value) => { setProductFilter({ 'book_time_from': value }) }}
                                                selected={productListFilters.book_time_from}
                                                selectValue={t("HEADER.take_time")}
                                            />
                                        </label>
                                    </div>
                                    <div className="filter">
                                        <DatePicker
                                            handleOnChange={(value) => { setProductFilter({ 'book_date_to': value }) }}
                                            selected={productListFilters.book_date_to}
                                            placeholder={t("HEADER.return_date")}
                                            min={productListFilters.book_date_from}
                                        />
                                        <div className="divider"></div>
                                        <label className="time" htmlFor="time-picker">
                                            <SelectMenu
                                                id="time-picker"
                                                name="time-picker"
                                                className={``}
                                                options={defaultTimeOptions}
                                                handleOnChange={(value) => { setProductFilter({ 'book_time_to': value }) }}
                                                selected={productListFilters.book_time_to}
                                                selectValue={t("HEADER.return_time")}
                                            />
                                        </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {(
                                            !!productListFilters.book_date_from
                                            || !!productListFilters.book_time_from
                                            || !!productListFilters.book_date_to
                                            || !!productListFilters.book_time_to
                                            || !!productListFilters.place
                                        ) && <div
                                            title="Clear"
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                marginLeft: '-15px',
                                                marginRight: '15px',
                                            }}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setProductFilter({
                                                    'book_date_from': '',
                                                    'book_time_from': '',
                                                    'book_date_to': '',
                                                    'book_time_to': '',
                                                    'lat': '',
                                                    'lng': '',
                                                    'place': '',
                                                })
                                            }}>
                                                <img src={closeBtn} className="w-100" />
                                            </div>
                                        }

                                        <Link to="/vehicle-list">
                                            <img src={headerMove} style={{ width: '42px', height: '42px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="header-right d-flex align-items-center">
                                <HeaderNotifications />
                                <HeaderMessages />
                                <HeaderProfileMenu />
                                {/* <HeaderNavigationMenu /> */}
                                <HeaderNavigationMenuOffcanvas />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    </>
}

export default Header2