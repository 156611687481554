import React, { useEffect, useRef, useState, useContext } from "react";

import ProductsContext from "../../_helper/Products";
import MapsJsApiContext from "../../_helper/MapsJsApi";
import GooglePlacesAutocomplete, { geocode, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import DatePickerInline from "../VehicleForm/datepicker-inline";
import DatePicker from './../VehicleForm/datepicker';
import SelectMenu from './../VehicleForm/selectmenu';
import TimepickerInline from './../VehicleForm/timepicker-inline';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from "react-i18next";
import locationPng from "../../assets/img/majesticons_map-marker-line.png";
import dateTimePickerPng from "../../assets/img/Group (1).png";
import searchPng from "../../assets/img/charm_search.png";
import { Link } from "react-router-dom";

const FindCarForm = () => {

    const {
        productListFilters,
        setProductFilter,

        mapProductListFilters,
        setMapProductFilter,
        setMapPlacePosition,
    } = useContext(ProductsContext);

    const {
        isLoaded,
    } = useContext(MapsJsApiContext);

    const [searchValue, setSearchValue] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        if (!!productListFilters?.place) {
            setSearchValue(productListFilters?.place);
            geocodeByPlaceId(productListFilters?.place?.value?.place_id)
                .then(results => {
                    console.log(results);
                    return getLatLng(results[0]);
                })
                .then(({ lat, lng }) => {
                    console.log('Successfully got latitude and longitude', `${lat}, ${lng}`);
                    setProductFilter({ 'lat': lat, 'lng': lng });
                    setMapProductFilter({ 'lat': lat, 'lng': lng });
                    setMapPlacePosition({ 'lat': lat, 'lng': lng });
                }
                );
        }
    }, [productListFilters?.place])

    const setPlaceValue = (value) => {
        setMapProductFilter({ 'place': value });
        setProductFilter({ 'place': value });
    }

    return (
        <>
            <div className="find-car d-flex">
                {!!isLoaded &&
                    <GooglePlacesAutocomplete
                        apiOptions={{ language: 'en', region: 'AM' }}
                        onLoadFailed={(error) => (
                            console.error("Could not inject Google script", error)
                        )}
                        selectProps={{
                            placeholder: t("HOME_FIND_YOUR_CAR.rent_location"),
                            className: 'location p-0',
                            value: searchValue,
                            onChange: setPlaceValue,
                            styles: {
                                container: (provided) => ({
                                    ...provided,
                                    width: '240px',
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    borderColor: 'blue',
                                    border: '0px solid transparent !important',
                                    boxShadow: 'unset!important',
                                    borderRadius: '0',
                                    padding: '7px 0 7px 30px',
                                    backgroundImage: `url(${locationPng})`,
                                    backgroundPosition: '15px center',
                                    backgroundRepeat: 'no-repeat',
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    fontDamily: "'SF Pro Display', sans-serif",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    lineHeight: "22px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    color: '#666',
                                }),
                                indicatorsContainer: (provided) => ({
                                    ...provided,
                                    display: 'none',
                                }),
                            }
                        }}
                    />
                }
                <Dropdown drop="down-centered">
                    <Dropdown.Toggle className="p-0 border-0 after-none" variant="" id="dropdown-basic" title="">
                        <div className="start-date d-flex cursor-pointer user-select-none">
                            <img src={dateTimePickerPng} alt="" />
                            <div className="d-flex flex-column">
                                {(!!productListFilters?.book_date_from && !!productListFilters?.book_time_from)
                                    ? <>
                                        <span>{productListFilters?.book_date_from}</span>
                                        <span>{productListFilters?.book_time_from}</span>
                                    </>
                                    : <span>{t("HOME_FIND_YOUR_CAR.pickup")}</span>
                                }
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="" style={{ width: '430px' }}>
                        <div className="w-100 d-flex justify-content-center px-3" style={{ gap: '10px' }}>
                            <DatePickerInline
                                handleOnChange={(value) => { setProductFilter({ 'book_date_from': value }) }}
                                selected={productListFilters.book_date_from}
                                placeholder={t("HOME_FIND_YOUR_CAR.pickup_date")}
                            />
                            <div className="divider"></div>
                            <label className="time" htmlFor="time-picker">
                                <TimepickerInline
                                    id="time-picker-1"
                                    name="time-picker-1"
                                    className={``}
                                    options={[
                                        { value: '12:00 AM', name: '12:00 AM' },
                                        { value: '01:00 PM', name: '01:00 PM' },
                                        { value: '02:00 PM', name: '02:00 PM' },
                                        { value: '03:00 PM', name: '03:00 PM' },
                                        { value: '04:00 PM', name: '04:00 PM' },
                                        { value: '05:00 PM', name: '05:00 PM' },
                                        { value: '06:00 PM', name: '06:00 PM' },
                                        { value: '07:00 PM', name: '07:00 PM' },
                                        { value: '08:00 PM', name: '08:00 PM' },
                                        { value: '09:00 PM', name: '09:00 PM' },
                                    ]}
                                    handleOnChange={(value) => { setProductFilter({ 'book_time_from': value }) }}
                                    selected={productListFilters.book_time_from}
                                    selectValue={'Pickup time'}
                                />
                            </label>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown drop="down-centered">
                    <Dropdown.Toggle className="p-0 border-0 after-none" variant="" id="dropdown-basic" title="">
                        <div className="end-date d-flex cursor-pointer user-select-none">
                            <img src={dateTimePickerPng} alt="" />
                            <div className="d-flex flex-column">
                                {(!!productListFilters?.book_date_to && !!productListFilters?.book_time_to)
                                    ? <>
                                        <span>{productListFilters?.book_date_to}</span>
                                        <span>{productListFilters?.book_time_to}</span>
                                    </>
                                    : <span>{t("HOME_FIND_YOUR_CAR.drop_off")}</span>
                                }
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="" style={{ width: '430px' }}>
                        <div className="w-100 d-flex justify-content-center px-3" style={{ gap: '10px' }}>
                            <DatePickerInline
                                handleOnChange={(value) => { setProductFilter({ 'book_date_to': value }) }}
                                selected={productListFilters.book_date_to}
                                placeholder={t("HOME_FIND_YOUR_CAR.drop_off_date")}
                            />
                            <div className="divider"></div>
                            <label className="time" htmlFor="time-picker">
                                <TimepickerInline
                                    id="time-picker-1"
                                    name="time-picker-1"
                                    className={``}
                                    options={[
                                        { value: '12:00 AM', name: '12:00 AM' },
                                        { value: '01:00 PM', name: '01:00 PM' },
                                        { value: '02:00 PM', name: '02:00 PM' },
                                        { value: '03:00 PM', name: '03:00 PM' },
                                        { value: '04:00 PM', name: '04:00 PM' },
                                        { value: '05:00 PM', name: '05:00 PM' },
                                        { value: '06:00 PM', name: '06:00 PM' },
                                        { value: '07:00 PM', name: '07:00 PM' },
                                        { value: '08:00 PM', name: '08:00 PM' },
                                        { value: '09:00 PM', name: '09:00 PM' },
                                    ]}
                                    handleOnChange={(value) => { setProductFilter({ 'book_time_to': value }) }}
                                    selected={productListFilters.book_time_to}
                                    selectValue={'Drop Off time'}
                                />
                            </label>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                {/* <Link to="/vehicle-list" className="search d-block user-select-none">
                    <img src={searchPng} alt="search" />
                </Link> */}
                <Link to="/map" className="search d-block user-select-none">
                    <img src={searchPng} alt="search" />
                </Link>
            </div>
            <div className="d-flex checkbox-wraper user-select-none">
                <label className="cursor-pointer d-flex align-items-center" style={{ gap: '4px' }}>
                    <input type="checkbox" name="check" className="checkbox" />
                    <span>{t("HOME_FIND_YOUR_CAR.deliver_at_different_point")}</span>
                </label>
            </div>
        </>
    )
}

export default FindCarForm
