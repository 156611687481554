import { GoogleMap, Marker } from '@react-google-maps/api';
import parse from 'html-react-parser';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { Link, useLocation, useParams } from 'react-router-dom';
import Slider from "react-slick";
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import Reviews from "../Components/Vehicle/Reviews";
import VehicleCard from "../Components/Vehicle/VehicleCard";
import Footer from "../Layout/Footer/Footer";
import Header2 from "../Layout/Header/Header2";

import "../assets/css/vehicle-single.css";
import "./../assets/css/custom.css";
import "./../assets/css/style.css";


import star from '../assets/img/Star.png';

import Hunter from '../assets/img/reviews/Mask group (1).png';
import Colin from '../assets/img/reviews/Mask group (2).png';
import Chantel from '../assets/img/reviews/Mask group.png';
import chatBtn from '../assets/img/chat-btn.png';

import contactEmail from '../assets/img/contact-email.png';
import contactPhone from '../assets/img/contact-phone.png';
import GPS from '../assets/img/compass.png';
import Transmission from '../assets/img/git-branch-24.png';
import LT from '../assets/img/ic24-opacity.png';
import Power from '../assets/img/meter-24.png';
import Persons from '../assets/img/people-24.png';
import Conditioner from '../assets/img/ph_snowflake-fill.png';
import VehicleSidebar from '../Components/Vehicle/VehicleSidebar';
import Seo from './../Components/seo';

import MapsJsApiContext from '../_helper/MapsJsApi';
import ProductsContext from "../_helper/Products";
import Loading from './../Components/Common/Loading';
import AddReview from '../Components/Vehicle/AddReview';
import ChatContext from '../_helper/Chat';

import { useTranslation } from "react-i18next";


const VehicleSingle = () => {
    const { t } = useTranslation();

    const {
        isLoaded,
        mapStyles,
    } = useContext(MapsJsApiContext);

    const {
        productForView,
        getProductForView,
        toggleProductFavorite,
        addReview,
    } = useContext(ProductsContext);

    const [productForViewLoaded, setProductForViewLoaded] = useState(false);

    const [contactDataModal, setContactDataModal] = useState(false);
    const openContactDataModal = () => {
        setContactDataModal(true);
    };

    const closeContactDataModal = () => {
        setContactDataModal(false);
    };

    const [showMoreFeatures, setShowMoreFeatures] = React.useState(false);

    const [vehicle, setVehicle] = React.useState(null);
    const [recommendedVehicles, setRecommendedVehicles] = React.useState([]);
    let { id } = useParams();
    const { pathname } = useLocation();
    const [oldPath, setOldPath] = React.useState('');
    useEffect(() => {
        if (!!pathname && pathname != oldPath) {
            setOldPath(pathname);
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const [center, setCenter] = useState(null);
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);

        // setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    React.useEffect(() => {
        if (!!id) {
            getProductForView(id);
        }
    }, [id]);

    useEffect(() => {
        if (null != productForView) {
            // console.log(productForView);
            setCenter({
                lat: parseFloat(productForView?.lat),
                lng: parseFloat(productForView?.lng),
            })
            setVehicle({
                id: id,
                price: {
                    perDay: productForView?.daily_price,
                },
                gallery: productForView?.images?.map(item => item.src),
                rate: productForView?.rating,
                votes: productForView?.reviews?.length,
                title: productForView?.title,
                category: productForView?.pa_trim,
                owner: {
                    id: productForView?.owner?.id,
                    name: productForView?.owner?.name,
                    rate: productForView?.owner?.rate,
                    votes: productForView?.owner?.votes,
                    since: productForView?.owner?.since,
                    image: productForView?.owner?.image,
                    email: productForView?.owner?.email,
                    phone: productForView?.owner?.phone,
                },
                description: productForView?.description,
                location: {
                    place: productForView?.place,
                },
                reviews: [...productForView?.reviews],
                features: {
                    year: productForView?.pa_years,
                    engine: '',
                    mileometer: productForView?.pa_odometer,
                    doors: null,
                    consumption: '',
                    color: '',
                    transmission: productForView?.pa_transmission,
                    persons: null,
                    gps: !!productForView?.pa_features?.gps,
                    airConditioning: true,
                    power: null,
                    LT: null,
                },
                isFavorite: productForView?.isFavorite,
                pa_features: Object.keys(productForView?.pa_features).map(key => { return productForView?.pa_features[key] }),
            });

            if (productForView?.related?.length > 0) {
                let related = productForView?.related?.map(item => {
                    return {
                        url: `/vehicle/${item.id}`,
                        img: item.img,
                        title: item.title,
                        rate: item.rate,
                        votes: item.votes,
                        price: {
                            perDay: `<span>$${item.daily_price}</span>/day`,
                            total: null,
                        }
                    }
                })
                setRecommendedVehicles(related);
            }

            setProductForViewLoaded(true);
        }

    }, [productForView]);

    const sliderSettings = {
        dots: true,
        arrows: true,
        slidesToShow: 1,
        infinite: true,
        // centerMode: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 6000,
        lazyLoad: true,
    };

    const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")))


    const {
        openChat,
        setOtherUser,
    } = useContext(ChatContext);
    const startChat = () => {
        setOtherUser({
            id: vehicle?.owner?.id,
            firstName: vehicle?.owner?.name,
            lastName: '',
            imageUrl: vehicle?.owner?.image,
            email: vehicle?.owner?.email,
        });
        openChat();
    }

    return (
        <Fragment>
            <Seo title={vehicle?.title ?? ''} schema={{}} />
            <Header2 />
            <main className="p-0">
                {
                    !!productForViewLoaded
                        ? <>
                            <section className="vehicle-single">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="vehicle-images-wrapper">
                                                {!!token &&
                                                    <>
                                                        {!!vehicle.isFavorite
                                                            ? <div className={`like-btn liked cursor-pointer`} onClick={() => { toggleProductFavorite(vehicle.id, 'remove') }}>
                                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M10.761 18.7528C8.5904 17.4064 6.57111 15.8218 4.73929 14.0273C3.45144 12.7351 2.47101 11.1596 1.8731 9.42168C0.797144 6.05033 2.05393 2.19077 5.57112 1.04857C7.41961 0.448808 9.43845 0.791597 10.9961 1.9697C12.5543 0.793032 14.5725 0.450363 16.4211 1.04857C19.9383 2.19077 21.2041 6.05033 20.1281 9.42168C19.5302 11.1596 18.5498 12.7351 17.2619 14.0273C15.4301 15.8218 13.4108 17.4064 11.2402 18.7528L11.0051 18.9002L10.761 18.7528Z"
                                                                        fill="#3C3C3C" fillOpacity="0.8" stroke="white" strokeWidth="1.2"
                                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            : <div className={`like-btn cursor-pointer`} onClick={() => { toggleProductFavorite(vehicle.id, 'add') }}>
                                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M10.761 18.7528C8.5904 17.4064 6.57111 15.8218 4.73929 14.0273C3.45144 12.7351 2.47101 11.1596 1.8731 9.42168C0.797144 6.05033 2.05393 2.19077 5.57112 1.04857C7.41961 0.448808 9.43845 0.791597 10.9961 1.9697C12.5543 0.793032 14.5725 0.450363 16.4211 1.04857C19.9383 2.19077 21.2041 6.05033 20.1281 9.42168C19.5302 11.1596 18.5498 12.7351 17.2619 14.0273C15.4301 15.8218 13.4108 17.4064 11.2402 18.7528L11.0051 18.9002L10.761 18.7528Z"
                                                                        fill="#3C3C3C" fillOpacity="0.8" stroke="white" strokeWidth="1.2"
                                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        }

                                                    </>
                                                }

                                                {vehicle?.gallery?.length > 0
                                                    ? <Slider
                                                        {...sliderSettings}
                                                        className="vehicle-gallery"
                                                    >
                                                        {vehicle?.gallery?.map((image, key) => {
                                                            return <div key={key} className='h-100'>
                                                                <div className="gal-item">
                                                                    <img src={image} alt="" />
                                                                </div>
                                                            </div>
                                                        })}
                                                    </Slider>
                                                    : ''
                                                }
                                            </div>
                                            <div className="vehicle-info">
                                                <h1 className="title">{vehicle?.title}</h1>
                                                <p className="category">{vehicle?.category}</p>
                                                {vehicle?.rate > 0 &&
                                                    <div className="rate d-flex align-items-center">
                                                        <img src={star} alt="" />
                                                        <span>{vehicle?.rate}</span>
                                                        <span className="votes">({vehicle?.votes})</span>
                                                    </div>
                                                }
                                                {!!vehicle?.owner
                                                    ? <div className="hosted-by-wrapper">
                                                        <h5>{t("VEHICLE_SINGLE.hosted_by")}</h5>
                                                        <div className="owner-wrapper d-flex align-items-center">
                                                            <img src={vehicle?.owner?.image} alt="" className="owner-img" />
                                                            <div className="owner-info">
                                                                <h6 className="name">{vehicle?.owner?.name}</h6>
                                                                <div className="owner-rate d-flex align-items-center">
                                                                    <img src={star} alt="" />
                                                                    <span>{vehicle?.owner?.rate}</span>
                                                                    <span className="votes">({vehicle?.owner?.votes} {t("VEHICLE_SINGLE.trips")})</span>
                                                                    &middot;
                                                                    <span className="member-date">{t("VEHICLE_SINGLE.member_since")} {vehicle?.owner?.since}</span>
                                                                </div>
                                                            </div>
                                                            <div onClick={startChat} className='cursor-pointer user-select-none ms-4'>
                                                                <img style={{ width: '36px', height: '36px' }} src={chatBtn} />
                                                            </div>
                                                            <div className="ctn-info-btn ms-3" onClick={openContactDataModal}>{t("VEHICLE_SINGLE.contact_info")}</div>
                                                        </div>
                                                        <Modal style={{ width: '400px' }} isOpen={contactDataModal} toggle={closeContactDataModal} centered>
                                                            <ModalHeader toggle={closeContactDataModal}>
                                                                {t("VEHICLE_SINGLE.contact_info")}
                                                            </ModalHeader>
                                                            <ModalBody className='p-0'>
                                                                <div className='userContacts'>
                                                                    <div className='userName'>{vehicle?.owner?.name}</div>
                                                                    {!!vehicle?.owner?.email &&
                                                                        <div className='singleContact'>
                                                                            <img src={contactEmail} />
                                                                            <div className='singleContactData'>
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <b>{t("VEHICLE_SINGLE.email")}</b>
                                                                                    <a href={`mailto:${vehicle?.owner?.email}`}>{t("VEHICLE_SINGLE.feedback")}</a>
                                                                                </div>
                                                                                <div className='userContactValue'>{vehicle?.owner?.email}</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {!!vehicle?.owner?.phone &&
                                                                        <div className='singleContact'>
                                                                            <img src={contactPhone} />
                                                                            <div className='singleContactData'>
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <b>{t("VEHICLE_SINGLE.phone_number")}</b>
                                                                                    <a href={`tel:${vehicle?.owner?.phone}`}>{t("VEHICLE_SINGLE.make_a_call")}</a>
                                                                                </div>
                                                                                <div className='userContactValue'>{vehicle?.owner?.phone}</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                            </ModalBody>
                                                        </Modal>
                                                    </div>
                                                    : ''
                                                }
                                                {!!vehicle?.description
                                                    ? <div className="vehicle-description">
                                                        <h5>{t("VEHICLE_SINGLE.description")}</h5>
                                                        {parse(vehicle.description)}
                                                    </div>
                                                    : ''
                                                }
                                                <div className="equipment">
                                                    <h5>{t("VEHICLE_SINGLE.equipment")}</h5>
                                                    <div className="equipment-container">
                                                        {!!vehicle?.features?.persons
                                                            ? <div className="equipment-item">
                                                                <img src={Persons} alt="" />
                                                                <span>{vehicle?.features?.persons} {t("VEHICLE_SINGLE.persons")}</span>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.power
                                                            ? <div className="equipment-item">
                                                                <img src={Power} alt="" />
                                                                <span>{vehicle?.features?.power}</span>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.transmission
                                                            ? <div className="equipment-item">
                                                                <img src={Transmission} alt="" />
                                                                <span>{vehicle?.features?.transmission}</span>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.LT
                                                            ? <div className="equipment-item">
                                                                <img src={LT} alt="" />
                                                                <span>{vehicle?.features?.LT}</span>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.gps
                                                            ? <div className="equipment-item">
                                                                <img src={GPS} alt="" />
                                                                <span>{t("VEHICLE_SINGLE.gps")}</span>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.airConditioning
                                                            ? <div className="equipment-item">
                                                                <img src={Conditioner} alt="" />
                                                                <span>{t("VEHICLE_SINGLE.air_conditioning")}</span>
                                                            </div>
                                                            : ""
                                                        }

                                                    </div>
                                                </div>
                                                <div className="technical-features">
                                                    <h5>{t("VEHICLE_SINGLE.technical_features")}</h5>
                                                    <div className="feautures-container d-flex flex-column">
                                                        {!!vehicle?.features?.year
                                                            ? <div className="features-item d-flex justify-content-between align-items-center w-100">
                                                                <div className="feature-name">{t("VEHICLE_SINGLE.year")}</div>
                                                                <div className="feature-value">{vehicle?.features?.year}</div>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.engine
                                                            ? <div className="features-item d-flex justify-content-between align-items-center w-100">
                                                                <div className="feature-name">{t("VEHICLE_SINGLE.engine")}</div>
                                                                <div className="feature-value">{vehicle?.features?.engine}</div>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.mileometer
                                                            ? <div className="features-item d-flex justify-content-between align-items-center w-100">
                                                                <div className="feature-name">{t("VEHICLE_SINGLE.mileometer")}</div>
                                                                <div className="feature-value">{vehicle?.features?.mileometer}</div>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.doors
                                                            ? <div className="features-item d-flex justify-content-between align-items-center w-100">
                                                                <div className="feature-name">{t("VEHICLE_SINGLE.doors")}</div>
                                                                <div className="feature-value">{vehicle?.features?.doors} {t("VEHICLE_SINGLE.doors")}</div>
                                                            </div>
                                                            : ""
                                                        }
                                                        {!!vehicle?.features?.consumption
                                                            ? <div className="features-item d-flex justify-content-between align-items-center w-100">
                                                                <div className="feature-name">{t("VEHICLE_SINGLE.consumption")}</div>
                                                                <div className="feature-value">{vehicle?.features?.consumption}</div>
                                                            </div>
                                                            : ""
                                                        }
                                                        <div className={`collapse flex-column w-100 ${showMoreFeatures ? 'show d-flex' : ''}`} id="moreFeatures" style={{ gap: '20px' }}>
                                                            {!!vehicle?.features?.color
                                                                ? <div
                                                                    className="features-item d-flex justify-content-between align-items-center w-100">
                                                                    <div className="feature-name">{t("VEHICLE_SINGLE.color")}</div>
                                                                    <div className="feature-value">{vehicle?.features?.color}</div>
                                                                </div>
                                                                : ""
                                                            }
                                                            {!!vehicle?.features?.transmission
                                                                ? <div
                                                                    className="features-item d-flex justify-content-between align-items-center w-100">
                                                                    <div className="feature-name">{t("VEHICLE_SINGLE.transmission")}</div>
                                                                    <div className="feature-value">{vehicle?.features?.transmission}</div>
                                                                </div>
                                                                : ""
                                                            }
                                                        </div>
                                                        {!showMoreFeatures ?
                                                            <button className="show-more-features-btn" type="button" onClick={() => { setShowMoreFeatures(true) }}>
                                                                {t("VEHICLE_SINGLE.show_more")}
                                                            </button>
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                                <div className="location" id="vehicleLocation">
                                                    <h5>{t("VEHICLE_SINGLE.location")}</h5>
                                                    {!!vehicle?.location?.place?.label ? <p className="vehicle-addres">{vehicle?.location?.place?.label}</p> : ''}
                                                    {!!vehicle?.location?.place ?
                                                        <div className="location-map">
                                                            <div className="mapouter">
                                                                <div className="gmap_canvas">
                                                                    {(isLoaded && center != null) && <GoogleMap
                                                                        mapContainerStyle={containerStyle}
                                                                        center={center}
                                                                        zoom={15}
                                                                        onLoad={onLoad}
                                                                        onUnmount={onUnmount}
                                                                        options={
                                                                            {
                                                                                disableAutoPan: true,
                                                                                clickable: false,
                                                                                mapTypeId: 'roadmap',
                                                                                styles: mapStyles,
                                                                            }
                                                                        }
                                                                    >
                                                                        <Marker position={center} />
                                                                        { /* Child components, such as markers, info windows, etc. */}
                                                                    </GoogleMap>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                                {vehicle?.reviews?.length > 0 ? <Reviews items={vehicle.reviews} /> : ''}
                                                {!!token && <AddReview onAddReview={addReview} />}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <VehicleSidebar />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="recomendation-car">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between section-title-wrapper align-items-center">
                                            <h5>{t("VEHICLE_SINGLE.recommendation_car")}</h5>
                                            <Link to="/vehicle-list" className="underlined-btn">{t("VEHICLE_SINGLE.view_all")}</Link>
                                        </div>
                                        {recommendedVehicles.map((item, key) => {
                                            return (
                                                <div className="col-lg-3 col-md-4" key={key}>
                                                    <VehicleCard item={item} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                        </>
                        : <div className="d-flex align-items-center justify-content-center my-5">
                            <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                        </div>
                }
            </main>
            <Footer />
        </Fragment >
    );
}

export default VehicleSingle;
