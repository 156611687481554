
import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import balanceimg from '../../../assets/img/balance.png';
import onholdimg from '../../../assets/img/onhold.png';
import arrow from '../../../assets/img/rotate-left.svg';
import upcomingimg from '../../../assets/img/upcoming.png';

import { useTranslation } from "react-i18next";
import Loading from "../../../Components/Common/Loading";
import UserContext from "../../../_helper/User";
import Seo from './../../../Components/seo';
import FinanceHistoryBlock from './FinanceHistoryBlock';
import WithdrawFundsBlock from "./WithdrawFundsBlock";

const Finances = () => {
    const { t } = useTranslation();
    const inputRef = React.useRef(null);

    const {
        addWithdrawTransaction,
        withdrawFunds,
        completedBalance,
        reservedBalance,
        upcomingBalance,
        getCurrentUserData,
    } = useContext(UserContext);

    const [mainLoading, setMainLoading] = useState(false);
    const [balanceRefreshing, setBalanceRefreshing] = useState(false);

    const [showWithdrawForm, setShowWithdrawForm] = React.useState(false);

    const [withdrawAmount, setWithdrawAmount] = React.useState('');
    const [checkedWithDrawFund, setCheckedWithDrawFund] = useState([]);

    useEffect(() => {
        getInitData();
    }, [])

    const getInitData = async () => {
        setMainLoading(true);
        await getCurrentUserData();
        setMainLoading(false);
    }

    const refreshBalance = async () => {
        setBalanceRefreshing(true);
        await getCurrentUserData();
        setBalanceRefreshing(false);
    }

    const [payments, setPayments] = useState([]);

    useEffect(() => {
        setPayments(withdrawFunds);
    }, [withdrawFunds]);

    useEffect(() => {
        if (payments?.length > 0) {

            let default_checked = payments.find((item) => {
                return item.default;
            })
            if (!!default_checked) {
                setCheckedWithDrawFund(default_checked);
            }
        }
    }, [payments])

    const addTransaction = () => {
        if ((!!withdrawAmount && withdrawAmount > completedBalance) || (!checkedWithDrawFund || checkedWithDrawFund.length === 0)) {
            toast.error(`Can't Withdraw. Not Enough Founds.`);
            inputRef.current.focus();
        } else {
            addWithdrawTransaction(withdrawAmount, checkedWithDrawFund.card_holder, checkedWithDrawFund.card_number, checkedWithDrawFund.expiration, checkedWithDrawFund.type);
            setShowWithdrawForm(false);
            toast.success("Success.Your Request Created.");
        }
    }

    return (
        <Fragment>
            <Seo title={'Payments & payouts'} schema={{}} />
            <section className="author-page-section trips-section review-payments active-section " section-name="review-payments">
                <h2>{t("FINANCES.finances")}</h2>
                {mainLoading
                    ? <div className="d-flex align-items-center justify-content-center my-5">
                        <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                    </div>
                    : <Tabs
                        defaultActiveKey="overview"
                        id="uncontrolled-tab-example"
                        className="mb-3 trips-tab-wrapper"
                    >
                        <Tab eventKey="overview" title={t("FINANCES.overview")}>
                            <div className='wrapper'>
                                <section className='finance-section'>
                                    <div className="finance-section-content row">
                                        <div className="col-md-6">
                                            <div className="balance main-balance">
                                                <div className="balance-block-header align-items-center">
                                                    <img src={balanceimg} alt="" />
                                                    {balanceRefreshing
                                                        ? <Loading style={{ width: '24px', height: '24px' }} spinnerStyle={{ borderWidth: '2px' }} />
                                                        : <img src={arrow} alt="" className="cursor-pointer" onClick={refreshBalance} />}
                                                </div>

                                                <div className="balance-block-body">
                                                    <p className="title">{t("FINANCES.balance")}</p>
                                                    <h3 className="amount">${completedBalance} USD</h3>
                                                    <span className="status">Available</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="balance">
                                                <div className="balance-block-header">
                                                    <img src={upcomingimg} alt="" />
                                                </div>
                                                <div className="balance-block-body">
                                                    <p className="title">{t("FINANCES.upcoming_payments")}</p>
                                                    <h3 className="amount">${upcomingBalance} USD</h3>
                                                    <span className="status">In Progress</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="balance">
                                                <div className="balance-block-header">
                                                    <img src={onholdimg} alt="" />
                                                </div>
                                                <div className="balance-block-body">
                                                    <p className="title">{t("FINANCES.on_hold")}</p>
                                                    <h3 className="amount">${reservedBalance} USD</h3>
                                                    <span className="status">Reserved</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <WithdrawFundsBlock />
                                <section className='withdrawal-money'>
                                    <div className="withdrawal-money-text">
                                        <p>{t("FINANCES.withdrawal_amount")}</p>

                                        <p>${completedBalance}</p>
                                    </div>

                                    <div className='withdrawal-money-border'></div>
                                    <div className={`${showWithdrawForm ? 'd-none' : ''} buttons-wrapper`}>
                                        <button className="withdrawal-money-button" onClick={() => { setShowWithdrawForm(true) }}>Withdraw</button>
                                    </div>
                                    <div className={`${showWithdrawForm ? '' : 'd-none'} withdraw-add-wrapper`}>
                                        <p>{t("PAYMENTS.withdraw_funds_desc")}</p>
                                        <div className="added-cards-wrapper">
                                            {payments?.length > 0 ? <>
                                                {payments?.map((item, key) => {
                                                    return <div className="added-card d-flex justify-content-between cursor-pointer user-select-none" key={key} onClick={() => { setCheckedWithDrawFund(item) }}>
                                                        <div className="d-flex card-info-wrapper">
                                                            <div><img src={item.image} alt="" /></div>
                                                            <div>
                                                                <h6>{item.type} {item.number}</h6>
                                                                <p>{t("PAYMENTS.expiration")}: {item.expiration}</p>
                                                            </div>
                                                            {/* <div>
                                                            <div className={`${item.default ? '' : 'd-none'} default`}>{t("PAYMENTS.default")}</div>
                                                        </div> */}
                                                        </div>
                                                        <div className={`custom-check ${checkedWithDrawFund.card_number == item.card_number ? 'active' : ''}`}></div>
                                                    </div>
                                                })}
                                            </> : ''}
                                        </div>
                                        <input
                                            type="text"
                                            name="withdrawAmount"
                                            placeholder={t("FINANCES.enter_withdrawal_amount")}
                                            value={withdrawAmount}
                                            onChange={(e) => { setWithdrawAmount(e.target.value) }}
                                            ref={inputRef}
                                            className="mt-50"
                                        />
                                        <div className="withdraw-buttons-wrapper">
                                            <button className="save-btn info-btn use-withdraw" onClick={addTransaction}>{t("FINANCES.accept")}</button>
                                            <button className="img-btn cancel-btn" onClick={() => { setShowWithdrawForm(false) }}>{t("FINANCES.cancel")}</button>
                                        </div>
                                    </div>
                                </section>
                            </div>

                        </Tab>
                        <Tab eventKey="history" title={t("FINANCES.history")}>
                            <FinanceHistoryBlock />
                        </Tab>
                    </Tabs>
                }
            </section>
        </Fragment >
    );
}

export default Finances;