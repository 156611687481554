import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { UserApi } from '../../api';
import Context from './index';
import { toast } from "react-toastify";

const UserProvider = (props) => {
    const [id, setId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');

    const [username, setUsername] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [balanceMain, setBalanceMain] = useState(0);
    const [balanceAvailable, setBalanceAvailable] = useState(0);
    const [role, setRole] = useState(null);
    const [verified, setVerified] = useState(null);
    const [driverLicense, setDriverLicense] = useState(null);

    const [completedBalance, setCompletedBalance] = useState('');
    const [reservedBalance, setReservedBalance] = useState('');
    const [upcomingBalance, setUpcomingBalance] = useState('');

    const [guestUser, setGuestUser] = useState(null);

    const [transactions, setTransactions] = useState(null);
    const [withdrawFunds, setWithdrawFunds] = useState(null);
    useEffect(() => {
        if (null == guestUser) {
            let guest = JSON.parse(sessionStorage.getItem('guest')) ?? {
                id: 'guest-' + Date.now(),
                firstName: 'guest',
                lastName: 'guest',
                email: null,
                photoUrl: '',
            };
            sessionStorage.setItem('guest', JSON.stringify(guest));
            setGuestUser(guest);
        }
    }, [guestUser])


    const getCurrentUserData = async () => {
        try {
            await axios.get(UserApi.getAll, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setId(resp.data?.id ?? '');
                setFirstName(resp.data?.firstName ?? '');
                setLastName(resp.data?.lastName ?? '');
                setEmail(resp.data?.email ?? '');
                setPhone(resp.data?.phone ?? '');
                setGender(resp.data?.gender ?? '');
                setUsername(resp.data?.username ?? '');
                setImageUrl(resp.data?.imageUrl ?? '');
                setBankName(resp.data?.bankName ?? '');
                setBankAccount(resp.data?.bankAccount ?? '');
                setBalanceMain(resp.data?.balanceMain ?? 0);
                setBalanceAvailable(resp.data?.balanceAvailable ?? 0);
                setRole(resp.data?.role ?? 'renter');
                setVerified(resp.data?.verified ?? false);
                setDriverLicense(resp.data?.driverLicense);
                setCompletedBalance(resp.data?.completed_balance ?? 0);
                setReservedBalance(resp.data?.reserved_balance?? 0);
                setUpcomingBalance(resp.data?.upcoming_balance ?? 0);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    const updateCurrentUserBankData = async (bankName, bankAccount, callback = null) => {
        try {
            await axios.post(UserApi.updateBanking, {
                bankName: bankName,
                bankAccount: bankAccount,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setBankName(bankName ?? '');
                    setBankAccount(bankAccount ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserNameData = async (firstName, lastName, callback = null) => {
        try {
            await axios.post(UserApi.updateNameData, {
                firstName: firstName,
                lastName: lastName,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setFirstName(firstName ?? '');
                    setLastName(lastName ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserEmailData = async (email, callback = null) => {
        try {
            await axios.post(UserApi.updateEmailData, {
                email: email,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setEmail(email ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserPhoneData = async (phone, callback = null) => {
        try {
            await axios.post(UserApi.updatePhoneData, {
                phone: phone,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setPhone(phone ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserGenderData = async (gender, callback = null) => {
        try {
            await axios.post(UserApi.updateGenderData, {
                gender: gender,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setGender(gender ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserPersonalData = async (firstName, lastName, email, phone, callback = null) => {
        try {
            await axios.post(UserApi.updatePersonalData, {
                email: email,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setFirstName(firstName ?? '');
                    setLastName(lastName ?? '');
                    setEmail(email ?? '');
                    setPhone(phone ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const updateCurrentUserPassword = async (newPassword, oldPassword, callback = null) => {
        try {
            await axios.post(UserApi.updatePassword, {
                newPassword: newPassword,
                oldPassword: oldPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    console.log('password changed');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };


    const updateCurrentUserAvatar = async (file, callback = null) => {
        try {

            let formData = new FormData();
            formData.append("newAvatar", file);
            await axios.post(UserApi.updateAvatar, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setImageUrl(resp.data?.imageUrl ?? '');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const deleteCurrentUserAvatar = async (callback = null) => {
        try {
            await axios.delete(UserApi.deleteAvatar, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setImageUrl('');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const makeCurrentUserOwner = async (callback = null) => {
        try {
            await axios.post(UserApi.becameOwner, {}, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setRole('owner');
                }
                if (typeof callback === "function") {
                    callback();
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const sendVerificationEmail = async () => {
        try {
            await axios.post(UserApi.verify, { origin: window.location.origin }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                console.log(resp.data);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const checkVerificationCode = async (code, errorMessage) => {
        console.log(code);
        try {
            await axios.post(UserApi.checkCode, { code }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                console.log(resp.data);
                if (!!resp?.data?.verified) {
                    setVerified(true);
                } else {
                    toast.error(errorMessage);
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const addWithdrawTransaction = async (amount, card_holder, card_number, expiration, account_type) => {
        try {
            await axios.post(UserApi.addWithdraw, {
                amount,
                card_holder,
                card_number,
                expiration,
                account_type,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setRole('owner');
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const getTransactions = async () => {
        try {
            await axios.get(UserApi.getTransactions, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setTransactions(resp.data)
                }
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };
    const getWithdrawFunds = async () => {
        try {
            await axios.get(UserApi.getWithdrawFunds, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp?.data?.funds) {
                    setWithdrawFunds(resp?.data?.funds)
                } else {
                    setWithdrawFunds([])
                }
            });
        } catch (error) {
            setWithdrawFunds([])
            console.log('cancelled', error);
        }
    };
    const addFunds = async (newItem) => {
        let funds = [...withdrawFunds];
        if (funds.length == 0) {
            newItem.default = true
        }
        funds.push(newItem);
        try {
            await axios.post(UserApi.updateFunds, { funds }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data?.funds) {
                    setWithdrawFunds(resp.data?.funds)
                }
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    }
    const deleteFunds = async (index) => {
        let funds = [...withdrawFunds];
        if (funds[index].default == true) {
            if (index == 0) {
                if (!!funds[1]) {
                    funds[1].default = true
                }
            } else {
                if (!!funds[0]) {
                    funds[0].default = true
                }
            }
        }
        funds.splice(index, 1);
        try {
            await axios.post(UserApi.updateFunds, { funds }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data?.funds) {
                    setWithdrawFunds(resp.data?.funds)
                }
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    }
    const logout = () => {
        localStorage.removeItem('token');

        setId('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setGender('');
        setUsername('');
        setImageUrl('');
        setBankName('');
        setBankAccount('');
        setBalanceMain(0);
        setBalanceAvailable(0);
        setRole('');
    }

    const updateDriverLicense = async (payload) => {
        try {
            await axios.post(UserApi.updateDriverLicense, {
                ...payload
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    // let driverLicenseNew = {
                    //     ...driverLicense,
                    //     verified: 'on_verification',
                    // };
                    setDriverLicense({ ...resp.data });
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    return (
        <Context.Provider
            value={{
                ...props,
                getCurrentUserData,
                updateCurrentUserNameData,
                updateCurrentUserEmailData,
                updateCurrentUserPhoneData,
                updateCurrentUserGenderData,
                updateCurrentUserBankData,
                updateCurrentUserPersonalData,
                updateCurrentUserPassword,
                updateCurrentUserAvatar,
                deleteCurrentUserAvatar,
                makeCurrentUserOwner,
                sendVerificationEmail,
                checkVerificationCode,
                updateDriverLicense,
                addWithdrawTransaction,
                getTransactions,
                getWithdrawFunds,
                addFunds,
                deleteFunds,
                logout,
                id,
                firstName,
                lastName,
                email,
                phone,
                gender,
                username,
                imageUrl,
                bankName,
                bankAccount,
                balanceMain,
                balanceAvailable,
                guestUser,
                verified,
                role,
                driverLicense,
                transactions,
                withdrawFunds,
                completedBalance,
                reservedBalance,
                upcomingBalance,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default UserProvider;