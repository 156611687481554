import React, { useContext, useEffect, useState } from "react";

import automaticPng from "../../assets/img/Frame 13.png";
import dieselPng from "../../assets/img/bi_fuel-pump-diesel.png";
import SettingsContext from "../../_helper/Settings";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const HomeFeaturedCar = () => {

    const [featuredCar, setFeaturedCar] = useState(null);

    const {
        homeFeaturedProduct
    } = useContext(SettingsContext);

    useEffect(() => {
        console.log(homeFeaturedProduct);
        if (null != homeFeaturedProduct) {
            setFeaturedCar({ ...homeFeaturedProduct });
        }
    }, [homeFeaturedProduct]);
    const { t } = useTranslation();
    return (
        <>
            {null != featuredCar && <>
                <div className="d-flex align-items-center justify-content-center flex-column rent-wrap">
                    <Link to={`/vehicle/${featuredCar.id}`}>{t("HOME_FEATURED_CAR.rent_now")}</Link>
                    <p>{featuredCar.title}</p>
                </div>
                <div className="d-flex flex-grow-1 pricing-wrap">
                    <div className="price-wrap">
                        <p>{t("HOME_FEATURED_CAR.start_from")}</p>
                        <h5 className="price">
                            <sup>$</sup>{featuredCar.price.full}{!!featuredCar.price.decimal && <sup>,{featuredCar.price.decimal}</sup>}<sub>{t("HOME_FEATURED_CAR.daily")}</sub>
                        </h5>
                    </div>
                    <div className="model">
                        <h4>{featuredCar.title}</h4>
                        <div className="types d-flex">
                            {!!featuredCar.fuel &&
                                <div className="fuel-type d-flex">
                                    <img src={dieselPng} alt="" />
                                    <p>{featuredCar.fuel}</p>
                                </div>
                            }
                            {!!featuredCar.transmission &&
                                <div className="transmisson-type d-flex">
                                    <img src={automaticPng} alt="" />
                                    <p>{featuredCar.transmission}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default HomeFeaturedCar
