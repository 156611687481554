import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import logoLight from "../../assets/img/Logo-light.png"
import "../../assets/css/footer.css"

import socialFacebook from "../../assets/img/social/rounded/akar-icons_facebook-fill.png"
import socialTwitter from "../../assets/img/social/rounded/ant-design_twitter-outlined.png"
import socialInstagram from "../../assets/img/social/rounded/bxl_instagram-alt.png"
import socialPinterest from "../../assets/img/social/rounded/akar-icons_pinterest-fill.png"
import socialFeed from "../../assets/img/social/rounded/fa_feed.png"

import GooglePlay from "../../assets/img/Google Play.png"
import AppStore from "../../assets/img/App Store.png"
import { Link } from 'react-router-dom';
import ChatPopup from './../Header/ChatPopup';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const socials = [
    {
      link: "#",
      image: socialFacebook
    },
    {
      link: "#",
      image: socialTwitter
    },
    {
      link: "#",
      image: socialInstagram
    },
    {
      link: "#",
      image: socialPinterest
    },
    {
      link: "#",
      image: socialFeed
    },
  ]

  return (
    <Fragment>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col d-flex footer-wrapper">
              <div className="footer-left-col">
                <a href="index.html" className="footer-brand">
                  <img src={logoLight} alt="" />
                </a>
                <p className="footer-description"> {t("FOOTER.footer_description")} </p>
                <div className="d-flex social-wrapper">
                  {socials.map((s, i) => {
                    return <a href={s.link} className="social-link" key={i}>
                      <img src={s.image} alt="" />
                    </a>
                  })}
                </div>
              </div>
              <div className="footer-right-col">
                <div className='menu-footer-block'>
                  <ul className="menu-footer">
                    <li className="menu-item menu-title">
                      <span>{t("FOOTER.pages.menu_title")}</span>
                    </li>
                    <li className="menu-item">
                      <Link to="/about">{t("FOOTER.pages.about_us")}</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/vehicle-list">{t("FOOTER.pages.explore_vehicles")}</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/news">{t("FOOTER.pages.news")}</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/contact">{t("FOOTER.pages.contact")}</Link>
                    </li>
                  </ul>
                </div>
                <div className='menu-footer-block'>
                  <ul className="menu-footer">
                    <li className="menu-item menu-title">
                      <span>{t("FOOTER.information.menu_title")}</span>
                    </li>
                    <li className="menu-item">
                      <Link to="/faq">{t("FOOTER.information.faq")}</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/terms-of-service">{t("FOOTER.information.rental_terms")}</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/privacy-policy">{t("FOOTER.information.privacy_policy")}</Link>
                    </li>
                  </ul>
                </div>
                <div className="download-app-column">
                  <p className="menu-title"><a href="#">{t("FOOTER.app_download.menu_title")}</a></p>
                  <p className="description">{t("FOOTER.app_download.description")}</p>
                  <div className="d-flex app-links-wrapper">
                    <a href="#" className="app-link d-flex align-items-center">
                      <img src={GooglePlay} alt="" />
                    </a>
                    <a href="#" className="app-link d-flex align-items-center">
                      <img src={AppStore} alt="" />
                    </a>
                  </div>
                </div>
                <div className="subscribe-col">
                  <p className="menu-title"><a href="#">{t("FOOTER.subscribe.menu_title")}</a></p>
                  <p className="description">{t("FOOTER.subscribe.description")}</p>
                  <label className="subscribe-wrapper js_subscribe_wrapper">
                    <input type="text" className="subscribe-input js_subscribe_email" placeholder={t("FOOTER.subscribe.email")} />
                    <button className="btn btn-subscribe js_send_subscribe_request">{t("FOOTER.subscribe.button")}</button>
                  </label>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='d-flex justify-content-between align-items-center' style={{marginTop: '60px'}}>
                <div className='copyright'>{t("FOOTER.copyright")}</div>
                <div className='made_by'>{t("FOOTER.made_by")}</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ChatPopup />
    </Fragment>
  );
};

export default Footer;