import React, { Fragment, useEffect, useState } from "react";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

const TimepickerInline = ({ id, className, name, handleOnChange, options, selected, ref, selectValue }) => {
    const [checked, setChecked] = useState(selected);

    useEffect(() => {
        setChecked(selected);
    }, [selected]);
    return (
        <>
            {(options?.length && selected != null) > 0 &&
                <div className="ui-front">
                    {
                        options.map((item, key) => {
                            return <label className="d-flex align-items-center mx-0 cursor-pointer user-select-none" style={{ gap: '14px' }} key={key} onClick={(e) => { handleOnChange(item.value) }}>
                                <div className={`custom-check ${checked == item.value ? 'active' : ''}`}></div>
                                {/* <input js-valid="true" type="radio" name={name} value={item.value} checked={checked == item.value} onChange={(e) => { handleOnChange(e.target.value) }} /> */}
                                {item.name}
                            </label>
                        })
                    }
                </div>
            }
        </>
    );
}

export default TimepickerInline;
