import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NotificationsApi } from '../../api';
import Context from './index';
import { toast } from "react-toastify";

const NotificationsProvider = (props) => {
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState([]);

    const getNotifications = async () => {
        try {
            setLoading(true);
            await axios.get(NotificationsApi.getAll, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setLoading(false);
                setNotifications(resp?.data);
            });
        } catch (error) {
            setLoading(false);
            console.log('cancelled', error);
        }
    };

    const getNewNotifications = async () => {
        try {
            let params = {};
            await axios.get(NotificationsApi.getNew, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setNewNotifications([...resp?.data]);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    const markNotificationRead = async (id) => {
        try {
            await axios.post(NotificationsApi.markRead, {
                id: id,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp?.data?.updated) {
                    let currentNotifications = notifications.map((item) => {
                        if (item.id == resp?.data?.id) {
                            item.new = false;
                        }
                        return { ...item }
                    });
                    setNotifications(currentNotifications);
                    let currentNewNotifications = newNotifications.map((item) => {
                        if (item.id == resp?.data?.id) {
                            item.new = false;
                        }
                        return { ...item }
                    });
                    setNewNotifications(currentNewNotifications);
                }
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };


    const markAllNotificationsRead = async (id) => {
        try {
            let ids = notifications.filter((item) => item.new == true).map(item => item.id);
            await axios.post(NotificationsApi.markAllRead, {
                ids: ids,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp?.data?.length) {
                    let currentNotifications = notifications.map((item) => {
                        let upd = resp?.data.find((n) => item.id == n.id);
                        if (!!upd && !!upd?.updated) {
                            item.new = false;
                        }
                        return { ...item }
                    });
                    setNotifications(currentNotifications);

                    let currentNewNotifications = newNotifications.map((item) => {
                        let upd = resp?.data.find((n) => item.id == n.id);
                        if (!!upd && !!upd?.updated) {
                            item.new = false;
                        }
                        return { ...item }
                    });
                    setNewNotifications(currentNewNotifications);
                }
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    return (
        <Context.Provider
            value={{
                ...props,
                getNotifications,
                getNewNotifications,
                markNotificationRead,
                markAllNotificationsRead,
                notifications,
                newNotifications,
                loading,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default NotificationsProvider;