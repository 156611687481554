import React from 'react';
import Routers from './Route';
// import ChartistProvider from './_helper/Chartist/ChartistProvider';
import AttributesProvider from './_helper/Attributes/AttributesProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import MapsJsApiProvider from './_helper/MapsJsApi/MapsJsApiProvider';
import ProductsProvider from './_helper/Products/ProductsProvider';
import RatingsProvider from './_helper/Ratings/RatingsProvider';
import UserProvider from './_helper/User/UserProvider';

import "./assets/css/icofont.css";
import "./assets/css/bugfix.css";
import "./assets/css/media.css";
import SettingsProvider from './_helper/Settings/SettingsProvider';
import OrdersProvider from './_helper/Orders/OrdersProvider';
import ChatProvider from './_helper/Chat/ChatProvider';
import NotificationsProvider from './_helper/Notification/NotificationsProvider';

const App = () => (
  <div className='App'>
    <CustomizerProvider>
      <SettingsProvider>
        <MapsJsApiProvider>
          <ChatProvider>
            <OrdersProvider>
              <UserProvider>
                <NotificationsProvider>
                  <RatingsProvider >
                    <AttributesProvider>
                      <ProductsProvider>
                        <Routers />
                      </ProductsProvider>
                    </AttributesProvider>
                  </RatingsProvider>
                </NotificationsProvider>
              </UserProvider>
            </OrdersProvider>
          </ChatProvider>
        </MapsJsApiProvider>
      </SettingsProvider>
    </CustomizerProvider>
  </div>
);

export default App;
