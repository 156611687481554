
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../../_helper/User";

import Loading from "../../../Components/Common/Loading";

import arrow from '../../../assets/img/rotate-left.svg';
import Status from './../../../Components/Common/Status';

const FinanceHistoryBlock = () => {
    const { t } = useTranslation();

    const {
        transactions,
        getTransactions,
    } = useContext(UserContext);

    const [transactionsLoading, setTransactionsLoading] = useState(true);

    useEffect(() => {
        getInitData();
    }, [])

    const getInitData = async () => {
        setTransactionsLoading(true);
        await getTransactions();
        setTransactionsLoading(false);
    }

    return (
        <>
            <section className="finance-history-section">
                {transactionsLoading
                    ? <div className="d-flex align-items-center justify-content-center my-5">
                        <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                    </div>
                    : <>
                        <div className="d-flex justify-content-end">
                            <img src={arrow} alt="" className="cursor-pointer" onClick={getInitData} />
                        </div>
                        <table className="finance-history-table">
                            <thead>
                                <tr>
                                    <th>{t("FINANCES.name")}</th>
                                    <th>{t("FINANCES.order_n")}</th>
                                    <th>{t("FINANCES.status")}</th>
                                    <th>{t("FINANCES.amount")}</th>
                                    <th>{t("FINANCES.date")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions?.length > 0
                                    ? <>
                                        {transactions.map((item, key) => {
                                            return (
                                                <OrdersHistoryRow block={item} key={key} />
                                            )
                                        })}
                                    </>
                                    : <>
                                        <tr>
                                            <td className="text-start" colSpan={5}>{t("FINANCES.no_withdrawal_requests")}</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                    </>
                }

            </section>
        </>
    )
}
const OrdersHistoryRow = ({ block }) => {
    const { t } = useTranslation();

    return (
        <tr>
            <td className="d-flex align-items-center renter-img">
                {!!block.renter_img && <img src={block.renter_img} alt="" />}
                {!!block.name && <span className="renter-name">{block.name}</span>}
            </td>
            {block.type == 'withdraw'
                ? <td>{t('FINANCES.withdraw')} #{block.id}</td>
                : <td>#{!!block.order_number ? block.order_number : block.id}</td>
            }

            <td>
                <Status status={block?.status} />
            </td>
            <td>${block.amount}</td>
            <td>{`${block.start_date && block.end_date ? `${block.start_date} - ${block.end_date}` : block.create_date}`}</td>
        </tr>
    )
}

export default FinanceHistoryBlock;