
import parse from 'html-react-parser';
import React, { Fragment, useState, useEffect, useContext } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import star from '../../assets/img/Star.png';
import marker from "../../assets/img/majesticons_map-marker-line.png";
import calendar from "../../assets/img/Calendar.png";

import { useNavigate } from "react-router-dom";
import ProductsContext from '../../_helper/Products';
import DatePicker from './../VehicleForm/datepicker';
import SelectMenu from './../VehicleForm/selectmenu';
import Loading from './../Common/Loading';
import OrdersContext from '../../_helper/Orders';
import UserContext from '../../_helper/User';
import MapsJsApiContext from './../../_helper/MapsJsApi/index';
import { useTranslation } from "react-i18next";

const VehicleSidebar = ({ vehicle }) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const {
        defaultTimeOptions,
        productListFilters,
        productForView,
        singleProduct_book_date_from,
        singleProduct_book_date_to,
        singleProduct_book_time_from,
        singleProduct_book_time_to,
        singleProduct_duration_days,
        singleProduct_duration_hours,
        singleProduct_duration_daily_amount,
        singleProduct_duration_hourly_amount,
        singleProduct_driver_days,
        singleProduct_driver_amount,
        singleProduct_delivery_amount,
        singleProduct_total_amount,
        singleProduct_loading,
        singleProduct_error,
        setSingleProductBookField,
        checkProductAvailability,
    } = useContext(ProductsContext);

    const {
        addOrder
    } = useContext(OrdersContext);

    const { verified, driverLicense, getCurrentUserData } = useContext(UserContext);

    useEffect(() => {
        getCurrentUserData()
    }, [])

    const [deliverAtDifferentPoint, setDeliverAtDifferentPoint] = React.useState(false);
    const [deliverPoint, setDeliverPoint] = React.useState(null);
    const {
        isLoaded,
    } = useContext(MapsJsApiContext);
    const setDeliveryPointAndLatLng = (value) => {
        if (!!value) {
            setDeliverPoint(value);
        } else {
            setDeliverPoint(null);
        }
    }

    const handleDeliverAtDifferentPoint = (e) => {
        setDeliverPoint(null);
        setDeliverAtDifferentPoint(e.target.checked);
    }

    const [useDriver, setUseDriver] = React.useState(false);
    const handleUseDriver = (e) => {
        setUseDriver(e.target.checked);
    }
    React.useEffect(() => {
        if (productForView?.hasDriver && productForView?.driverRequired) {
            setUseDriver(true);
        }
    }, [productForView?.id]);

    React.useEffect(() => {
        if (!!productForView?.id && !!singleProduct_book_date_from && !!singleProduct_book_time_from && !!singleProduct_book_date_to && !!singleProduct_book_time_to) {
            checkProductAvailability(useDriver);
        } else {
            console.log('not all selected');
        }
    }, [
        productForView?.id,
        singleProduct_book_time_from,
        singleProduct_book_time_to,
        useDriver,
    ]);

    React.useEffect(() => {
        let bookOptions = {};
        if (productListFilters.book_date_from) {
            bookOptions.book_date_from = productListFilters.book_date_from;
        }
        if (productListFilters.book_time_from) {
            bookOptions.book_time_from = productListFilters.book_time_from;
        }
        if (productListFilters.book_date_to) {
            bookOptions.book_date_to = productListFilters.book_date_to;
        }
        if (productListFilters.book_time_to) {
            bookOptions.book_time_to = productListFilters.book_time_to;
        }
        setSingleProductBookField(bookOptions)
    }, []);

    const makeBookRequest = async () => {
        let payload = {
            pickup_date: singleProduct_book_date_from,
            pickup_time: singleProduct_book_time_from,
            dropoff_date: singleProduct_book_date_to,
            dropoff_time: singleProduct_book_time_to,
            quote_price: '',
            product_id: productForView.id,
            deliver_at_different_point: deliverAtDifferentPoint,
            delivery_point: deliverPoint,
            useDriver: useDriver,
        }
        if (!!productForView?.id && !!singleProduct_book_date_from && !!singleProduct_book_time_from && !!singleProduct_book_date_to && !!singleProduct_book_time_to) {
            let res = await addOrder(payload);
            setLoading(false);
            if (true == res) {
                toast.success(t("VEHICLE_SIDEBAR.booked_succesfully"));
            } else {
                toast.error(t("VEHICLE_SIDEBAR.went_wrong"));
            }
        }
    }

    const [time_from_options, setTimeFromOptions] = useState(defaultTimeOptions);
    const [time_to_options, setTimeToOptions] = useState(defaultTimeOptions);

    useEffect(() => {
        if (!!singleProduct_book_date_from) {
            let [day, month, year] = singleProduct_book_date_from.split('.');
            let date = `${month}/${day}/${year}`;
            if (!!productForView?.periods?.allowed_datetime[date]) {
                console.log(productForView?.periods?.allowed_datetime[date]);
                let time_from_options_new = defaultTimeOptions.filter((item) => {
                    return productForView?.periods?.allowed_datetime[date].includes(item.value);
                });
                console.log(time_from_options_new);
                setTimeFromOptions(time_from_options_new);
            }
            else {
                setTimeFromOptions(defaultTimeOptions);
            }
        } else {
            setTimeFromOptions(defaultTimeOptions);
        }
        setSingleProductBookField({ 'book_time_from': '' });
    }, [singleProduct_book_date_from])

    useEffect(() => {
        if (!!singleProduct_book_date_to) {
            let [day, month, year] = singleProduct_book_date_to.split('.');
            let date = `${month}/${day}/${year}`;
            if (!!productForView?.periods?.allowed_datetime[date]) {
                console.log(productForView?.periods?.allowed_datetime[date]);
                let time_to_options_new = defaultTimeOptions.filter((item) => {
                    return productForView?.periods?.allowed_datetime[date].includes(item.value);
                });
                console.log(time_to_options_new);
                setTimeToOptions(time_to_options_new);
            }
            else {
                setTimeToOptions(defaultTimeOptions);
            }
        } else {
            setTimeToOptions(defaultTimeOptions);
        }
        setSingleProductBookField({ 'book_time_to': '' });
    }, [singleProduct_book_date_to])

    return (
        <div className="sidebar-widget d-flex flex-column">
            {productForView?.verified
                ? <>
                    <h2 className="price">
                        {productForView?.daily_price > 0 && <>${productForView?.daily_price} {t("VEHICLE_SIDEBAR.day")}</>}
                        {productForView?.hourly_price > 0 && <small className="text-secondary mt-2 d-block">${productForView?.hourly_price} {t("VEHICLE_SIDEBAR.hour")}</small>}
                    </h2>
                    {!verified
                        ? <Link to={`${process.env.PUBLIC_URL}/verification/`} className="secondary-button text-decoration-none text-white d-block">{t("VEHICLE_SIDEBAR.verification_required")}</Link>
                        : <>
                            {driverLicense.verified != 'verified'
                                ? <Link to={`${process.env.PUBLIC_URL}/driver-license/`} className="secondary-button text-decoration-none text-white d-block">{t("VEHICLE_SIDEBAR.verified_driver_license_required")}</Link>
                                : <div className="d-flex flex-column sidebar-filters">
                                    <div>
                                        <p className="filter-title">{t("VEHICLE_SIDEBAR.trip_start")}</p>
                                        <div className="date-input-group d-flex" disabled={singleProduct_loading}>
                                            <DatePicker
                                                handleOnChange={(value) => { setSingleProductBookField({ 'book_date_from': value }) }}
                                                selected={singleProduct_book_date_from}
                                                placeholder={t("VEHICLE_SIDEBAR.date")}
                                                max={singleProduct_book_date_to}
                                                diasbledDates={productForView?.periods?.availability}
                                            />
                                            {/* {singleProduct_book_date_from} */}
                                            <div className="divider"></div>
                                            <SelectMenu
                                                id="time-picker-from"
                                                name="time-picker-from"
                                                className={``}
                                                options={time_from_options}
                                                handleOnChange={(value) => { setSingleProductBookField({ 'book_time_from': value }) }}
                                                selected={singleProduct_book_time_from}
                                                selectValue={t("VEHICLE_SIDEBAR.time")}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="filter-title">Trip end</p>
                                        <div className="date-input-group d-flex" style={{ zIndex: '99' }} disabled={singleProduct_loading}>
                                            <DatePicker
                                                handleOnChange={(value) => { setSingleProductBookField({ 'book_date_to': value }) }}
                                                selected={singleProduct_book_date_to}
                                                placeholder={t("VEHICLE_SIDEBAR.date")}
                                                min={singleProduct_book_date_from}
                                                diasbledDates={productForView?.periods?.availability}
                                            />
                                            <div className="divider"></div>
                                            <SelectMenu
                                                id="time-picker-to"
                                                name="time-picker-to"
                                                className={``}
                                                options={time_to_options}
                                                handleOnChange={(value) => { setSingleProductBookField({ 'book_time_to': value }) }}
                                                selected={singleProduct_book_time_to}
                                                selectValue={t("VEHICLE_SIDEBAR.time")}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="filter-title">{t("VEHICLE_SIDEBAR.pickup_return_location")}</p>
                                        <a href="#vehicleLocation" className="addres-filter d-flex align-items-center text-decoration-none cursor-pointer">
                                            <img src={marker} alt="" />
                                            <input type="text" readOnly placeholder={t("VEHICLE_SIDEBAR.pick_up_location")} value={productForView?.place?.label} />
                                        </a>
                                    </div>
                                    {!!productForView?.deliveryToRenter &&
                                        <div>
                                            <p className="filter-title">{t("VEHICLE_SIDEBAR.delivery")}</p>
                                            {!!isLoaded &&
                                                <GooglePlacesAutocomplete
                                                    apiOptions={{ language: 'en', region: 'AM' }}
                                                    onLoadFailed={(error) => (
                                                        console.error("Could not inject Google script", error)
                                                    )}
                                                    disabled={!deliverAtDifferentPoint}
                                                    selectProps={{
                                                        placeholder: t("VEHICLE_SIDEBAR.select_delivery_point"),
                                                        className: 'input-no-border your-car-email',
                                                        value: deliverPoint,
                                                        onChange: setDeliveryPointAndLatLng,
                                                        styles: {
                                                            container: (provided) => ({
                                                                ...provided,
                                                                width: '100%',
                                                                opacity: !deliverAtDifferentPoint ? '0.5' : '1',
                                                                pointerEvents: !deliverAtDifferentPoint ? 'none' : 'all',
                                                            }),
                                                            control: (provided) => ({
                                                                ...provided,
                                                                border: '1px solid #D8D8D8',
                                                                boxShadow: 'unset',
                                                                borderRadius: '5px',
                                                                padding: '8px 15px',
                                                                borderColor: '#D8D8D8',
                                                                accentColor: '#D8D8D8',
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                padding: '0',
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                border: 'unset !important',
                                                                padding: '0',
                                                                margin: '0',
                                                            }),
                                                            indicatorsContainer: (provided) => ({
                                                                ...provided,
                                                                display: 'none',
                                                            }),
                                                        }
                                                    }}
                                                />
                                            }
                                            <div className="d-flex align-items-center">
                                                <input type="checkbox" id="delivery-checkbox" className='form-checkbox' onChange={handleDeliverAtDifferentPoint} />
                                                <label className="checkbox-label" htmlFor="delivery-checkbox">{t("VEHICLE_SIDEBAR.deliver_at_different_point")}</label>
                                            </div>
                                        </div>

                                    }
                                    {!!productForView?.hasDriver &&
                                        <div>
                                            <p className="filter-title">{t("VEHICLE_SIDEBAR.driver")}</p>
                                            <div className="d-flex align-items-center">
                                                <input disabled={productForView?.driverRequired} checked={useDriver} type="checkbox" className='form-checkbox' id="driver-checkbox" onChange={handleUseDriver} />
                                                <label className="checkbox-label" htmlFor="driver-checkbox">{t("VEHICLE_SIDEBAR.with_driver")}</label>
                                            </div>
                                        </div>
                                    }
                                    {singleProduct_loading
                                        ? <div className="d-flex align-items-center justify-content-center my-5">
                                            <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                                        </div>
                                        : <>
                                            {
                                                !!singleProduct_error
                                                    ? <>{singleProduct_error}</>
                                                    : <>
                                                        {
                                                            singleProduct_total_amount > 0 &&
                                                            <div className="amount-wrapper">
                                                                <table className="vehicle-purchase-table">
                                                                    <tbody>
                                                                        {
                                                                            singleProduct_duration_days > 0 && <tr>
                                                                                <th>${productForView?.daily_price} × {singleProduct_duration_days} {t("VEHICLE_SIDEBAR.days")}</th>
                                                                                <td>${singleProduct_duration_daily_amount}</td>
                                                                            </tr>
                                                                        }
                                                                        {
                                                                            singleProduct_duration_hours > 0 && <tr>
                                                                                <th>${productForView?.hourly_price} × {singleProduct_duration_hours} {t("VEHICLE_SIDEBAR.hours")}</th>
                                                                                <td>${singleProduct_duration_hourly_amount}</td>
                                                                            </tr>
                                                                        }
                                                                        {
                                                                            (productForView?.hasDriver
                                                                                && !productForView?.driverRequired
                                                                                && useDriver
                                                                                && singleProduct_driver_days > 0)
                                                                            && <>
                                                                                <tr>
                                                                                    <th><b>{t("VEHICLE_SIDEBAR.driver")}</b></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>${productForView?.driverDailyPrice} × {singleProduct_driver_days} {t("VEHICLE_SIDEBAR.days")}</th>
                                                                                    <td>${singleProduct_driver_amount}</td>
                                                                                </tr>
                                                                            </>
                                                                        }
                                                                        {
                                                                            singleProduct_delivery_amount > 0 && <tr>
                                                                                <th>{t("VEHICLE_SIDEBAR.delivery")}</th>
                                                                                <td>${singleProduct_delivery_amount}</td>
                                                                            </tr>
                                                                        }
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <th>{t("VEHICLE_SIDEBAR.total")}</th>
                                                                            <td>${singleProduct_total_amount}</td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                                <button type="button" onClick={() => { makeBookRequest() }} className="book-btn">{t("VEHICLE_SIDEBAR.book_now")}</button>
                                                            </div>
                                                        }
                                                    </>
                                            }
                                        </>
                                    }

                                </div>
                            }
                        </>
                    }
                </>
                : <>
                    <div className="secondary-button text-decoration-none text-white d-block">{t("VEHICLE_SIDEBAR.vehicle_is_not_verification")}</div>
                </>
            }

        </div >
    );
}

export default VehicleSidebar;
