import React, { Fragment, useState } from "react";
import calendar from "../../assets/img/Calendar.png";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

const DatePickerInline = ({ id, className, name, handleOnChange, selected, ref, placeholder, min, max, diasbledDates }) => {

    const datepickerRef = React.useRef(null);

    const [datepickerOpened, setDatepickerOpened] = useState(false);
    const [datepickerValue, setDatepickerValue] = useState(selected);

    const formatDate = (date) => {
        let todayMoth = date.getMonth() + 1;
        let todayDay = date.getDate();
        todayDay = todayDay > 9 ? todayDay : '0' + todayDay;
        let todayYear = date.getFullYear();
        todayMoth = todayMoth > 9 ? todayMoth : '0' + todayMoth;
        return `${todayMoth}/${todayDay}/${todayYear}`;
    }

    React.useEffect(() => {
        if (null != selected) {
            $(datepickerRef.current).datepicker({
                minDate: new Date(),
                dateFormat: "dd.mm.yy",
                showMonthAfterYear: false,
                onSelect: (date) => {
                    setDatepickerValue(date);
                },
                beforeShow: function () {
                    setDatepickerOpened(true);
                },
                onClose: function () {
                    setDatepickerOpened(false);
                },
                beforeShowDay: function (date ) {
                    let isActive = true;
                    if (!!diasbledDates) {
                        let dayFormatted = formatDate(date);
                        if (diasbledDates.includes(dayFormatted)) {
                            isActive = false;
                        }
                    }
                    return [isActive, '', ''];
                },
            });

            $(datepickerRef.current).datepicker( "setDate", selected )
        }
    }, [selected]);

    React.useEffect(() => {
        if (!!min) {
            $(datepickerRef.current).datepicker("option", "minDate", min);
        } else {
            $(datepickerRef.current).datepicker("option", "minDate", new Date());
        }
    }, [min]);
    React.useEffect(() => {
        if (!!max) {
            $(datepickerRef.current).datepicker("option", "maxDate", max);
        } else {
            $(datepickerRef.current).datepicker("option", "maxDate", '');
        }
    }, [max]);

    React.useEffect(() => {
        handleOnChange(datepickerValue)
    }, [datepickerValue]);

    return (
        <>
            {selected != null &&
                <div className="date" ref={datepickerRef}></div>
            }
        </>
    );
}

export default DatePickerInline;
