import React, { Fragment, useEffect, useContext } from "react";
import ProductsContext from "../_helper/Products";

import Dropdown from 'react-bootstrap/Dropdown';
import parse from 'html-react-parser';

import "../assets/css/vehicle-list.css";
import "../assets/css/custom.css";
import "../assets/css/style.css";

import Footer from "../Layout/Footer/Footer";
import Header2 from "../Layout/Header/Header2";
import VehicleCard from "../Components/Vehicle/VehicleCard";
import Seo from '../Components/seo';

import image6 from '../assets/img/vehicles/image 6.png';
import rangerover from '../assets/img/vehicles/range-rover.png';
import sort from '../assets/img/sort.png';
import Filter from '../assets/img/Filter.png';
import { useState } from "react";
import Loading from './../Components/Common/Loading';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Favorites = () => {

    const [vehicles, setVehicles] = useState(null);
    const [loading, setLoading] = useState(true);

    const {
        favoriteProducts,
        getFavoriteProducts,
    } = useContext(ProductsContext);


    const getFavorites = async () => {
        setVehicles(null);
        setLoading(true);
        await getFavoriteProducts();
        setLoading(false);
    }

    useEffect(() => {
        getFavorites();
    }, [])

    useEffect(() => {
        if (null != favoriteProducts) {
            setVehicles([...favoriteProducts]);
        }
    }, [favoriteProducts])
    const { t } = useTranslation();
    return (
        <Fragment>
            <Seo title={'Favorites'} schema={{}} />
            <Header2 />
            <main>
                <section className="vehicle-list">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col">
                                <h1>{t("FAVORITES.title")}</h1>
                            </div>
                        </div>
                        <div className="row vehicles-wrapper">
                            {loading
                                ? <div className="d-flex align-items-center justify-content-center my-5">
                                    <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                                </div>
                                : <>
                                    {vehicles?.length > 0
                                        ? <>
                                            {vehicles.map((item, key) => {
                                                return (
                                                    <div className="col-lg-3 col-md-4" key={key}>
                                                        <VehicleCard item={item} />
                                                    </div>
                                                )
                                            })}
                                        </>
                                        : <div className="d-flex flex-column align-items-center justify-content-center my-5" style={{ gap: '20px' }}>
                                            <h2 className="text-center">{t("FAVORITES.your_favorites_list_is_empty")}</h2>
                                            <Link to="/vehicle-list" className="ab-dark-button">{t("FAVORITES.explore_vehicles")}</Link>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>
    );
}

export default Favorites;
