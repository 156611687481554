import React, { useState, useContext, useCallback } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DatePickerInline from "../VehicleForm/datepicker-inline";
import TimepickerInline from "../VehicleForm/timepicker-inline";
import dateTimePickerPng from "../../assets/img/lucide_calendar-clock.png";
import './booked-date.css';
import ProductsContext from "../../_helper/Products";
import { useEffect } from "react";
import DatePicker from "../VehicleForm/datepicker";
import SelectMenu from "../VehicleForm/selectmenu";
import { useTranslation } from "react-i18next";

const CustomBookedDate = ({ existingDates, handleOnChange }) => {
    const [dates, setDates] = useState(existingDates ?? []);

    useEffect(() => {
        setDates(existingDates)
    }, [existingDates])

    useEffect(() => {
        if ('function' == typeof handleOnChange) {
            handleOnChange(dates);
        }
    }, [dates]);

    const setDateValue = (index, newItem) => {
        let newDates = dates.map((item, i) => {
            if (i == index) {
                return newItem;
            }
            return item;
        });
        setDates(newDates);
    }

    const handleButtonClick = () => {
        // Create a new date item
        const newDate = {
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
        };

        // Add the new date to the array
        setDates([...dates, newDate]);
    };
    const handleRemoveButtonClick = (indexToRemove) => {
        // Remove the component at the specified index

        const updatedDates = dates.filter((_, index) => index !== indexToRemove);
        setDates(updatedDates);
    };
    const { t } = useTranslation();
    return (
        <>
            <section className="busyDaysWrapper">
                {dates.map((date, index) => (
                    <div key={index} className="d-flex mb-2">
                        <ChooseDate id={index} data={date} handelOnChange={setDateValue} handleOnDelete={handleRemoveButtonClick} />
                    </div>
                ))}
            </section>
        </>
    )
}
export default CustomBookedDate

const ChooseDate = ({ id, data, handelOnChange, handleOnDelete }) => {
    const { t } = useTranslation();
    const {
        defaultTimeOptions,
    } = useContext(ProductsContext);

    const [startDate, setStartDate] = useState(data?.startDate ?? '');
    const [startTime, setStartTime] = useState(data?.startTime ?? '');
    const [endDate, setEndDate] = useState(data?.endDate ?? '');
    const [endTime, setEndTime] = useState(data?.endTime ?? '');
    const [errors, setErrors] = useState({
        startDate: false,
        startTime: false,
        endDate: false,
        endTime: false,
    });
    const [opened, setOpened] = useState(false);
    const handleEditClick = () => {
        setOpened(!opened); // Toggle the opened state
    };
    // const handleStartDateChange = (value) => {
    //     setStartDate(value);
    //     handelOnChange(id, {
    //         startDate: value,
    //         startTime,
    //         endDate,
    //         endTime,
    //     })
    // };

    // const handleStartTimeChange = (value) => {
    //     setStartTime(value);
    //     handelOnChange(id, {
    //         startDate,
    //         startTime: value,
    //         endDate,
    //         endTime,
    //     })
    // };
    // const handleEndDateChange = (value) => {
    //     setEndDate(value);
    //     handelOnChange(id, {
    //         startDate,
    //         startTime,
    //         endDate: value,
    //         endTime,
    //     })
    // };

    // const handleEndTimeChange = (value) => {
    //     setEndTime(value);
    //     handelOnChange(id, {
    //         startDate,
    //         startTime,
    //         endDate,
    //         endTime: value,
    //     })
    // };
    const handleOnUpdate = () => {
        if (isFormValid()) {
            handelOnChange(id, {
                startDate,
                startTime,
                endDate,
                endTime,
            })
            setOpened(false);
        }
    }

    const isFormValid = () => {
        let newErrors = { ...errors };
        let isValid = true;
        if (!startDate) {
            newErrors.startDate = true;
            isValid = false;
        } else {
            newErrors.startDate = false;
        }
        if (!startTime) {
            newErrors.startTime = true;
            isValid = false;
        } else {
            newErrors.startTime = false;
        }
        if (!endDate) {
            newErrors.endDate = true;
            isValid = false;
        } else {
            newErrors.endDate = false;
        }
        if (!endTime) {
            newErrors.endTime = true;
            isValid = false;
        } else {
            newErrors.endTime = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const deleteBtn = () => {
        handleOnDelete(id)
    }
    return (
        <>
            <div className="date-wrapper d-flex flex-grow-1" style={{ borderColor: opened ? '#00C48C' : '#D8D8D8' }}>
                {/* <div className="d-flex-flex-column w-100">
                    <div className="busy-day-header d-flex justify-content-between align-items-center">
                        <p>{data?.startDate && data?.endDate && <>{data?.startDate} {data?.startTime} - {data?.endDate} {data?.endTime}</>}</p>
                        <div className="actions-wrapper">
                            <button onClick={handleEditClick}>Edit</button>
                            <button onClick={deleteBtn}>Delete</button>
                        </div> */}
                <div className="d-flex flex-column w-100">
                    <div className="busy-day-header d-flex justify-content-between align-items-center">
                        <p>{data?.startDate && data?.endDate && <>{data?.startDate} {data?.startTime} - {data?.endDate} {data?.endTime}</>}</p>
                        <div className="actions-wrapper">
                            <button onClick={handleEditClick}>{t("BOOKED_DATE.edit")}</button>
                            <button onClick={deleteBtn}>{t("BOOKED_DATE.delete")}</button>
                        </div>
                    </div>
                    {!!opened && <div>
                        <div className="d-flex busy-days-body">
                            <label>
                                <span className="label">{t("BOOKED_DATE.start_date")}</span>
                                <DatePicker
                                    handleOnChange={(value) => { setStartDate(value) }}
                                    selected={startDate}
                                    placeholder={t("BOOKED_DATE.start_date")}
                                    max={endDate}
                                    className={errors?.startDate ? 'invalid' : ''}
                                />
                            </label>

                            <label className="time" htmlFor={`time-picker-${id}`}>
                                <span className="label">{t("BOOKED_DATE.start_time")}</span>
                                <SelectMenu
                                    id={`time-picker-${id}`}
                                    name={`time-picker-${id}`}
                                    className={errors?.startTime ? 'invalid' : ''}
                                    options={defaultTimeOptions}
                                    handleOnChange={(value) => { setStartTime(value) }}
                                    selected={startTime}
                                    selectValue={t("BOOKED_DATE.start_time")}
                                />
                            </label>
                            <label>
                                <span className="label">{t("BOOKED_DATE.end_date")}</span>
                                <DatePicker
                                    handleOnChange={(value) => { setEndDate(value) }}
                                    selected={endDate}
                                    placeholder={t("BOOKED_DATE.end_date")}
                                    min={startDate}
                                    className={errors?.endDate ? 'invalid' : ''}
                                />
                            </label>
                            <label className="time" htmlFor={`time-picker-${id}`}>
                                <span className="label">{t("BOOKED_DATE.end_time")}</span>
                                <SelectMenu
                                    id={`time-picker-${id}`}
                                    name={`time-picker-${id}`}
                                    className={errors?.endTime ? 'invalid' : ''}
                                    options={defaultTimeOptions}
                                    handleOnChange={(value) => { setEndTime(value) }}
                                    selected={endTime}
                                    selectValue={t("BOOKED_DATE.end_time")}
                                />
                            </label>
                        </div>
                        <button className="update-btn" onClick={handleOnUpdate}>{t("BOOKED_DATE.update")}</button>
                    </div>
                    }
                </div>
            </div >
        </>
    )
}