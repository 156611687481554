

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { Container, Input } from "reactstrap";
import Seo from '../Components/seo';
import Header from '../Layout/Header/Header';
import { toast } from "react-toastify";
import UserContext from './../_helper/User/index';
import { useNavigate, useParams } from "react-router-dom";
import Footer from '../Layout/Footer/Footer';
import Loading from './../Components/Common/Loading';
import { useTranslation } from "react-i18next";

const Verification = () => {
    const { t } = useTranslation();
    const history = useNavigate();

    const { verified, sendVerificationEmail } = useContext(UserContext);
    const [resendLoading, setResendLoading] = useState(false);

    useEffect(() => {
        if (!!verified) {
            history(`${process.env.PUBLIC_URL}/profile`);
        }
    }, [verified])

    const resend = async () => {
        setResendLoading(true);
        let res = await sendVerificationEmail();
        setResendLoading(false);
        history(`${process.env.PUBLIC_URL}/verify`);
        toast.success(t("VERIFICATION.code_send"));
    }

    return (
        <Fragment>
            <Seo title={'Verification'} schema={{}} />
            <div className="page-wrapper">
                <Header />
                <div className="error-wrapper py-5 flex-grow-1 d-flex  align-items-center">
                    <Container>
                        <div className='row justify-content-center'>
                            <div className='col-md-8'>
                                <div className='d-flex justify-content-center align-items-center flex-column' style={{ gap: '24px' }}>
                                    <h3>{t("VERIFICATION.please_verify_your_account")}</h3>
                                    <h5>{t("VERIFICATION.will_send_verification_code")}</h5>
                                    {resendLoading == true
                                        ? <div className="d-inline-block">
                                            <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                        </div>
                                        : <button className="secondary-button" onClick={resend}>{t("VERIFICATION.send_code")}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <Footer />
            </div>
        </Fragment>
    );
};

export default Verification;