import React from "react";
import Slider from "react-slick";
import parse from 'html-react-parser';
import slider1Img from "../../assets/img/home/slider1.png";
import slider2Img from "../../assets/img/home/slider2.png";
import slider3Img from "../../assets/img/home/slider3.png";
import slider4Img from "../../assets/img/home/slider4.png";
import { useTranslation } from "react-i18next";

const HomeSlider = ({ classes }) => {
    const sliderSettings = {
        dots: true,
        arrows: false,
        slidesToShow: 1,
        infinite: true,
        // centerMode: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 6000,
        lazyLoad: true,
    };
    const { t } = useTranslation();
    return (
        <>
            <section className={`homepage-slider-section ${classes}`}>
                <Slider
                    {...sliderSettings}
                    className="owl-full-height"
                >
                    <div className="h-100">
                        <div className="slider-item d-flex flex-column justify-content-center p-s-container"
                            style={{ backgroundImage: `url(${slider1Img})` }}>
                            <h2>{parse(t('HOME_SLIDER.0.title'))}</h2>
                            <p>{parse(t('HOME_SLIDER.0.description'))}</p>
                        </div>
                    </div>
                    <div className="h-100">
                        <div className="slider-item d-flex flex-column justify-content-center p-s-container"
                            style={{ backgroundImage: `url(${slider2Img})` }}>
                            <h2>{parse(t('HOME_SLIDER.1.title'))}</h2>
                            <p>{parse(t('HOME_SLIDER.1.description'))}</p>
                        </div>
                    </div>
                    <div className="h-100">
                        <div className="slider-item d-flex flex-column justify-content-center p-s-container"
                            style={{ backgroundImage: `url(${slider3Img})` }}>
                            <h2>{parse(t('HOME_SLIDER.2.title'))}</h2>
                            <p>{parse(t('HOME_SLIDER.2.description'))}</p>
                        </div>
                    </div>
                    <div className="h-100">
                        <div className="slider-item d-flex flex-column justify-content-center p-s-container"
                            style={{ backgroundImage: `url(${slider4Img})` }}>
                             <h2>{parse(t('HOME_SLIDER.3.title'))}</h2>
                            <p>{parse(t('HOME_SLIDER.3.description'))}</p>
                        </div>
                    </div>
                </Slider>
            </section>
        </>
    )
}

export default HomeSlider
