import React, { Fragment, useContext, useState } from "react";
import Footer from "../Layout/Footer/Footer";
import Header2 from "../Layout/Header/Header2";
import Dropdown from 'react-bootstrap/Dropdown';
import parse from 'html-react-parser';
import "../assets/css/vehicle-list.css";
import "../assets/css/custom.css";
import "../assets/css/style.css";
import Seo from './../Components/seo';

import image6 from '../assets/img/vehicles/image 6.png';
import rangerover from '../assets/img/vehicles/range-rover.png';
import arrowLeft from '../assets/img/Arrowleft.png';
import arrowRight from '../assets/img/Arrowright.png';

import sort from '../assets/img/sort.png';
import Filter from '../assets/img/Filter.png';
import VehicleCard from "../Components/Vehicle/VehicleCard";
import VehicleListSidebar from "../Components/VehicleList/FilterSidebar";
import { useEffect } from "react";
import ProductsContext from "../_helper/Products";
import Loading from './../Components/Common/Loading';

import { useTranslation } from "react-i18next";

const VehicleList = () => {
    const [showMoreFilters, setShowMoreFilters] = React.useState(false);


    const [vehicles, setVehicles] = useState([]);

    const {
        setProductFilter,
        productListFilters,
        productsList,
        getFilteredProducts,
    } = useContext(ProductsContext);

    useEffect(() => {
        getFilteredProducts();
    }, [])

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (null == productsList) {
            setLoading(true);
        } else {
            setVehicles(productsList);
            setLoading(false);
        }
    }, [productsList])

    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        let pages = [];
        for (let index = 1; index <= productListFilters.pages; index++) {
            pages.push(index);
        }
        let paginationNew = [];

        let currentPage = productListFilters.page;
        if (pages?.length > 1) {
            if (currentPage > 1) {
                paginationNew.push(
                    <a onClick={(e) => { setProductFilter({ 'page': currentPage - 1 }) }} className="page-numbers prev cursor-pointer" key={'link-prev'}><img src={arrowLeft} alt="" /></a>
                );
            }
            pages.map((p) => {
                if (p === currentPage) {
                    paginationNew.push(<b className="page-numbers current" style={{ cursor: 'auto' }} title={`Current Page`} key={`blog-page-${p}`}>{p}</b>)
                } else {
                    if (p == 1 || p == pages.length) {
                        if (currentPage < pages.length - 2 && p == pages.length) {
                            paginationNew.push(<b className="page-numbers dots disabled" title={`...`} key={`blog-page-${p}-next`}>...</b>)
                        }
                        paginationNew.push(<a className="page-numbers cursor-pointer" onClick={(e) => { setProductFilter({ 'page': p }) }} title={p} key={`blog-page-${p}`}>{p}</a>)
                        if (currentPage > 3 && p == 1) {
                            paginationNew.push(<b className="page-numbers dots disabled" title={`...`} key={`blog-page-${p}-prev`}>...</b>)
                        }
                    } else {
                        if (p >= currentPage - 1 && p <= currentPage + 1) {
                            paginationNew.push(<a className="page-numbers  cursor-pointer" onClick={(e) => { setProductFilter({ 'page': p }) }} title={p} key={`blog-page-${p}`}>{p}</a>)
                        }
                    }
                }
            })
            if (currentPage < pages.length) {
                paginationNew.push(
                    <a onClick={(e) => { setProductFilter({ 'page': currentPage + 1 }) }} className="page-numbers next cursor-pointer" key={'link-next'}><img src={arrowRight} alt="" /></a>
                );
            }

        }
        setPagination(paginationNew);

    }, [productListFilters.pages, productListFilters.page]);

    useEffect(() => {
        getFilteredProducts();
    }, [productListFilters.page, productListFilters.per_page]);


    const [showingPostsCount, setShowingPostsCount] = useState(0);

    useEffect(() => {
        let showing = productListFilters.found_posts % productListFilters.per_page;
        if (showing > 0) {

        } else {
            showing = productListFilters.per_page;
        }
        if (showing == 1) {
            setShowingPostsCount(0);
        } else {
            setShowingPostsCount(showing);
        }
    }, [productListFilters.found_posts, productListFilters.per_page])

    const setShowBy = (e, value) => {
        console.log(value);
        e.preventDefault();
        setProductFilter({ 'per_page': value });
    }

    const per_page = [1, 24, 36, 48];
    // const per_page = [];
    const { t } = useTranslation();
    return (
        <Fragment>
            <Seo title={'Vehicles list'} schema={{}} />
            <Header2 />
            <main className="p-0">
                <section className="vehicle-list">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col">
                                <h1>{t("VEHICLE_LIST.title")}</h1>
                            </div>
                        </div>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-lg-6">
                                {vehicles?.length > 0 && <div className="d-flex show-filter-wrapper">
                                    {vehicles?.lengthw}
                                    <p className="items-shown">
                                        <span>{((productListFilters.page - 1) * productListFilters.per_page) + 1}{!!showingPostsCount ? `-${showingPostsCount}` : ''} </span>
                                        <span>{t("VEHICLE_LIST.of")} </span>
                                        <span>{productListFilters.found_posts} </span>
                                        <span>{t("VEHICLE_LIST.cars")}</span>
                                    </p>
                                    {per_page?.length > 0 && (<div className="show-filter">
                                        <p className="showby">{t("VEHICLE_LIST.show_by")}</p>
                                        {
                                            per_page?.map(item => {
                                                return <a key={item} className={`cursor-pointer ${item == productListFilters?.per_page ? 'active' : ''}`} onClick={(e) => setShowBy(e, item)}>{item}</a>
                                            })
                                        }
                                    </div>)}
                                </div>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2 mt-lg-0">
                                <div className="filters d-flex justify-content-start justify-content-lg-end flex-column flex-sm-row">
                                    {vehicles?.length > 0 && <Dropdown className="sort-filter-dropdown">
                                        <Dropdown.Toggle className="sort-filter" variant="">
                                            <img src={sort} alt="" />{t("VEHICLE_LIST.sort_by")} · {t("VEHICLE_LIST.price_low_to_high")}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); }}>{t("VEHICLE_LIST.relevance")}</a></li>
                                            <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); }}>{t("VEHICLE_LIST.distance_away")}</a></li>
                                            <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); }}>{t("VEHICLE_LIST.price_low_to_high")}</a></li>
                                            <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); }}>{t("VEHICLE_LIST.price_high_to_low")}</a></li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    }
                                    <button className="more-filters d-flex justify-content-between" type="button" onClick={() => { setShowMoreFilters(!showMoreFilters) }}>
                                        <div className="d-flex align-items-center" style={{gap: '14px'}}>
                                            <img src={Filter} alt="" />{t("VEHICLE_LIST.more_filters")}
                                            <div className="filters-count d-none"></div>
                                        </div>
                                    </button>
                                    <div className={`offcanvas offcanvas-end ${showMoreFilters ? 'show' : ''}`} id="more-filters">
                                        <div className="more-filters-wrap">
                                            <div className="filters-header offcanvas-header">
                                                <button type="button" className="btn-close text-reset" onClick={() => { setShowMoreFilters(false) }}>
                                                </button>
                                            </div>
                                            <div className="filters-body offcanvas-body">
                                                <VehicleListSidebar />
                                            </div>
                                        </div>
                                    </div>
                                    {showMoreFilters ? <div className="offcanvas-backdrop fade show" onClick={() => { setShowMoreFilters(false) }}></div> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row vehicles-wrapper">
                            {loading
                                ? <div className="d-flex align-items-center justify-content-center my-5">
                                    <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                                </div>
                                : <>
                                    {vehicles?.length > 0
                                        ? <>
                                            {vehicles.map((item, key) => {
                                                return (
                                                    <div className="col-12 col-sm-6 col-lg-3 col-md-4" key={key}>
                                                        <VehicleCard item={item} />
                                                    </div>
                                                )
                                            })}
                                        </>
                                        : <>
                                            <div className="col-lg-12">
                                                <h3 className="text-center">{t("VEHICLE_LIST.no_vehicles_matching_your_filters")}</h3>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        {!loading && pagination?.length > 0 &&
                            <div className="row pagination-wrapper">
                                <div className="pagination justify-content-center">
                                    <div className="nav-links">
                                        {pagination.map((p) => {
                                            return p;
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment >
    );
}

export default VehicleList;
