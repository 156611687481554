

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { Container, Input } from "reactstrap";
import Seo from '../Components/seo';
import Header from '../Layout/Header/Header';
import { toast } from "react-toastify";
import UserContext from './../_helper/User/index';
import { useNavigate, useParams } from "react-router-dom";
import Footer from '../Layout/Footer/Footer';
import Loading from './../Components/Common/Loading';
import { useTranslation } from "react-i18next";

const Verify = () => {
    const { t } = useTranslation();
    const history = useNavigate();

    const { verified, checkVerificationCode, sendVerificationEmail } = useContext(UserContext);
    const { code } = useParams();
    const [codeValue, setCodeValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);

    useEffect(() => {
        if (!!verified) {
            toast.success(t("VERIFICATION.successfully_verified"));
            history(`${process.env.PUBLIC_URL}/profile`);
        }
    }, [verified])

    useEffect(() => {
        if (!verified && !!code) {
            setCodeValue(code);
            checkVerification();
        }
    }, [verified, code])

    const checkVerification = async () => {
        if (!!codeValue) {
            setLoading(true);
            console.log('code', codeValue);
            let res = await checkVerificationCode(codeValue, t("VERIFICATION.cant_verify") );
            setLoading(false);
            if (true == res) {
            } else {
                toast.error(t("VERIFICATION.went_wrong"));
            }
        }
    }

    const resend = async () => {
        setResendLoading(true);
        let res = await sendVerificationEmail();
        setResendLoading(false);
        toast.success(t("VERIFICATION.resended"));
    }

    return (
        <Fragment>
            <Seo title={'Verify'} schema={{}} />
            <div className="page-wrapper">
                <Header />
                <div className="error-wrapper py-5 flex-grow-1 d-flex  align-items-center">
                    <Container>
                        <div className='row justify-content-center'>
                            <div className='col-md-8 d-flex justify-content-center align-items-center flex-column' style={{ gap: '24px' }}>
                                {!!code
                                    ? <>
                                        <h3>{t("VERIFICATION.verification_code")}</h3>
                                        <input type='text' className='primary-input text-center m-0' value={codeValue} onChange={(e) => { console.log(e.target.value); setCodeValue(e.target.value) }} />

                                        {loading == true
                                            ? <div className="d-inline-block">
                                                <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                            </div>
                                            : <button type='button' className='btn btn-warning primary-button py-2' onClick={checkVerification}>{t("VERIFICATION.verify")}</button>
                                        }
                                    </>
                                    : <>
                                        <h3>{t("VERIFICATION.was_sent")}</h3>
                                        <h5>{t("VERIFICATION.please_check_it")}</h5>
                                        <input type='text' className='primary-input text-center m-0' value={codeValue} onChange={(e) => { setCodeValue(e.target.value) }} />
                                        {loading == true
                                            ? <div className="d-inline-block">
                                                <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                            </div>
                                            : <button type='button' className='btn btn-warning primary-button py-2' onClick={checkVerification}>{t("VERIFICATION.verify")}</button>
                                        }
                                        <div className=' mt-4'>
                                            {resendLoading == true
                                                ? <div className="d-inline-block">
                                                    <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                                </div>
                                                : <button className="secondary-button" onClick={resend}>{t("VERIFICATION.resend_code")}</button>
                                            }
                                        </div>

                                    </>
                                }
                            </div>
                        </div>
                    </Container>
                </div>
                <Footer />
            </div>
        </Fragment>
    );
};

export default Verify;