import { Navigate } from "react-router";
import About from "../Pages/About";
import Account from "../Pages/Account";
import Contact from "../Pages/Contact";
import FAQ from "../Pages/FAQ";
import Favorites from "../Pages/Favorites";
import HelpCenter from "../Pages/HelpCenter";
import Home from "../Pages/Home";
import HomeMap from "../Pages/HomeMap";
import MyVehicles from "../Pages/MyVehicles";
import MyVehiclesAdd from "../Pages/MyVehiclesAdd";
import MyVehiclesEdit from "../Pages/MyVehiclesEdit";
import News from "../Pages/News";
import PaymentResult from "../Pages/PaymentResult";
import Privacy from "../Pages/Privacy";
import Bookings from "../Pages/Profile/Bookings";
import LoginSecurity from "../Pages/Profile/LoginSecurity";
import Payments from "../Pages/Profile/Payments";
import PersonalInfo from "../Pages/Profile/PersonalInfo";
import Trips from "../Pages/Profile/Trips";
import Terms from "../Pages/Terms";
import VehicleList from "../Pages/VehicleList";
import VehicleSingle from "../Pages/VehicleSingle";
import Verify from "../Pages/Verify";
import OwnerRoute from "./OwnerRoute";
import Verification from "../Pages/Verification";
import VerifiedRoute from "./VerifiedRoute";
import Finances from "../Pages/Profile/Finances/Finances";
import DriverLicense from "../Pages/Profile/DriverLicense";
import Notifications from "../Pages/Profile/Notifications";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/`, Component: <Home /> },
  { path: `${process.env.PUBLIC_URL}/home`, Component: <Home /> },
  { path: `${process.env.PUBLIC_URL}/map`, Component: <HomeMap /> },
  { path: `${process.env.PUBLIC_URL}/contact`, Component: <Contact /> },
  { path: `${process.env.PUBLIC_URL}/terms-of-service`, Component: <Terms /> },
  { path: `${process.env.PUBLIC_URL}/privacy-policy`, Component: <Privacy /> },
  { path: `${process.env.PUBLIC_URL}/faq`, Component: <FAQ /> },
  { path: `${process.env.PUBLIC_URL}/help-center`, Component: <HelpCenter /> },
  { path: `${process.env.PUBLIC_URL}/about`, Component: <About /> },
  { path: `${process.env.PUBLIC_URL}/news`, Component: <News /> },
  { path: `${process.env.PUBLIC_URL}/vehicle-list`, Component: <VehicleList /> },
  { path: `${process.env.PUBLIC_URL}/vehicle/:id`, Component: <VehicleSingle /> },

];

export const privateRoutes = [
  { path: `${process.env.PUBLIC_URL}/account/`, Component: <Account /> },
  { path: `${process.env.PUBLIC_URL}/favorites/`, Component: <Favorites /> },

  { path: `${process.env.PUBLIC_URL}/my-vehicles`, Component: <OwnerRoute><VerifiedRoute><MyVehicles /></VerifiedRoute></OwnerRoute> },
  { path: `${process.env.PUBLIC_URL}/add-vehicle`, Component: <OwnerRoute><VerifiedRoute><MyVehiclesAdd /></VerifiedRoute></OwnerRoute> },
  { path: `${process.env.PUBLIC_URL}/edit-vehicle/:id`, Component: <OwnerRoute><VerifiedRoute><MyVehiclesEdit /></VerifiedRoute></OwnerRoute> },
  { path: `${process.env.PUBLIC_URL}/order/:id`, Component: <PaymentResult /> },
  { path: `${process.env.PUBLIC_URL}/verify/:code`, Component: <Verify /> },
  { path: `${process.env.PUBLIC_URL}/verify/`, Component: <Verify /> },
  { path: `${process.env.PUBLIC_URL}/verification/`, Component: <Verification /> },

];

export const profileRoutes = [
  { path: `${process.env.PUBLIC_URL}/profile/`, Component: <Navigate to={`${process.env.PUBLIC_URL}/personal-info`} /> },
  { path: `${process.env.PUBLIC_URL}/personal-info`, Component: <PersonalInfo /> },
  { path: `${process.env.PUBLIC_URL}/login-and-security`, Component: <LoginSecurity /> },
  { path: `${process.env.PUBLIC_URL}/driver-license`, Component: <DriverLicense /> },
  { path: `${process.env.PUBLIC_URL}/payments`, Component: <Payments /> },
  { path: `${process.env.PUBLIC_URL}/finances`, Component: <Finances /> },
  { path: `${process.env.PUBLIC_URL}/trips`, Component: <Trips /> },
  { path: `${process.env.PUBLIC_URL}/notifications`, Component: <Notifications /> },
  { path: `${process.env.PUBLIC_URL}/bookings`, Component: <OwnerRoute><Bookings /></OwnerRoute> },
];