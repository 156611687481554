import React, { Fragment, useContext, useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { toast } from "react-toastify";

import SelectMenu from "../Components/VehicleForm/selectmenu";
import Footer from "../Layout/Footer/Footer";
import Header from "../Layout/Header/Header";

import "../assets/css/list-your-car.css";
import "./../assets/css/custom.css";
import "./../assets/css/style.css";

import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Components/Common/Loading";
import CustomBookedDate from "../Components/CustomBookedDates/CustomBookedDate";
import Seo from "../Components/seo";
import AttributesContext from "../_helper/Attributes";
import MapsJsApiContext from "../_helper/MapsJsApi";
import ProductsContext from "../_helper/Products";
import AddBookedDate from "../Components/CustomBookedDates/AddBookedDate";
import AddDate from "../Components/CustomBookedDates/AddBookedDate";
import { useTranslation } from "react-i18next";

const MyVehiclesEdit = () => {
  const { t } = useTranslation();

  const history = useNavigate();

  const {
    getAllAttributes,
    pa_years,
    pa_categories,
    pa_makes,
    pa_model,
    pa_odometer,
    pa_transmission,
    pa_trim,
    pa_style,
    pa_license_country,
    pa_plate_numer_states,
    pa_features,
  } = useContext(AttributesContext);

  const { id } = useParams();

  useEffect(() => {
    setNewAddedProductId(null);
    getProductForEdit(id);
  }, [id]);

  const {
    productForEdit,
    getProductForEdit,
    updateProduct,
    setNewAddedProductId,
  } = useContext(ProductsContext);

  const {
    isLoaded,
  } = useContext(MapsJsApiContext);


  const [productForEditLoaded, setProductForEditLoaded] = useState(false);

  useEffect(() => {
    if (null != productForEdit) {

      if (false != productForEdit) {
        //section main
        setAddress(productForEdit?.address);
        setPlace(productForEdit?.place);
        setLat(productForEdit?.lat);
        setLng(productForEdit?.lng);
        setVin(productForEdit?.vin);
        setDailyPrice(productForEdit?.daily_price);
        setHourlyPrice(productForEdit?.hourly_price);
        setIsPersonal(!!productForEdit?.isPersonal);
        setYear(productForEdit?.pa_years);
        setModel(productForEdit?.pa_model);
        setMake(productForEdit?.pa_makes);
        setOdometer(productForEdit?.pa_odometer);
        setTransmission(productForEdit?.pa_transmission);
        setTrim(productForEdit?.pa_trim);
        setStyle(productForEdit?.pa_style);
        setHasDriver(productForEdit?.hasDriver);
        setDriverRequired(productForEdit?.driverRequired);

        setDriverDailyPrice(productForEdit?.driverDailyPrice);
        // setDriverHourlyPrice(productForEdit?.driverHourlyPrice);
        setDeliveryToRenter(productForEdit?.deliveryToRenter);
        //end section main

        // section phone
        // setCountryCode(productForEdit?.countryCode);
        // setPhone(productForEdit?.phone);
        //end section phone

        //section license
        // setLicenseCountry(productForEdit?.pa_license_country);
        // setLicenseNumber(productForEdit?.licenseNumber);
        // setLicenseFirstName(productForEdit?.licenseFirstName);
        // setLicenseMiddleName(productForEdit?.licenseMiddleName);
        // setLicenseLastName(productForEdit?.licenseLastName);

        // setLicenseDobDay(productForEdit?.licenseDobDay);
        // setLicenseDobMonth(productForEdit?.licenseDobMonth);
        // setLicenseDobYear(productForEdit?.licenseDobYear);
        //end section license

        //section details
        setLicensePlateNumber(productForEdit?.licensePlateNumber);
        setLicensePlateNumberState(productForEdit?.pa_plate_numer_states);
        setCarDescription(productForEdit?.description);
        setCarFeatures(productForEdit?.pa_features);
        //end section details

        //section photos
        setProductImages(productForEdit?.images);
        let mainIndex = productForEdit?.images.findIndex((item) => item.id == productForEdit?.featured_image?.id);
        setMainImageIndex({
          type: 'id',
          value: productForEdit?.featured_image?.id,
        });
        //end section photos

        // section blocked_dates
        setBlockedDates(productForEdit?.blockedDates ?? []);
        // end section blocked_dates
        setProductForEditLoaded(true);
      }
    }

  }, [productForEdit]);
  useEffect(() => {
    if (productForEditLoaded) {
      let newValidSections = [...validSections];
      if (isMainSectionValid()) {
        newValidSections.push('main');
      }
      // if (isPhoneSectionValid()) {
      //   newValidSections.push('phone');
      // }
      // if (isLicenseSectionValid()) {
      //   newValidSections.push('license');
      // }
      if (isDetailsSectionValid()) {
        newValidSections.push('details');
      }
      if (isPhotoSectionValid()) {
        newValidSections.push('photos');
      }
      setValidSections(newValidSections);
    }
  }, [productForEditLoaded]);

  const getAttributeValues = (attr) => {
    return attr?.map(y => {
      return { value: y.term_id, name: y.term_name }
    })
  }

  useEffect(() => {
    getAllAttributes();
  }, [])

  useEffect(() => {
    if (pa_transmission?.length > 0 && '' == transmission) {
      setTransmission(pa_transmission[0].term_id);
    }
  }, [pa_transmission])

  const [activeItem, setActiveItem] = React.useState('main');
  // const [activeItem, setActiveItem] = React.useState('details');

  const setActiveAccordionItem = (newItem) => {
    if (activeItem == newItem) {
      setActiveItem(null);
    } else {
      setActiveItem(newItem);
    }
  }

  const [invalid, setInvalid] = React.useState([]);
  const [validSections, setValidSections] = React.useState([]);
  const setFieldInvalid = (newInvalids, key, valid = false) => {
    if (!!valid) {
      newInvalids = newInvalids.filter(item => item != key);
    } else {
      newInvalids.push(key);
    }
    return newInvalids;
  }
  // section 1 - main
  const [address, setAddress] = React.useState('');
  const [place, setPlace] = React.useState(null);
  const [lat, setLat] = React.useState('');
  const [lng, setLng] = React.useState('');

  const setPlaceAndLatLng = (value) => {
    if (!!value) {
      setPlace(value);
      geocodeByPlaceId(value?.value?.place_id)
        .then(results => {
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          setLat(lat);
          setLng(lng);
        });
    } else {
      setPlace(null);
      setLat(lat);
      setLng(lng);
    }
  }


  const [vin, setVin] = React.useState('');
  const [dailyPrice, setDailyPrice] = React.useState('');
  const [hourlyPrice, setHourlyPrice] = React.useState('');
  const [hasDriver, setHasDriver] = React.useState(false);
  const [driverRequired, setDriverRequired] = React.useState(true);
  const [driverDailyPrice, setDriverDailyPrice] = React.useState('');
  // const [driverHourlyPrice, setDriverHourlyPrice] = React.useState('');
  const [year, setYear] = React.useState(null);
  const [make, setMake] = React.useState(null);
  const [modelOptions, setModelOptions] = React.useState([]);
  const [model, setModel] = React.useState(null);
  const [odometer, setOdometer] = React.useState(null);
  const [transmission, setTransmission] = React.useState('');
  const [trim, setTrim] = React.useState(null);
  const [style, setStyle] = React.useState(null);
  const [deliveryToRenter, setDeliveryToRenter] = React.useState(false);
  const [isPersonal, setIsPersonal] = React.useState(false);

  useEffect(() => {
    if (!!make) {
      let makeObj = pa_categories?.find(item => item.term_id == make);
      if (!!makeObj) {
        let models = makeObj?.models?.map(item => { return { ...item } });
        // console.log(make, makeObj, model, models);
        if (undefined == models?.find(item => item.term_id == model)) {
          setModel('');
        }
        if (models?.length > 0) {
          setModelOptions(models);
          return;
        }
      }
    }
    setModelOptions([]);
  }, [make, pa_categories]);

  const checkMainSectionValidation = () => {
    if (isMainSectionValid()) {
      let newValidSections = [...validSections];
      newValidSections.push('main');
      setValidSections(newValidSections);
      setActiveAccordionItem('details');
    } else {
      let newValidSections = [...validSections];
      newValidSections = newValidSections.filter(item => item != 'main');
      setValidSections(newValidSections);
    }
  };
  const isMainSectionValid = () => {
    let newInvalids = [...invalid];
    let valid = true;
    // if (!!address) {
    //   newInvalids = setFieldInvalid(newInvalids, 'address', true);
    // } else {
    //   newInvalids = setFieldInvalid(newInvalids, 'address', false);
    //   valid = false;
    // }

    if (!!place) {
      newInvalids = setFieldInvalid(newInvalids, 'place', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'place', false);
      valid = false;
    }

    if (!!vin) {
      newInvalids = setFieldInvalid(newInvalids, 'vin', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'vin', false);
      valid = false;
    }

    if (!!dailyPrice) {
      newInvalids = setFieldInvalid(newInvalids, 'dailyPrice', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'dailyPrice', false);
      valid = false;
    }

    if (!!hourlyPrice) {
      newInvalids = setFieldInvalid(newInvalids, 'hourlyPrice', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'hourlyPrice', false);
      valid = false;
    }

    if (!!hasDriver && !driverRequired) {
      if (!!driverDailyPrice) {
        newInvalids = setFieldInvalid(newInvalids, 'driverDailyPrice', true);
      } else {
        newInvalids = setFieldInvalid(newInvalids, 'driverDailyPrice', false);
        valid = false;
      }

      // if (!!driverHourlyPrice) {
      //   newInvalids = setFieldInvalid(newInvalids, 'driverHourlyPrice', true);
      // } else {
      //   newInvalids = setFieldInvalid(newInvalids, 'driverHourlyPrice', false);
      //   valid = false;
      // }
    }

    if (!!year) {
      newInvalids = setFieldInvalid(newInvalids, 'year', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'year', false);
      valid = false;
    }

    if (!!make) {
      newInvalids = setFieldInvalid(newInvalids, 'make', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'make', false);
      valid = false;
    }

    if (!!model) {
      newInvalids = setFieldInvalid(newInvalids, 'model', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'model', false);
      valid = false;
    }

    if (!!odometer) {
      newInvalids = setFieldInvalid(newInvalids, 'odometer', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'odometer', false);
      valid = false;
    }

    if (!!trim) {
      newInvalids = setFieldInvalid(newInvalids, 'trim', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'trim', false);
      valid = false;
    }

    if (!!style) {
      newInvalids = setFieldInvalid(newInvalids, 'style', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'style', false);
      valid = false;
    }
    setInvalid(newInvalids);
    return valid;
  }
  // end section 1 - main

  // section 2 - phone
  // const [countryCode, setCountryCode] = React.useState(null);
  // const [phone, setPhone] = React.useState('');
  // const checkPhoneSectionValidation = () => {
  //   if (isPhoneSectionValid()) {
  //     let newValidSections = [...validSections];
  //     newValidSections.push('phone');
  //     setValidSections(newValidSections);
  //     setActiveAccordionItem('license');
  //   } else {
  //     let newValidSections = [...validSections];
  //     newValidSections = newValidSections.filter(item => item != 'phone');
  //     setValidSections(newValidSections);
  //   }
  // }
  // const isPhoneSectionValid = () => {
  //   let newInvalids = [...invalid];
  //   let valid = true;
  //   if (!!countryCode) {
  //     newInvalids = setFieldInvalid(newInvalids, 'countryCode', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'countryCode', false);
  //     valid = false;
  //   }
  //   if (!!phone) {
  //     newInvalids = setFieldInvalid(newInvalids, 'phone', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'phone', false);
  //     valid = false;
  //   }
  //   setInvalid(newInvalids);
  //   return valid;
  // }
  // end section 2 - phone

  // section 3 - license
  // const [licenseCountry, setLicenseCountry] = React.useState(null);
  // const [licenseNumber, setLicenseNumber] = React.useState('');
  // const [licenseFirstName, setLicenseFirstName] = React.useState('');
  // const [licenseMiddleName, setLicenseMiddleName] = React.useState('');
  // const [licenseLastName, setLicenseLastName] = React.useState('');
  // const [licenseDobDay, setLicenseDobDay] = React.useState(null);
  // const [licenseDobMonth, setLicenseDobMonth] = React.useState(null);
  // const [licenseDobYear, setLicenseDobYear] = React.useState(null);

  // const days = [];
  // for (let d = 1; d <= 31; d++) {
  //   if (d < 10) {
  //     days.push({ value: '0' + d, name: '0' + d });
  //   } else {
  //     days.push({ value: d, name: d });
  //   }
  // }
  // const months = [];
  // for (let m = 1; m <= 12; m++) {
  //   if (m < 10) {
  //     months.push({ value: '0' + m, name: '0' + m });
  //   } else {
  //     months.push({ value: m, name: m });
  //   }
  // }
  // const years = [];
  // for (let y = 2005; y >= 1950; y--) {
  //   years.push({ value: y, name: y });
  // }

  // const checkLicenseSectionValidation = () => {
  //   if (isLicenseSectionValid()) {
  //     let newValidSections = [...validSections];
  //     newValidSections.push('license');
  //     setValidSections(newValidSections);
  //     setActiveAccordionItem('details');
  //   } else {
  //     let newValidSections = [...validSections];
  //     newValidSections = newValidSections.filter(item => item != 'license');
  //     setValidSections(newValidSections);
  //   }
  // }
  // const isLicenseSectionValid = () => {
  //   let newInvalids = [...invalid];
  //   let valid = true;
  //   if (!!licenseCountry) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseCountry', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseCountry', false);
  //     valid = false;
  //   }

  //   if (!!licenseNumber) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseNumber', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseNumber', false);
  //     valid = false;
  //   }
  //   if (!!licenseFirstName) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseFirstName', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseFirstName', false);
  //     valid = false;
  //   }
  //   if (!!licenseMiddleName) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseMiddleName', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseMiddleName', false);
  //     valid = false;
  //   }
  //   if (!!licenseLastName) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseLastName', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseLastName', false);
  //     valid = false;
  //   }
  //   if (!!licenseDobDay) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseDobDay', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseDobDay', false);
  //     valid = false;
  //   }
  //   if (!!licenseDobMonth) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseDobMonth', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseDobMonth', false);
  //     valid = false;
  //   }
  //   if (!!licenseDobYear) {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseDobYear', true);
  //   } else {
  //     newInvalids = setFieldInvalid(newInvalids, 'licenseDobYear', false);
  //     valid = false;
  //   }
  //   setInvalid(newInvalids);
  //   return valid;
  // }
  // end section 3 - license

  // section 4 - details
  const [licensePlateNumber, setLicensePlateNumber] = React.useState('');
  const [licensePlateNumberState, setLicensePlateNumberState] = React.useState(null);
  const [carDescription, setCarDescription] = React.useState('');
  const [carFeatures, setCarFeatures] = React.useState([]);

  const handleFeatureChange = (term_id) => {
    let newFeatures = [...carFeatures];
    if (newFeatures.includes(term_id)) {
      newFeatures = newFeatures.filter(item => item != term_id);
    } else {
      newFeatures.push(term_id);
    }
    setCarFeatures(newFeatures);
  }

  const checkDetailsSectionValidation = () => {
    if (isDetailsSectionValid()) {
      let newValidSections = [...validSections];
      newValidSections.push('details');
      setValidSections(newValidSections);
      setActiveAccordionItem('photos');
    } else {
      let newValidSections = [...validSections];
      newValidSections = newValidSections.filter(item => item != 'details');
      setValidSections(newValidSections);
    }
  }
  const isDetailsSectionValid = () => {
    let newInvalids = [...invalid];
    let valid = true;
    if (!!licensePlateNumber) {
      newInvalids = setFieldInvalid(newInvalids, 'licensePlateNumber', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'licensePlateNumber', false);
      valid = false;
    }

    if (!!licensePlateNumberState) {
      newInvalids = setFieldInvalid(newInvalids, 'licensePlateNumberState', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'licensePlateNumberState', false);
      valid = false;
    }

    if (!!carDescription) {
      newInvalids = setFieldInvalid(newInvalids, 'carDescription', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'carDescription', false);
      valid = false;
    }
    setInvalid(newInvalids);
    return valid;
  }
  // end section 4 - details

  // section 5 - photos
  const [productImages, setProductImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [canvasImages, setCanvasImages] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState({
    type: 'index',
    value: 0,
  });

  const selectFiles = async (event) => {
    let imagesCanvas = [...canvasImages];

    for (let i = 0; i < event.target.files.length; i++) {
      let url = URL.createObjectURL(event.target.files[i])
      let img = new Image();
      let type = event.target.files[i].type
      await new Promise((resolve, reject) => {
        img.onload = function () {
          let canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          let ctx = canvas.getContext('2d')

          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            0,
            0,
            img.width,
            img.height
          )
          let base64Image = canvas.toDataURL(type, 1);
          imagesCanvas.push(base64Image);
          resolve();
        }
        img.src = url;
      })
    }
    setCanvasImages(imagesCanvas);
  };

  const removeOldImage = (img_id) => {
    setRemovedImages([...removedImages, img_id]);
    console.log('removeOldImage', img_id);
    let newProductImages = productImages.filter((item, i) => item.id != img_id);
    setProductImages(newProductImages);
  }

  const removeImage = (key) => {
    let newCanvasImages = canvasImages.filter((item, i) => i != key);
    if (mainImageIndex.type == 'idnex' && key < mainImageIndex.value) {
      setMainImageIndex({
        type: 'index',
        value: mainImageIndex.value - 1,
      });
    }
    setCanvasImages(newCanvasImages);
  }

  const checkPhotosSectionValidation = () => {
    if (isPhotoSectionValid()) {
      let newValidSections = [...validSections];
      newValidSections.push('photos');
      setValidSections(newValidSections);
      setActiveAccordionItem('standarts')
    } else {
      let newValidSections = [...validSections];
      newValidSections = newValidSections.filter(item => item != 'photos');
      setValidSections(newValidSections);
    }
  }
  const isPhotoSectionValid = () => {
    let newInvalids = [...invalid];
    let valid = true;
    if (canvasImages?.length > 0 || productImages?.length > 0) {
      newInvalids = setFieldInvalid(newInvalids, 'canvasImages', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'canvasImages', false);
      valid = false;
    }
    setInvalid(newInvalids);
    return valid;
  }
  // end section 5 - photos

  // section 6 - standarts
  const [agreementChecked, setAgreementChecked] = React.useState(true);
  // end section 6 - standarts

  // section 7 - add custom blocked dates
  const [blockedDates, setBlockedDates] = React.useState([]);
  const addBlockedDate = (item) => {
    let newBlockedDates = blockedDates.map((d) => { return { ...d } })
    console.log(item, newBlockedDates);
    newBlockedDates.push(item);
    setBlockedDates(newBlockedDates);
  }
  // end section 7 - add custom blocked dates


  const [isFormInvalid, setIsFormInvalid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const checkIsFormInvalid = () => {
    let isInvalid = false;
    if (!(
      validSections.includes('main')
      // && validSections.includes('phone')
      // && validSections.includes('license')
      && validSections.includes('details')
      && validSections.includes('photos')
      // && agreementChecked
    )) {
      isInvalid = true;
    }
    setIsFormInvalid(isInvalid);
  }
  useEffect(() => {
    checkIsFormInvalid();
  }, [validSections, agreementChecked]);

  const handleOnSubmit = async () => {
    if (!(
      isMainSectionValid()
      && isDetailsSectionValid()
      // && isLicenseSectionValid()
      // && isPhoneSectionValid()
      && isPhotoSectionValid()
      // && agreementChecked
    )) {
      setIsFormInvalid(true);
      toast.error(t("VEHICLE_ADD.required_fields_empty"));
      return;
    }
    setLoading(true);
    let payload = {
      id,
      address,
      lat,
      lng,
      place,
      vin,
      dailyPrice,
      hourlyPrice,
      hasDriver,
      driverRequired,
      driverDailyPrice,
      // driverHourlyPrice,
      deliveryToRenter,
      pa_years: !!year ? [Number(year)] : [],
      categories: [Number(make), Number(model)],
      pa_odometer: !!odometer ? [Number(odometer)] : [],
      pa_transmission: !!transmission ? [Number(transmission)] : [],
      pa_trim: !!trim ? [Number(trim)] : [],
      pa_style: !!style ? [Number(style)] : [],
      // pa_license_country: !!licenseCountry ? [Number(licenseCountry)] : [],
      pa_plate_numer_states: !!licensePlateNumberState ? [Number(licensePlateNumberState)] : [],
      isPersonal,
      // countryCode,
      // phone,
      // licenseNumber,
      // licenseFirstName,
      // licenseMiddleName,
      // licenseLastName,
      // licenseDobDay,
      // licenseDobMonth,
      // licenseDobYear,
      licensePlateNumber,
      carDescription,
      pa_features: carFeatures,
      images: canvasImages,
      mainImageIndex,
      productImages: productImages?.map(item => item.id),
      removedImages,
      blockedDates,
    }
    let res = await updateProduct(payload);
    setLoading(false);
    if (true == res) {
      toast.success(t("VEHICLE_ADD.vehicle_updated"));
      history(`${process.env.PUBLIC_URL}/my-vehicles`);
    } else {
      toast.error(t("VEHICLE_ADD.something_went_wrong"));
    }
  }

  return (
    <Fragment>
      <Seo title={'Edit Vehicle'} schema={{}} />
      <Header />
      <main className="p=-">
        <section className="privacy-policy-page-header">
        </section>
        <section>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6">
                <h5>{t("VEHICLE_ADD.edit_your_car")}</h5>
              </div>
            </div>
          </div>
        </section>
        <section className="list-car-section">
          <div className="container">
            {
              !!productForEditLoaded
                ? <>
                  {productForEdit == false
                    ? <div className="row d-flex justify-content-center">
                      <div className="col-md-6">
                        <h2 className="text-center">{t("VEHICLE_ADD.went_wrong")}</h2>
                      </div>
                    </div>
                    : <div className="row d-flex justify-content-center">
                      <div className="col-md-6">
                        <Accordion className="accordion-flush" activeKey={activeItem}>
                          <Accordion.Item eventKey="main">
                            <Accordion.Header onClick={() => { setActiveAccordionItem('main') }}>
                              <div className="d-flex justify-content-between dropdown-but">
                                <b>{t("VEHICLE_ADD.list_your_car")}</b>
                                <a href="#" className="accordion-add-btn">{t("VEHICLE_ADD.add")}</a>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="your-car">
                              <label className="your-car-email">
                                <h6>{t("VEHICLE_ADD.where_is_your_car_located")}</h6>
                                {!!isLoaded &&
                                  <GooglePlacesAutocomplete
                                    apiOptions={{ language: 'en', region: 'AM' }}
                                    onLoadFailed={(error) => (
                                      console.error("Could not inject Google script", error)
                                    )}
                                    selectProps={{
                                      placeholder: t("VEHICLE_ADD.enter_address"),
                                      className: 'input-no-border your-car-email',
                                      value: place,
                                      onChange: setPlaceAndLatLng,
                                      styles: {
                                        container: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                        }),
                                        control: (provided) => ({
                                          ...provided,
                                          border: '1px solid #D8D8D8',
                                          boxShadow: 'unset',
                                          borderRadius: '5px',
                                          padding: '8px 15px',
                                          borderColor: invalid.includes('place') ? '#EC4E5F !important' : '#D8D8D8',
                                          accentColor: invalid.includes('place') ? '#EC4E5F !important' : '#D8D8D8',
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          padding: '0',
                                        }),
                                        input: (provided) => ({
                                          ...provided,
                                          border: 'unset !important',
                                          padding: '0',
                                          margin: '0',
                                        }),
                                        indicatorsContainer: (provided) => ({
                                          ...provided,
                                          display: 'none',
                                        }),
                                      }
                                    }}
                                  />
                                }
                                {/* <input value={address} onChange={(e) => { setAddress(e.target.value) }} className={`your-car-email ${invalid.includes('address') ? 'invalid' : ''}`} type="text" placeholder="Enter address" name="address" /> */}
                              </label>
                              {/* <label>
                                <h6>Which car do you have?</h6>
                                <input value={vin} onChange={(e) => { setVin(e.target.value) }} className={`your-vin ${invalid.includes('vin') ? 'invalid' : ''}`} type="text" placeholder="Enter your VIN" name="vin-code" />
                              </label>
                              <a className="about-vin" href="#">Learn more about VIN</a> */}
                              <div className="row name-sec">
                                <div className="col-md-6">
                                  <h6>{t("VEHICLE_ADD.daily_price")}</h6>
                                  <input value={dailyPrice} onChange={(e) => { setDailyPrice(e.target.value) }} type="number" className={`form-control ${invalid.includes('dailyPrice') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="first-name" />
                                </div>
                                <div className="col-md-6">
                                  <h6>{t("VEHICLE_ADD.hourly_price")}</h6>
                                  <input value={hourlyPrice} onChange={(e) => { setHourlyPrice(e.target.value) }} type="number" className={`form-control ${invalid.includes('hourlyPrice') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="middle-name" />
                                </div>
                              </div>
                              <div className="listing-personal user-select-none d-flex flex-column border-top border-bottom py-3">
                                <div className="d-flex flex-wrap" style={{ gap: '25px' }}>
                                  <div className="round">
                                    <input required js-valid="true" type="checkbox" readOnly checked={hasDriver} name="listing-personal" id="listing-personal" />
                                    <label htmlFor="listing-personal" onClick={() => { setHasDriver(!hasDriver) }}></label>
                                    <div className="ms-3 cursor-pointer" onClick={() => { setHasDriver(!hasDriver) }}>
                                      <h6>{t("VEHICLE_ADD.has_driver")}</h6>
                                    </div>
                                  </div>

                                  {hasDriver && <div className="round">
                                    <input required js-valid="true" type="checkbox" readOnly checked={driverRequired} name="listing-personal" id="listing-personal" />
                                    <label htmlFor="listing-personal" onClick={() => { setDriverRequired(!driverRequired) }}></label>
                                    <div className="ms-3 cursor-pointer" onClick={() => { setDriverRequired(!driverRequired) }}>
                                      <h6>{t("VEHICLE_ADD.driver_required")}</h6>
                                    </div>
                                  </div>}
                                </div>
                                {(hasDriver && !driverRequired) && <div className="row name-sec mt-3">
                                  <div className="col-md-6">
                                    <h6 className="mb-2">{t("VEHICLE_ADD.driver_daily_price")}</h6>
                                    <input value={driverDailyPrice} onChange={(e) => { setDriverDailyPrice(e.target.value) }} type="number" className={`form-control ${invalid.includes('driverDailyPrice') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="first-name" />
                                  </div>
                                  {/* <div className="col-md-6">
                                    <h6 className="mb-2">Driver Hourly price</h6>
                                    <input value={driverHourlyPrice} onChange={(e) => { setDriverHourlyPrice(e.target.value) }} type="number" className={`form-control ${invalid.includes('driverHourlyPrice') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="middle-name" />
                                  </div> */}
                                </div>}
                              </div>
                              <div className="row">
                                <div className="col-md-3">

                                  <h6>{t("VEHICLE_ADD.year")}</h6>
                                  <SelectMenu
                                    id="year"
                                    name="year"
                                    className={`car-year ${invalid.includes('year') ? 'invalid' : ''}`}
                                    options={getAttributeValues(pa_years)}
                                    handleOnChange={setYear}
                                    selected={year}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <h6>{t("VEHICLE_ADD.odometer")}</h6>
                                  <SelectMenu
                                    id="odometer"
                                    name="odometer"
                                    className={`car-odometer ${invalid.includes('odometer') ? 'invalid' : ''}`}
                                    options={getAttributeValues(pa_odometer)}
                                    handleOnChange={setOdometer}
                                    selected={odometer}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <h6>{t("VEHICLE_ADD.make")}</h6>
                                  <SelectMenu
                                    id="make"
                                    name="make"
                                    className={`car-make ${invalid.includes('make') ? 'invalid' : ''}`}
                                    options={getAttributeValues(pa_categories)}
                                    handleOnChange={setMake}
                                    selected={make}
                                  />
                                </div>
                                {modelOptions?.length > 0 && (
                                  <div className="col-md-3">
                                    <h6>{t("VEHICLE_ADD.model")}</h6>
                                    <SelectMenu
                                      id="model"
                                      name="model"
                                      className={`car-model ${invalid.includes('model') ? 'invalid' : ''}`}
                                      options={getAttributeValues(modelOptions)}
                                      handleOnChange={setModel}
                                      selected={model}
                                    />
                                  </div>
                                )}

                              </div>
                              <h6>{t("VEHICLE_ADD.transmission")}</h6>
                              <div className="transmission d-flex align-items-center" style={{ gap: '25px' }}>
                                {pa_transmission?.map((t) => {
                                  return <label className="d-flex align-items-center mx-0" style={{ gap: '14px' }} key={t.term_id}>
                                    <input required js-valid="true" type="radio" id="automatic" name="transmission" value={t.term_id} checked={transmission == t.term_id} onChange={(e) => { setTransmission(e.target.value) }} />
                                    {t.term_name}
                                  </label>
                                }
                                )}
                              </div>
                              <div className="row">
                                <div className="col-md-3">

                                  <h6>{t("VEHICLE_ADD.trim")}</h6>
                                  <SelectMenu
                                    id="trim"
                                    name="trim"
                                    className={`car-trim ${invalid.includes('trim') ? 'invalid' : ''}`}
                                    options={getAttributeValues(pa_trim)}
                                    handleOnChange={setTrim}
                                    selected={trim}
                                  />
                                </div>
                                <div className="col-md-3">

                                  <h6>{t("VEHICLE_ADD.style")}</h6>
                                  <SelectMenu
                                    id="style"
                                    name="style"
                                    className={`car-style ${invalid.includes('style') ? 'invalid' : ''}`}
                                    options={getAttributeValues(pa_style)}
                                    handleOnChange={setStyle}
                                    selected={style}
                                  />
                                </div>
                              </div>
                              <div className="listing-personal d-flex">
                                <div className="round">
                                  <input required js-valid="true" type="checkbox" readOnly checked={deliveryToRenter} name="listing-personal" id="listing-personal" />
                                  <label htmlFor="listing-personal" onClick={() => { setDeliveryToRenter(!deliveryToRenter) }}></label>
                                  <div className="ms-3 cursor-pointer" onClick={() => { setDeliveryToRenter(!deliveryToRenter) }}>
                                    <h6>Delivery to Renter's Selected Location</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="listing-personal d-flex">
                                <div className="round">
                                  <input required js-valid="true" type="checkbox" readOnly checked={isPersonal} name="listing-personal" id="listing-personal" />
                                  <label htmlFor="listing-personal" onClick={() => { setIsPersonal(!isPersonal) }}></label>
                                  <div className="ms-3">
                                    <h6>{t("VEHICLE_ADD.personal_vehicle")}</h6>
                                    <p>{t("VEHICLE_ADD.commercial_rental_insurance")}</p>
                                  </div>
                                </div>
                              </div>
                              <button className="next-but" onClick={() => { checkMainSectionValidation() }}>{t("VEHICLE_ADD.next")}</button>
                            </Accordion.Body>
                          </Accordion.Item>
                          {/* <Accordion.Item eventKey="phone" active-item={'phone' == activeItem || validSections.includes('main') || validSections.includes('phone') ? '' : "false"}>
                            <Accordion.Header onClick={() => { setActiveAccordionItem('phone') }}>
                              <div className="d-flex justify-content-between dropdown-but">
                                <b>Mobile number</b>
                                <a href="#" className="accordion-add-btn">{t("VEHICLE_ADD.add")}</a>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="country-code">
                                <h6>Country code</h6>
                                <SelectMenu
                                  id="country-code"
                                  name="country-code"
                                  className={`car-country-code ${invalid.includes('countryCode') ? 'invalid' : ''}`}
                                  options={[
                                    { value: '+1', name: 'USA' },
                                    { value: '+374', name: 'Armenia' },
                                    { value: '+8', name: 'Russia' },
                                  ]}
                                  handleOnChange={setCountryCode}
                                  selected={countryCode}
                                />
                              </div>
                              <div className="phone-number">
                                <h6>Phone number</h6>
                                <div className="input-group">
                                  <span className="input-group-text" id="basic-addon1">{countryCode}</span>
                                  <input value={phone} onChange={(e) => { setPhone(e.target.value) }} js-valid="true" type="text" className={`form-control ${invalid.includes('phone') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="phone-number" />
                                </div>
                              </div>
                              <button className="next-but" onClick={() => { checkPhoneSectionValidation() }}>{t("VEHICLE_ADD.next")}</button>
                            </Accordion.Body>
                          </Accordion.Item> */}
                          {/* <Accordion.Item eventKey="license" active-item={'license' == activeItem || validSections.includes('main') || validSections.includes('license') ? '' : "false"}>
                            <Accordion.Header onClick={() => { setActiveAccordionItem('license') }}>
                              <div className="d-flex justify-content-between dropdown-but">
                                <b>Driver’s license</b>
                                <a href="#" className="accordion-add-btn">{t("VEHICLE_ADD.add")}</a>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex justify-content-between">
                                <div className="driver-license">
                                  <h6>Issuing country</h6>
                                  <SelectMenu
                                    id="issuing-country"
                                    name="issuing-country"
                                    className={`car-license-country ${invalid.includes('licenseCountry') ? 'invalid' : ''}`}
                                    options={getAttributeValues(pa_license_country)}
                                    handleOnChange={setLicenseCountry}
                                    selected={licenseCountry}
                                  />
                                </div>
                                <div className="license-number">
                                  <h6>Driver’s license number</h6>
                                  <input value={licenseNumber} onChange={(e) => { setLicenseNumber(e.target.value) }} type="text" className={`form-control ${invalid.includes('licenseNumber') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="drivers-license-number" />
                                </div>
                              </div>
                              <div className="row name-sec">
                                <div className="col-md-4">
                                  <h6>First name</h6>
                                  <input value={licenseFirstName} onChange={(e) => { setLicenseFirstName(e.target.value) }} type="text" className={`form-control ${invalid.includes('licenseFirstName') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="first-name" />
                                </div>
                                <div className="col-md-4">
                                  <h6>Middle name</h6>
                                  <input value={licenseMiddleName} onChange={(e) => { setLicenseMiddleName(e.target.value) }} type="text" className={`form-control ${invalid.includes('licenseMiddleName') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="middle-name" />
                                </div>
                                <div className="col-md-4">
                                  <h6>Last name</h6>
                                  <input value={licenseLastName} onChange={(e) => { setLicenseLastName(e.target.value) }} type="text" className={`form-control ${invalid.includes('licenseLastName') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="last-name" />
                                </div>
                              </div>
                              <p className="name-p">Enter your name exactly as it appears on your driver’s license</p>
                              <div className="row date-birth">
                                <h6>Date of birth</h6>
                                <div className="col-md-4">
                                  <SelectMenu
                                    id="date-day"
                                    name="license-dob-day"
                                    className={`car-license-dob-day ${invalid.includes('licenseDobDay') ? 'invalid' : ''}`}
                                    options={days}
                                    handleOnChange={setLicenseDobDay}
                                    selected={licenseDobDay}
                                    selectValue={'Day'}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <SelectMenu
                                    id="date-month"
                                    name="license-dob-month"
                                    className={`car-license-dob-month ${invalid.includes('licenseDobMonth') ? 'invalid' : ''}`}
                                    options={months}
                                    handleOnChange={setLicenseDobMonth}
                                    selected={licenseDobMonth}
                                    selectValue={'Month'}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <SelectMenu
                                    id="date-year"
                                    name="license-dob-year"
                                    className={`car-license-dob-year ${invalid.includes('licenseDobYear') ? 'invalid' : ''}`}
                                    options={years}
                                    handleOnChange={setLicenseDobYear}
                                    selected={licenseDobYear}
                                    selectValue={'Year'}
                                  />
                                </div>
                              </div>
                              <button className="next-but" onClick={() => { checkLicenseSectionValidation() }}>{t("VEHICLE_ADD.next")}</button>
                            </Accordion.Body>
                          </Accordion.Item> */}
                          <Accordion.Item eventKey="details" active-item={'details' == activeItem || validSections.includes('main') || validSections.includes('details') ? '' : "false"}>
                            <Accordion.Header onClick={() => { setActiveAccordionItem('details') }}>
                              <div className="d-flex justify-content-between dropdown-but">
                                <b>{t("VEHICLE_ADD.car_details")}</b>
                                <a href="#" className="accordion-add-btn">{t("VEHICLE_ADD.add")}</a>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex justify-content-between">
                                <div className="phone-number">
                                  <h6>{t("VEHICLE_ADD.license_plate_number")}</h6>
                                  <input value={licensePlateNumber} onChange={(e) => { setLicensePlateNumber(e.target.value) }} type="text" className={`form-control ${invalid.includes('licensePlateNumber') ? 'invalid' : ''}`} aria-describedby="basic-addon1" name="last-name" />
                                </div>
                                <div className="phone-number">
                                  <h6>{t("VEHICLE_ADD.state")}</h6>
                                  <SelectMenu
                                    id="car-state"
                                    name="state"
                                    className={`car-state ${invalid.includes('licensePlateNumberState') ? 'invalid' : ''}`}
                                    options={getAttributeValues(pa_plate_numer_states)}
                                    handleOnChange={setLicensePlateNumberState}
                                    selected={licensePlateNumberState}
                                  />
                                </div>
                              </div>
                              <p className="name-p">{t("VEHICLE_ADD.license_plate_information")}</p>
                              <h6>{t("VEHICLE_ADD.car_description")}</h6>
                              <textarea value={carDescription} onChange={(e) => { setCarDescription(e.target.value) }} rows="6" className={`${invalid.includes('carDescription') ? 'invalid' : ''}`} placeholder="A detailed description will help you get more trips" name="car-description"></textarea>
                              <div className="car-describe">
                                <h6>{t("VEHICLE_ADD.car_features")}</h6>
                                <div className="checkbox-div">
                                  <div className="row">
                                    {
                                      pa_features?.map((f) => {
                                        return <div className="col-md-4" key={f.term_id}>
                                          <div className="round cursor-pointer" onClick={(e) => { handleFeatureChange(f.term_id) }}>
                                            <input readOnly checked={carFeatures?.includes(f.term_id)} type="checkbox" name={f.term_slug} />
                                            <label></label>
                                            <span>{f.term_name}</span>
                                          </div>
                                        </div>
                                      })
                                    }
                                  </div>
                                </div>
                                <button className="next-but" onClick={() => { checkDetailsSectionValidation() }}>{t("VEHICLE_ADD.next")}</button>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="photos" active-item={'photos' == activeItem || validSections.includes('details') || validSections.includes('photos') ? '' : "false"}>
                            <Accordion.Header onClick={() => { setActiveAccordionItem('photos') }}>
                              <div className="d-flex justify-content-between dropdown-but">
                                <b>{t("VEHICLE_ADD.car_photos")}</b>
                                <a href="#" className="accordion-add-btn">{t("VEHICLE_ADD.add")}</a>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="name-p">{t("VEHICLE_ADD.car_photo_guide")}</p>
                              <div className="car-photo">
                                <input required js-valid="true" type="file" multiple accept="image/*" onChange={selectFiles} className="custom-file-input" />
                                <div className="row">

                                  {productImages.length > 0 && (
                                    <>
                                      {productImages.map((img, i) => {
                                        return (
                                          <div className={`col-md-4 photo-wrapper`} key={img.id} >
                                            {mainImageIndex.type == 'id' && mainImageIndex.value == img.id
                                              ? <div className="check" title="Main Image">&#10003;</div>
                                              : <button type="button" className="remove" onClick={() => { removeOldImage(img.id) }}>&times;</button>
                                            }
                                            <img className="preview mw-100" src={img.src} alt={"image-" + img.id} onClick={() => { setMainImageIndex({ type: 'id', value: img.id }) }} />
                                          </div>
                                        );
                                      })}
                                    </>
                                  )}
                                  {canvasImages.length > 0 && (
                                    <>
                                      {canvasImages.map((img, i) => {
                                        return (
                                          <div className={`col-md-4 photo-wrapper`} key={i} >
                                            {mainImageIndex.type == 'index' && mainImageIndex.value == i
                                              ? <div className="check" title="Main Image">&#10003;</div>
                                              : <button type="button" className="remove" onClick={() => { removeImage(i) }}>&times;</button>
                                            }
                                            <img className="preview mw-100" src={img} alt={"image-" + i} onClick={() => {
                                              setMainImageIndex({ type: 'index', value: i })
                                            }} />
                                          </div>
                                        );
                                      })}
                                    </>
                                  )}
                                  {(canvasImages.length > 0 || productImages.length > 0) && (
                                    <div className="col-12" >
                                      <input required js-valid="true" type="file" multiple accept="image/*" onChange={selectFiles} className="custom-file-input" />
                                    </div>
                                  )}

                                  {invalid.includes('canvasImages') && (
                                    <div className="text-danger">{t("VEHICLE_ADD.please_add_images")}</div>
                                  )}
                                </div>
                              </div>
                              <button className="next-but" href="#" onClick={() => { checkPhotosSectionValidation() }}>{t("VEHICLE_ADD.next")}</button>
                            </Accordion.Body>
                          </Accordion.Item>
                          {/* <Accordion.Item eventKey="standarts" active-item={'standarts' == activeItem || validSections.includes('photos') || validSections.includes('standarts') ? '' : "false"}>
                            <Accordion.Header onClick={() => { setActiveAccordionItem('standarts') }}>
                              <div className="d-flex justify-content-between dropdown-but">
                                <b>Safety & quality standarts</b>
                                <a href="#" className="accordion-add-btn">{t("VEHICLE_ADD.add")}</a>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="name-p">Velit at vehicula sollicitudin amet, magna morbi a mollis. Vitae maecenas ac facilisi augue in consequat. Lorem tempus, molestie non nunc non varius augue ut. Tincidunt eget vulputate vel ut eget sapien elit. Egestas integer netus ut maecenas. Rhoncus, nisi, feugiat rhoncus lacus congue nec faucibus. Ipsum sollicitudin elit consectetur urna.</p>
                              <input type="checkbox" hidden name="safety-standarts" id="safety-standarts" />
                              <label htmlFor="safety-standarts" className={`agree-button ${agreementChecked ? 'active' : ''}`} onClick={() => { setAgreementChecked(!agreementChecked); }}>Agree and continue</label>
                            </Accordion.Body>
                          </Accordion.Item> */}
                          <Accordion.Item eventKey="disabled_dates" active-item={''}>
                            <Accordion.Header onClick={() => { setActiveAccordionItem('disabled_dates') }}>
                              <div className="d-flex justify-content-between dropdown-but">
                                <b>{t("VEHICLE_ADD.closed_dates")}</b>
                                <a href="#" className="accordion-add-btn">{t("VEHICLE_ADD.add")}</a>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="my-3">
                                <p className="closed-dates-paragraph">{t("VEHICLE_ADD.closed_dates_info")}</p>
                              </div>
                              <div className="my-3">
                                <CustomBookedDate existingDates={blockedDates} handleOnChange={(value) => { setBlockedDates(value) }} />
                                <AddDate onPublish={(value) => { addBlockedDate(value) }} />
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="publish-div">
                          <button className="publish-but" disabled={isFormInvalid || loading} type="button" onClick={handleOnSubmit}>{t("VEHICLE_ADD.update")}</button>
                          {loading == true
                            ? <div className="d-inline-block">
                              <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                            </div>
                            : ''
                          }
                          {/* <Link className="publish-but" to="/my-vehicles">Publish</Link> */}
                        </div>
                      </div>
                    </div>
                  }
                </>
                : <div className="d-flex align-items-center justify-content-center my-5">
                  <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                </div>
            }
          </div>
        </section>
      </main>
      <Footer />
    </Fragment >
  );
}


export default MyVehiclesEdit;
