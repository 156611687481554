import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Media } from "reactstrap";
import { BACK_TO_HOME_PAGE } from "../Constant";
import sad from '../assets/images/other-images/sad.png';
import Header from '../Layout/Header/Header';
import Seo from '../Components/seo';
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";

const ErrorPage404 = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Seo title={'404'} schema={{}} />
            <div className="page-wrapper">
                <Header />
                <div className="error-wrapper py-5">
                    <Container>
                        <Media body className="img-100 d-block mx-auto my-5" src={sad} alt="" />
                        <div className="error-heading">
                            <h2 className="headline text-danger text-center" style={{ fontSize: '100px' }} >{"404"}</h2>
                        </div>
                        <Col md="8 offset-md-2">
                            <p className="sub-content text-center mb-3" >{parse(t("ERROR_PAGE.error_text"))}</p>
                        </Col>
                        <div className='d-flex justify-content-center'>
                            <Link to={`${process.env.PUBLIC_URL}/`} className='d-inline-block mx-auto'>
                                <Button color="danger" size='lg'>{t("ERROR_PAGE.back_btn")}</Button>
                            </Link>
                        </div>
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default ErrorPage404;