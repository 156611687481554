import React, { Fragment, useRef } from "react";
import Footer from "../Layout/Footer/Footer";
import Header from "../Layout/Header/Header";
// import "../assets/css/home-map.css"
import "../assets/css/custom.css";
import "../assets/css/privacy.css";
import "../assets/css/privacy-page.css";
import "../assets/css/style.css";
import Seo from '../Components/seo';

const Privacy = () => {
    const contentRef = useRef();

    return (
        <Fragment>
            <Seo title={'Privacy Policy'} schema={{}} />
            <Header />
            <main className="p-0">
                <section className="privacy-policy">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-5 text-center text-white">
                                <h2>Privacy policy</h2>
                                <p>Last revised: September 25, 2021</p>
                                <p>Utrip Inc. and its subsidiaries (collectively, “Utrip”, “we”, or “us”) care about privacy and
                                    want you to be familiar with how we collect, use, process, and disclose your personal
                                    information. This Privacy Policy describes our privacy practices in connection with our
                                    websites and applications that link to this Privacy Policy (collectively, the “Services”)
                                    and our offline interactions with you in settings where we post this Privacy Policy.</p>
                                {/* <Link to="/privacy-policy" >Reach more</Link> */}
                                <button onClick={() => {contentRef.current.scrollIntoView()}} className="ab-1-content-button">Reach More</button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <main ref={contentRef}>
                {/* <section className="privacy-policy-page-header">
                </section> */}
                <section className="privacy-chapters">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7">
                                <h3>Chapters</h3>
                                <div className="row ">
                                    <div className="col-md-6">
                                        <a href="#privacy-policy-text">Personal information we collect</a>
                                        <a href="#how-we-use">How we use your personal information</a>
                                        <a href="#how-we-disclose">How we disclose your personal information</a>
                                        <a href="#your-preference">Your preferences and choices</a>
                                        <a href="#Security">Security</a>
                                    </div>
                                    <div className="col-md-6">
                                        <a href="#other-important">Other important information</a>
                                        <a href="#changes-to">Changes to this privacy policy</a>
                                        <a href="#contact-privacy">Contact</a>
                                        <a href="#california-resident">Information for California residents</a>
                                        <a href="#information-for">Information for users in the United Kingdom</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="privacy-policy-text" id="privacy-policy-text" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7">
                                <h4 className="privacy-text-header">
                                    Personal information we collect
                                </h4>
                                <p>We collect three categories of personal information - personal information you give us;
                                    personal information automatically collected from your use of the Services; and personal
                                    information from third-party sources.</p>
                                <h5>Personal information you give us</h5>
                                <p><b>Account data.</b> When you register for an account with us, we require certain personal
                                    information to open your account, such as your name, email address, and password.</p>
                                <p><b>Profile data.</b> We may also ask you to provide additional profile information to use
                                    certain features of the Services which may include street addresses, phone numbers, driver’s
                                    license number, date of issuance and issuing country and/or state, profile photos, employer,
                                    city, school, biography, and date of birth. Certain parts of your profile (like your profile
                                    photos, employer, city, school, and biography) are part of your public profile page and will
                                    be publicly visible to others.</p>
                                <p><b>Vehicle data.</b> We collect information you provide in relation to a vehicle such as
                                    vehicle listing details, vehicle identification number (VIN), availability dates, reviews,
                                    and uploaded trip photos.</p>
                                <p><b>Payment data.</b> We collect your digital payment details, bank account or payment card
                                    numbers, and transaction information in connection with a potential or actual transaction,
                                    which may be processed and stored by one or more third party payment service providers or
                                    digital payments companies.</p>
                                <p><b>Identity verification data.</b> In some instances, we may collect identity verification
                                    information such as a photograph or scanned copy of a driver’s license, passport, national
                                    ID card, or payment card, last four digits of your Social Security number, social insurance
                                    number, social media account information, driver’s/motor vehicle record, insurance
                                    information, or other forms of identification information. Where we request that you
                                    withhold certain information (such as obscuring or redacting aspects of identification
                                    information), please do so. To learn more, visit our Help Center article about Getting
                                    Approved to Drive.</p>
                                <p>Communications. When you communicate with Utrip, including via phone, email, or chat, or use
                                    the Services to communicate with other users, we collect information about your
                                    communication and any information you choose to provide.</p>
                                <h5>Personal information we automatically collect</h5>
                                <p><b>Usage data.</b> We collect information about your interactions with the Services, such as
                                    the pages or other content you view, your searches, bookings you have made, how long you
                                    spent on a page or screen, sites from which you link or browse to in the Services,
                                    navigation paths between pages or screens, information about your activity on a page or
                                    screen, access time, duration of access, and other actions on the Services.</p>
                                <p><b>Device data.</b> We collect information about your computer or mobile device, such as its
                                    operating system type and version number, manufacturer and model, browser type, screen
                                    resolution, IP address, unique device identifiers, or general location information such as
                                    city, state, or geographic area.</p>
                                <p><b>Trip data.</b> We collect transactional information related to the trips you take through
                                    the Services, including the date and time of your trip, amounts charged, and other related
                                    trip details.</p>
                                <p><b>In-vehicle device data.</b> If a vehicle is enrolled in a Utrip Go program or has a Utrip Go
                                    device installed, we may remotely collect certain information about the vehicle. Depending
                                    on the make, model, and year of the vehicle, this information may include, GPS location, car
                                    battery voltage, fuel level, odometer reading, malfunction indicator lamp (e.g., a check
                                    engine light), door state (e.g., locked/unlocked), engine state (e.g., stopped/running), and
                                    VIN.</p>
                                <p><b>Cookies and similar technology.</b> When you access the Services, we (including companies
                                    we work with) may place small data files on your computer or other device. These data files
                                    may be cookies, clear gifs, pixel tags, e-tags, “Flash cookies”, or other local storage
                                    provided by your browser or associated applications (collectively “Cookies”). For more
                                    detailed information on our use of these technologies, please see our policy on Cookies, Web
                                    Beacons, and Similar Technologies. At this time, we do not respond to browser ‘Do-Not-Track’
                                    signals.</p>
                                <h5>Personal information we collect from third-party sources</h5>
                                <p><b>Third-party services.</b> If you choose to log in to our Services through a third-party
                                    site or service (e.g., Apple, Facebook, or Google), the third-party service may send us
                                    information such as your registration and profile information from that service. The
                                    information we may receive varies by service and is controlled by the relevant service. By
                                    associating an account managed by a third party with your Utrip account and authorizing Utrip
                                    to have access to this information, you agree that Utrip may collect, store, and use this
                                    information in accordance with this Privacy Policy. We are not responsible for how those
                                    third parties use and share your information. Please refer to the privacy policies of those
                                    third parties to understand how they use and share your personal information.</p>
                                <p><b>Third-party in-vehicle devices.</b> If your vehicle, or a vehicle you book through the
                                    Services, includes an in-vehicle device or system operated by a third-party service
                                    (including vehicle manufacturers) or installed by the host, that host or service may record
                                    information about your use of the car. These devices or systems may monitor the car's
                                    condition, performance and operation, track fuel consumption, distance traveled, location,
                                    and other information. The host and/or third-party service will be solely responsible for
                                    its use of such information but may disclose such information to Utrip, which we will use in
                                    accordance with this Privacy Policy.</p>
                                <p><b>Background check services.</b> To the extent permitted by applicable laws, Utrip may
                                    collect background information about you from public records, background check providers, or
                                    other screening services, including credit reports and information about criminal
                                    convictions or from sex offender registries. We may use your information, including your
                                    full name and date of birth, to obtain such reports.</p>
                                <p><b>Other sources.</b> To the extent permitted by applicable law, we may receive additional
                                    information about you, such as demographic data, fraud detection information, or data from
                                    credit bureaus and other third-party data providers.</p>
                                <p>We may combine the information about you that we receive from third-party sources with other
                                    information we have about you.</p>
                                <h4 id="how-we-use" >How we use your personal information</h4>
                                <p>We use, store, and process your personal information to provide and improve the Services and
                                    for security and safety purposes. For example, we may use your information:</p>
                                <h5>To provide the Services, including to:</h5>
                                <p>Provide and operate the Services</p>
                                <p>Provide customer support</p>
                                <p>Send you service, support, and administrative messages, reminders, technical notices,
                                    updates, security alerts, and information requested by you at any telephone number, by
                                    placing a voice call or through text (SMS) or email messaging</p>
                                <p>Facilitate your login to the Services via third-party identity and access management
                                    providers, such as Facebook, Google, and Apple</p>
                                <p>Process transactions and send notices about your transactions</p>
                                <p>Personalize or customize your user experience</p>
                                <p>Enable you to communicate with other Utrip users, including by sending them messages or other
                                    information during the booking process</p>
                                <p>Facilitate your referral invitations</p>
                                <p>Send your requests for reviews, for fraud detection and prevention, and for any purpose you
                                    authorize at the time of collection</p>
                                <p>Send your requests for reviews, for fraud detection and prevention, and for any purpose you
                                    authorize at the time of collection</p>
                                <p>Administer referral programs, rewards, surveys, contests, or other promotional activities or
                                    sponsored events in which you participate</p>
                                <h5>For research and development:</h5>
                                <p>We may use your personal information to analyze and improve the Services and to develop new
                                    products and services, including by studying our user demographics of the Services. We may
                                    also create aggregated, de-identified, or other anonymous data from your personal
                                    information. We make personal information into anonymous data by removing information that
                                    makes the data personally identifiable to you. We may use this anonymous data and share it
                                    with third parties for our lawful business purposes, including to analyze and improve the
                                    Services and promote our business.</p>
                                <h5>For marketing and advertising:</h5>
                                <p>We and our third party advertising partners may collect and use your personal information for
                                    marketing and advertising purposes:</p>
                                <p><b>Direct marketing.</b> We may send you Utrip-related marketing communications as permitted
                                    by law. You will have the ability to opt-out of our marketing and promotional communications
                                    as described in the Opting out of marketing communications section below.</p>
                                <p><b>Interest-based advertising.</b> We may contract with third party advertising and social
                                    media companies to display ads on the Services and other sites. These companies may use
                                    cookies and similar technologies to collect information about you (including the device
                                    data, online activity data, and/or geolocation data described above) over time across our
                                    Services and other sites and services or your interaction with our emails, and use that
                                    information to serve ads that they think will interest you. These ads are known as
                                    “interest-based advertisements”. You can learn more about your choices for limiting
                                    interest-based advertising, in the Interest-based advertising choices section below.</p>
                                <h5>For security and safety, including to:</h5>
                                <p>Verify your identity or authenticate information that you provide, including during account
                                    creation and password reset processes</p>
                                <p>Resolve disputes, collect fees, and troubleshoot problems</p>
                                <p>Detect, prevent, and/or remediate fraud, abuse, security incidents, or other potentially
                                    harmful, prohibited, or illegal activities</p>
                                <p>Determine your likelihood of getting into an accident or of making an insurance claim, such
                                    as by checking your auto insurance score</p>
                                <p>Using information from your mobile or in-vehicle device to identify unsafe driving behavior,
                                    including speeding or harsh braking and acceleration, and to raise awareness regarding such
                                    behaviors</p>
                                <p>Detect, prevent, or remediate violations of and enforce our Terms of Service and Policies.
                                </p>
                                <p>Manage and protect our information technology infrastructure</p>
                                <p>Conduct investigations and risk assessments</p>
                                <p>Conduct checks against databases and information sources (such as but not limited to public
                                    government databases)</p>
                                <p>Perform creditworthiness and solvency checks</p>
                                <h5>To comply with law:</h5>
                                <p>We use your personal information as we believe necessary or appropriate to comply with
                                    applicable laws, lawful requests, and legal processes, such as to respond to subpoenas or
                                    requests from government authorities.</p>
                                <h5>With your consent:</h5>
                                <p>In some cases, we may specifically ask for your consent to process your personal information.
                                </p>
                                <p>We may also use your personal information as described elsewhere in this Privacy Policy or as
                                    disclosed to you at the time of collection.</p>
                                <h4 id="how-we-disclose" >How we disclose your personal information</h4>
                                <h5>With your consent</h5>
                                <p>We may share your information at your direction or with your consent.</p>
                                <h5>Profiles, listings, and other public information</h5>
                                <p>Your public listing page on the Services will always include some basic information, such as
                                    your user ID or name associated with your account, your public profile photo, and for hosts,
                                    the city where your car is located, your listing description, your calendar availability,
                                    transaction related information to allow our community to evaluate your reliability and
                                    responsiveness, and reviews or feedback about you. Your public listing page may also include
                                    aggregate demand information (such as number of page views over a period of time) and
                                    information about your cancellations. The Services may also display the approximate
                                    geographic pick-up location of your vehicle.</p>
                                <p>The Services allow your public profile and public listing pages to be included in search
                                    engines, in which case your public profile and public listing pages may be indexed by search
                                    engines and may be published as search results.</p>
                                <h5>Sharing between hosts and guests</h5>
                                <p>Utrip enables car owners to offer and share their vehicles with other individuals. If you
                                    agree to a booking through the Services, we may provide your information to the other party
                                    in that transaction as reasonably necessary to facilitate the transaction. For example,
                                    Utrip may provide your mobile phone number to facilitate communication, your driver's
                                    license information to confirm license validity, or your photograph to facilitate
                                    identification. We will also share the address of the vehicle and, if applicable, the
                                    proposed delivery location with the other party. If you used the Trip Photo feature, we may
                                    share the trip photos you upload and associated captions with the other party. The other
                                    party may also send you text messages (such as to confirm pickup or delivery location).
                                    Standard telephone minute and text and data charges may apply.</p>
                                <h5>Service providers</h5>
                                <p>We may share information with vendors and service providers who support the operation of the
                                    Services and business and who need access to such information to carry out their work for us
                                    (including, for example, web hosting, analytics, payment processing, email delivery,
                                    marketing, insurance, claims administration, and customer support services). In some cases,
                                    the service provider may directly collect the information from you on our behalf. For hosts
                                    who choose to use our photography program, your contact information will be shared with the
                                    photographer assigned to shoot your vehicle. These service providers may use your personal
                                    information only as directed or authorized by us.</p>
                                <h5>Third-party platforms and social media networks</h5>
                                <p>If you have enabled features or functionality that connect the Services to a third-party
                                    platform or social media network (such as by logging in to the Services using your account
                                    with the third party, providing your API key or similar access token for the Services to a
                                    third party, or otherwise linking your Services account to a third-party’s services), we may
                                    disclose to the third-party platform or social media network the personal information
                                    necessary to facilitate the connection or that you authorized us to share. We do not control
                                    the third party’s use of your personal information.</p>
                                <h5>Professional advisors</h5>
                                <p>We may disclose your personal information to professional advisors, such as lawyers, bankers,
                                    auditors, and insurers, where necessary in the course of the professional services that they
                                    render to us.</p>
                                <h5>Business transfers</h5>
                                <p>We may sell, transfer, or otherwise share some or all of our business or assets, including
                                    your personal information, in connection with a business transaction (or potential business
                                    transaction) such as a corporate divestiture, merger, consolidation, acquisition,
                                    reorganization, or sale of assets, or in the event of bankruptcy or dissolution.</p>
                                <h5>Responding to legal requests, preventing harm, and protecting our rights</h5>
                                <p>We may disclose your personal information to courts, law enforcement, governmental or tax
                                    authorities, or third parties. We will make such disclosure to the extent we are required or
                                    permitted to do so by applicable law or where we consider such disclosure is reasonably
                                    necessary to comply with our legal obligations or legal process, to respond to claims
                                    asserted against us, and for the security and safety purposes described above. We may also
                                    disclose your information in response to valid legal requests relating to criminal
                                    investigations or alleged or suspected illegal activity or any other activity that may
                                    expose Utrip, you, or any other user, or to protect the rights, property, or personal safety
                                    of Utrip, our users, or others.</p>
                                <p>We may also disclose your personal information as described elsewhere in this Privacy Policy
                                    or as disclosed to you at the time of collection.</p>
                                <h4 id="your-preference" >Your preferences and choices</h4>
                                <h5>Communication preferences</h5>
                                <p>You can control the methods by which we may contact you about your account, your booking and
                                    listing activities, promotions, and announcements in the Notifications section within your
                                    Utrip account.</p>
                                <h5>Opting out of marketing communications</h5>
                                <p>You may opt out of marketing-related emails by following the opt-out or unsubscribe
                                    instructions at the bottom of the email. You may continue to receive service-related and
                                    other non-marketing emails. If you receive marketing text messages from us, you may opt out
                                    of receiving further marketing text messages from us by replying STOP to our marketing
                                    message or as described in the message.</p>
                                <h5>Correct and update</h5>
                                <p>You can review, correct, update, and edit certain information that has been previously
                                    provided to us by you at any time by logging in to your account and reviewing your account
                                    settings and profile. You can also access or request a correction of your information by
                                    contacting us at support.utrip.com. For your protection, we may need to verify your identity
                                    before implementing your request.</p>
                                <h5>Account closure</h5>
                                <p>If you wish to close your account and request deletion of your personal information, please
                                    send an email to accountclosure@utrip.com or contact us at support.utrip.com.</p>
                                <p>Please note that we may be unable to delete information needed to comply with applicable
                                    laws, detect or prevent fraud, collect any fees owed, resolve disputes, assist with or
                                    process claims, troubleshoot problems, assist with any audits and investigations, to enforce
                                    our Terms of Service and Policies, and take other actions reasonably necessary, permitted,
                                    or required by applicable law. There may also be residual information that will remain
                                    within our databases and other records, which will not be removed.</p>
                                <h5>Access</h5>
                                <p>You can request a copy of your personal information pursuant to any information access rights
                                    that you may have under applicable laws in your account page. We may request proof of
                                    identification or re-authentication to verify your access request.</p>
                                <h5>Location data</h5>
                                <p>Users of our mobile application can disable the application’s access to the device’s location
                                    within the device’s settings.</p>
                                <h5>Interest-based advertising choices</h5>
                                <p>Your choices for limiting use of your personal information for interest-based advertising
                                    include:</p>
                                <p><b>Blocking cookies in your browser.</b> Most browsers let you remove or reject cookies,
                                    including cookies used for interest-based advertising. To do this, follow the instructions
                                    in your browser settings. Many browsers accept cookies by default until you change your
                                    settings. For more information about cookies, including how to see what cookies have been
                                    set on your device and how to manage and delete them, visit www.allaboutcookies.org.</p>
                                <p><b>Blocking advertising ID use in your mobile settings.</b> Your mobile device settings may
                                    provide functionality to limit use of the advertising ID associated with your mobile device
                                    (e.g., Apple ID for Advertising or Google Advertising ID) for interest-based advertising
                                    purposes.</p>
                                <p><b>Using privacy plug-ins or browsers.</b> You can block sites from setting cookies for
                                    interest-based ads by using a browser with privacy features, like Brave, or installing
                                    browser plugins like Privacy Badger, Ghostery, or uBlock Origin, and configuring them to
                                    block third party cookies/trackers.</p>
                                <p><b>Advertising industry opt-out tools.</b> You can also use these opt-out options to limit
                                    use of your information for interest-based advertising by participating companies (but note
                                    we may work with companies that do not participate in these programs)</p>
                                <h5>Choosing not to share your personal information</h5>
                                <p>Where we are required by law to collect your personal information, or where we need your
                                    personal information in order to provide the Services to you, if you do not provide this
                                    information when requested (or we later delete it at your request), we may not be able to
                                    provide you with the Services.</p>
                                <h5>Third-party platforms and social media networks</h5>
                                <p>If you choose to connect the Services to a third-party platform or social media network, such
                                    as by using the third party’s authentication service to log into your account on the
                                    Services, you may be able to control your settings through the third-party platform or
                                    social media network. If you withdraw our ability to access certain information from a
                                    third-party platform or social media network, that choice will not apply to information that
                                    we have already received from that third party.</p>
                                <h4 id="Security" >Security</h4>
                                <p>We employ a number of technical, physical, and organizational measures designed to protect
                                    information against unauthorized access, destruction, or alteration while it is under our
                                    control. However, no method of transmitting or storing information can be 100% secure and we
                                    cannot guarantee the security of your personal information.</p>
                                <h4 id="other-important">Other important information</h4>
                                <h5>Cross border transfer</h5>

                                <p>The Services are controlled and operated from the United States. Your personal information
                                    may be used, stored, and processed in any country where we have facilities or in which we
                                    engage service providers. These locations may be outside of your state, province, or country
                                    of residence, and may have different and/or less protective data protection rules than those
                                    of your state, province, or country. As a result, this information may be subject to access
                                    requests from governments, courts, regulatory agencies, security authorities, or law
                                    enforcement in those jurisdictions according to the laws in those jurisdictions.</p>
                                <h5>Sensitive information</h5>
                                <p>We ask that you not send us, and you not disclose, any sensitive information (e.g., Social
                                    Security numbers, social insurance numbers, passports, information related to racial or
                                    ethnic origin, or health) on or through the Services or otherwise to us unless specifically
                                    requested.</p>
                                <h5>Children</h5>
                                <p>The Services are not intended for anyone under the age of 18 and we do not knowingly collect
                                    personal information from users under the age of 18. If a child under the age of 18 has
                                    already provided us with personal information, his or her parent or guardian may contact us
                                    to request that we delete it.</p>
                                <h5>Third-party privacy practices</h5>
                                <p>This Privacy Policy addresses only the use and disclosure of information collected by Utrip.
                                    This Privacy Policy does not address, and we are not responsible for, the privacy,
                                    information, or other practices of any third parties, including any third party operating
                                    any site or service to which the Services link. The inclusion of a link on the Services does
                                    not imply endorsement of the linked site or service by us or by our affiliates. If you
                                    disclose your information to others, or if you are directed to a third-party website, their
                                    privacy notices and practices will apply.</p>
                                <h5>Translations</h5>
                                <p>Where Utrip has provided you with a version of this Privacy Policy in a language other than
                                    English, in case of any wording discrepancies between such version and the English version,
                                    the English wording takes precedence.</p>
                                <h4 id="changes-to">Changes to this privacy policy</h4>
                                <p>We may change this Privacy Policy. Please take a look at the “Last revised” legend at the top
                                    of this page to see when this Privacy Policy was last revised. Any changes to this Privacy
                                    Policy will become effective when we post the revised Privacy Policy on the Services or as
                                    otherwise indicated. Your use of the Services following these changes means that you accept
                                    the revised Privacy Policy. If you don’t agree to these changes, you can contact us to close
                                    your account.</p>
                                <h4 id="contact-privacy">Contact</h4>
                                <p>Utrip welcomes your questions and comments about privacy. Please feel free to contact us at
                                    our address in the United States. You may also email us.</p>
                                <h4 id="california-resident">Information for California residents</h4>
                                <p><b>Scope.</b> This section applies only to California residents entitled to receive the
                                    information in this section under the California Consumer Privacy Act of 2018
                                    ("<b>CCPA</b>"). It describes how we collect, use, and share Personal Information of
                                    California residents when we act as a "business" as defined under the CCPA, and their rights
                                    with respect to their Personal Information. For purposes of this section, "<b>Personal
                                        Information</b>" has the meaning given in the CCPA but does not include information
                                    excluded from the scope of the CCPA. In some cases, we may provide a different privacy
                                    notice to certain categories of California residents, such as job applicants, in which case
                                    that notice will apply instead of this section.</p>
                                <h5>Your California privacy rights</h5>
                                <p>As a California resident, you have the rights listed below.</p>
                                <p><b>Information.</b> You can request the following information about how we have collected and
                                    used your Personal Information:<br />
                                    The categories of Personal Information that we have collected<br />
                                    The categories of sources from which we collected Personal Information<br />
                                    The business or commercial purpose for collecting Personal Information<br />
                                    The categories of third parties with whom we share Personal Information<br />
                                    Whether we have disclosed your Personal Information for a business purpose, and if so, the
                                    categories of Personal Information received by each category of third-party recipient<br />
                                    Whether we have sold your Personal Information, and if so, the categories of Personal
                                    Information received by each category of third-party recipient</p>
                                <p><b>Access.</b> You can request a copy of the Personal Information that we have collected
                                    about you during the past 12 months.</p>
                                <p><b>Deletion.</b> You can request that we delete the Personal Information that we have
                                    collected from you.</p>
                                <p><b>Nondiscrimination.</b> You are entitled to exercise the rights described above free from
                                    discrimination in the form of any unlawful denial of service, increase in the price of
                                    services, decrease in service quality, or suggestion that you may be penalized for
                                    exercising your rights.</p>
                                <h5>We do not sell your Personal Information</h5>
                                <p>Based on our understanding of the term "sell" under the CCPA, we do not sell personal
                                    information to third parties and have not sold Personal Information during the twelve months
                                    preceding the "last revised" date of this Privacy Policy. However, like many companies
                                    online, we use services provided by Google, Facebook, and others that help deliver
                                    interest-based ads to you as described in the section above entitled Interest-based
                                    advertising. We describe how you can opt-out of use of your Personal Information for
                                    interest-based advertising in the section entitled Interest-based advertising choices.</p>
                                <h5>How to exercise your rights</h5>
                                <p>You can request to exercise your information, access, and deletion rights by contacting us at
                                    support.utrip.com or emailing privacy@utrip.com.</p>
                                <p>We may decline your request where required or permitted by law. We will need to confirm your
                                    identity to process your requests and we reserve the right to confirm your California
                                    residency as well. Government identification may be required. You may designate an
                                    authorized agent to make a request on your behalf by providing a valid power of attorney,
                                    the requester’s valid government-issued identification, and the authorized agent’s valid
                                    government issued identification. We cannot process your request if you do not provide us
                                    with sufficient detail to allow us to understand and respond to it. You should transmit your
                                    government-issued identification or any other sensitive information to us only as
                                    instructed. Email is not a secure means of communication and you should not email your
                                    government-issued identification or any other sensitive information to us.</p>
                                <h5>Personal information that we collect, use, and share</h5>
                                <p>The chart accessible here provides additional information pursuant to the CCPA about the
                                    Personal Information we collect, use, and share.</p>
                                <h4 id="information-for">Information for users in the United Kingdom and Europe</h4>
                                <p>The information in this section applies only to individuals in the United Kingdom and the
                                    European Economic Area (“Europe”).</p>
                                <p><b>Personal information.</b> Except as otherwise specified, references to “personal
                                    information” in this Privacy Policy are equivalent to “personal data” governed by European
                                    data protection legislation.</p>
                                <p><b>Controller.</b> Utrip Inc. is the controller of your personal information covered by this
                                    Privacy Policy for purposes of data protection legislation in the United Kingdom and Europe.
                                    You may contact us at privacy@utrip.com.</p>
                                <p><b>Legal bases for processing.</b> The legal bases of our processing of your personal
                                    information as described in this Privacy Policy will depend on the type of personal
                                    information and the specific context in which we process it. If you have questions about the
                                    legal basis of how we process your personal information, contact us at privacy@utrip.com.
                                </p>
                                <p><b>Use for new purposes.</b> We may use your personal information for reasons not described
                                    in this Privacy Policy where permitted by law and the reason is compatible with the purpose
                                    for which we collected it. If we need to use your personal information for an unrelated
                                    purpose, we will notify you and explain the applicable legal basis.</p>
                                <p><b>Retention.</b> We retain personal information where we have an ongoing legitimate business
                                    need to do so (for example, to provide you with a service you have requested; to comply with
                                    applicable legal, tax, or accounting requirements; to establish or defend legal claims; or
                                    for fraud prevention). When we have no ongoing legitimate business need to process your
                                    personal information, we will either delete or anonymize it, or if this is not possible (for
                                    example, because your personal information has been stored in backup archives), then we will
                                    securely store your personal information and isolate it from any further processing until
                                    deletion is possible.</p>
                                <h5>Your rights</h5>
                                <p>The data protection laws of the United Kingdom and Europe give you certain rights regarding
                                    your personal information. If you are located in the United Kingdom or Europe, you may ask
                                    us to take the following actions in relation to your personal information that we hold:</p>
                                <p><b>Access.</b> Provide you with information about our processing of your personal information
                                    and give you access to your personal information.</p>
                                <p><b>Correct.</b> Update or correct inaccuracies in your personal information.</p>
                                <p><b>Delete.</b> Delete your personal information.</p>
                                <p><b>Transfer.</b> Transfer a machine‐readable copy of your personal information to you or a
                                    third party of your choice.</p>
                                <p><b>Restrict.</b> Restrict the processing of your personal information.</p>
                                <p><b>Object.</b> Object to our reliance on our legitimate interests as the basis of our
                                    processing of your personal information that impacts your rights.</p>
                                <p>You may submit these requests by email to privacy@utrip.com or our postal address or by
                                    accessing certain features in the Services.</p>
                                <p>We may request specific information from you to help us confirm your identity and process
                                    your request. Applicable law may require or permit us to decline your request. If we decline
                                    your request, we will tell you why, subject to legal restrictions. If you would like to
                                    submit a complaint about our use of your personal information or our response to your
                                    requests regarding your personal information, you may contact us at privacy@utrip.com or
                                    submit a complaint to the data protection regulator in your jurisdiction. You can find your
                                    data protection regulator here.</p>
                                <h5>Cross‐Border Data Transfer</h5>
                                <p>If we transfer your personal information from Europe to a country outside of Europe such that
                                    we are required to apply additional safeguards to your personal information under European
                                    data protection laws, we will do so, for example, by implementing the standard contractual
                                    clauses adopted by the European Commission. Similarly, if we transfer your personal
                                    information from the United Kingdom to a country outside of the United Kingdom such that we
                                    are required to apply additional safeguards to your personal information under data
                                    protection laws of the United Kingdom, we will do so, for example, by implementing the
                                    applicable standard contractual clauses. Please contact us at privacy@utrip.com for further
                                    information about any such transfers or the specific safeguards applied.</p>
                                <p>Thanks for using Utrip!</p>
                            </div>
                        </div >
                    </div >
                </section >
            </main >
            <Footer />
        </Fragment >
    );
}

export default Privacy;
