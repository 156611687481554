import React, { Fragment } from "react";
import Footer from "../Layout/Footer/Footer";
import Header2 from "../Layout/Header/Header2";
// import "../assets/css/home-map.css"
import "../assets/css/custom.css";
import "../assets/css/style.css";
import Seo from '../Components/seo';


const FAQ = () => {


    return (
        <Fragment>
            <Seo title={'FAQ'} schema={{}} />
            <Header2 />
                FAQ
            <Footer />
        </Fragment>
    );
}

export default FAQ;
