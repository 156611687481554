import axios from 'axios';
import React, { useState } from 'react';
import { ProductsApi } from '../../api';
import Context from './index';

const ProductsProvider = (props) => {

    const defaultTimeOptions = [
        { value: '12:00', name: '12:00 PM' },
        { value: '13:00', name: '01:00 PM' },
        { value: '14:00', name: '02:00 PM' },
        { value: '15:00', name: '03:00 PM' },
        { value: '16:00', name: '04:00 PM' },
        { value: '17:00', name: '05:00 PM' },
        { value: '18:00', name: '06:00 PM' },
        { value: '19:00', name: '07:00 PM' },
        { value: '20:00', name: '08:00 PM' },
        { value: '21:00', name: '09:00 PM' },
    ];

    const [newAddedProductId, setNewAddedProductId] = useState(null);
    const [myProducts, setMyProducts] = useState(null);
    const [productForEdit, setProductForEdit] = useState(null);
    const [productForView, setProductForView] = useState(null);
    const [favoriteProducts, setFavoriteProducts] = useState(null);

    const [productListFilters, setProductListFilters] = useState({
        transmission: '',
        make: '',
        model: '',
        style: '',
        years: '',
        odometer: '',
        year_min: '2020',
        year_max: '2023',
        year_from: '2020',
        year_to: '2023',
        per_page: 24,
        // per_page: 1,
        page: 1,
        pages: 0,
        found_posts: 0,
        book_date_from: '',
        book_date_to: '',
        book_time_from: '',
        book_time_to: '',
        place: null,
        lat: '',
        lng: '',
    });
    const [productsList, setProductsList] = useState(null);

    const setProductFilter = (item) => {
        // console.log(item);
        let newFiltersList = { ...productListFilters, ...item };
        // console.log(newFiltersList);
        // newFiltersList[key] = value;
        setProductListFilters(newFiltersList);
    }

    const [mapProductListFilters, setMapProductListFilters] = useState({
        place: null,
        lat: '',
        lng: '',
    });
    const [mapPlacePosition, setMapPlacePosition] = useState({
        lat: '',
        lng: '',
    });
    const [mapProductsList, setMapProductsList] = useState({});
    const setMapProductFilter = (item) => {
        // console.log(item);
        let newFiltersList = { ...mapProductListFilters, ...item };
        // console.log(newFiltersList);
        // newFiltersList[key] = value;
        setMapProductListFilters(newFiltersList);
    }

    const initMapProducts = () => {
        getMapProducts();
    }
    const getMapProducts = async () => {
        try {
            let exclude = Object.keys(mapProductsList).map((key) => {
                if (!!mapProductsList[key].id) {
                    return mapProductsList[key].id;
                }
            })
            let params = {
                lat: mapProductListFilters.lat,
                lng: mapProductListFilters.lng,
                exclude: exclude,
            }
            await axios.get(ProductsApi.getMapProducts, {
                // headers: {
                //     Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                // },
                params: params
            }).then((resp) => {
                // console.log(resp);
                let products = {};
                resp.data?.products?.forEach(item => {
                    products[`item_${item.id}`] = {
                        id: item.id,
                        url: `/vehicle/${item.id}`,
                        position: {
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                        },
                        title: item.title,
                        address: item.place?.label,
                        isActive: false,
                    }
                });
                let newMarkers = {
                    ...mapProductsList,
                    ...products,
                }
                if (!!mapProductListFilters?.place) {
                    newMarkers['center'] = {
                        url: null,
                        position: {
                            lat: parseFloat(mapPlacePosition.lat),
                            lng: parseFloat(mapPlacePosition.lng),
                        },
                        title: 'Your Seached Location',
                        address: mapProductListFilters?.place?.label,
                        isActive: false,
                    }
                }
                // console.log(newMarkers);
                setMapProductsList(newMarkers);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    }

    const toggleMarkerActive = (key, active) => {
        let newMarkers = { ...mapProductsList }
        newMarkers[key] = {
            ...mapProductsList[key],
            isActive: active
        }
        setMapProductsList(newMarkers);
    }


    const [singleProduct_book_date_from, setSingleProduct_book_date_from] = useState('');
    const [singleProduct_book_date_to, setSingleProduct_book_date_to] = useState('');
    const [singleProduct_book_time_from, setSingleProduct_book_time_from] = useState('');
    const [singleProduct_book_time_to, setSingleProduct_book_time_to] = useState('');
    const [singleProduct_duration_days, setSingleProduct_duration_days] = useState(0);
    const [singleProduct_duration_hours, setSingleProduct_duration_hours] = useState(0);
    const [singleProduct_duration_daily_amount, setSingleProduct_duration_daily_amount] = useState(0);
    const [singleProduct_duration_hourly_amount, setSingleProduct_duration_hourly_amount] = useState(0);
    const [singleProduct_delivery_amount, setSingleProduct_delivery_amount] = useState(0);
    const [singleProduct_driver_days, setSingleProduct_driver_days] = useState(0);
    const [singleProduct_driver_amount, setSingleProduct_driver_amount] = useState(0);
    
    const [singleProduct_total_amount, setSingleProduct_total_amount] = useState(0);
    const [singleProduct_loading, setSingleProduct_loading] = useState(false);
    const [singleProduct_error, setSingleProduct_error] = useState('');

    const setSingleProductBookField = (item) => {
        Object.keys(item).forEach(key => {
            switch (key) {
                case 'book_date_from':
                    setSingleProduct_book_date_from(item[key])
                    break;
                case 'book_date_to':
                    setSingleProduct_book_date_to(item[key])
                    break;
                case 'book_time_from':
                    setSingleProduct_book_time_from(item[key])
                    break;
                case 'book_time_to':
                    setSingleProduct_book_time_to(item[key])
                    break;
                case 'duration_days':
                    setSingleProduct_duration_days(item[key])
                    break;
                case 'duration_hours':
                    setSingleProduct_duration_hours(item[key])
                    break;
                case 'duration_daily_amount':
                    setSingleProduct_duration_daily_amount(item[key])
                    break;
                case 'duration_hourly_amount':
                    setSingleProduct_duration_hourly_amount(item[key])
                    break;
                case 'delivery_amount':
                    setSingleProduct_delivery_amount(item[key])
                    break;
                case 'total_amount':
                    setSingleProduct_total_amount(item[key])
                    break;
                case 'driver_days':
                    setSingleProduct_driver_days(item[key])
                    break;
                case 'driver_amount':
                    setSingleProduct_driver_amount(item[key])
                    break;
                case 'loading':
                    setSingleProduct_loading(item[key])
                    break;
                case 'error':
                    setSingleProduct_error(item[key])
                    break;
            }
        });
    }

    const addProduct = async (payload) => {
        try {
            await axios.post(ProductsApi.add, {
                ...payload
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    setNewAddedProductId(resp.data.product_id);
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const getMyProducts = async () => {
        try {
            await axios.get(ProductsApi.getMy, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                setMyProducts(resp.data);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    }

    const getProductForEdit = async (id) => {
        setProductForEdit(null);
        try {
            await axios.get(ProductsApi.getProductForEdit, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                },
                params: { id: id }
            }).then((resp) => {
                setProductForEdit(resp.data);
            });
        } catch (error) {
            setProductForEdit(false);
            console.log('cancelled', error);
        }
    }

    const updateProduct = async (payload) => {
        try {
            await axios.post(ProductsApi.update, {
                ...payload
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp.data) {
                    // setNewAddedProductId(resp.data.product_id);
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const getProductForView = async (id) => {
        setProductForView(null);
        try {
            let req_params = {
                params: { id: id }
            }
            let token = JSON.parse(localStorage.getItem("token"));
            if (!!token) {
                req_params.headers = {
                    Authorization: `Bearer ${token}`
                }
            }
            await axios.get(ProductsApi.getProductForView, req_params).then((resp) => {
                setProductForView(resp.data);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    }

    const getFilteredProducts = async (page = 0) => {
        try {
            setProductsList(null);

            if (!page) {
                page = productListFilters?.page;
            } else {
                setProductFilter({ 'page': page });
            }

            let params = {
                per_page: productListFilters?.per_page,
                offset: (page - 1) * productListFilters?.per_page,
                filter_product: '',
                filter_attributes: [],
                book_date_from: productListFilters.book_date_from,
                book_date_to: productListFilters.book_date_to,
                book_time_from: productListFilters.book_time_from,
                book_time_to: productListFilters.book_time_to,
                lat: productListFilters.lat,
                lng: productListFilters.lng,
            }

            if (!!productListFilters?.transmission) {
                params.filter_attributes.push({
                    attribute_slug: 'pa_transmission',
                    term_ids: [productListFilters?.transmission]
                });
            }

            if (!!productListFilters?.odometer) {
                params.filter_attributes.push({
                    attribute_slug: 'pa_odometer',
                    term_ids: [productListFilters?.odometer]
                });
            }

            if (!!productListFilters?.style) {
                params.filter_attributes.push({
                    attribute_slug: 'pa_style',
                    term_ids: [productListFilters?.style]
                });
            }

            if (!!productListFilters?.years) {
                params.filter_attributes.push({
                    attribute_slug: 'pa_years',
                    term_ids: [...productListFilters?.years]
                });
            }

            let categories = {
                attribute_slug: 'product_cat',
                term_ids: []
            }
            if (!!productListFilters?.make) {
                categories.term_ids = [productListFilters?.make];
            }
            if (!!productListFilters?.model) {
                categories.term_ids = [productListFilters?.model];
            }
            if (categories.term_ids?.length > 0) {
                params.filter_attributes.push(categories);
            }

            let req_params = {
                params: params
            }
            let token = JSON.parse(localStorage.getItem("token"));
            if (!!token) {
                req_params.headers = {
                    Authorization: `Bearer ${token}`
                }
            }

            await axios.get(ProductsApi.getProducts, req_params).then((resp) => {
                // console.log(resp.data);
                if (resp.data?.products?.length > 0) {
                    let pages = Math.ceil(resp.data?.found_posts / productListFilters.per_page);
                    setProductFilter({
                        'pages': pages,
                        'found_posts': resp.data?.found_posts,
                    });
                    let products = resp.data?.products?.map(item => {
                        return {
                            url: `/vehicle/${item.id}`,
                            img: item.img,
                            title: item.title,
                            rate: item.rate,
                            isFavorite: item.isFavorite,
                            votes: item.votes,
                            price: {
                                perDay: `<span>$${item.daily_price}</span>/day`,
                                total: null,
                            }
                        }
                    })
                    setProductsList(products);
                    return;
                }
                setProductsList([]);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    }

    const getFavoriteProducts = async () => {
        setFavoriteProducts(null);
        try {
            await axios.get(ProductsApi.getFavorites, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                },
            }).then((resp) => {
                // console.log(resp.data);
                if (resp.data?.products?.length > 0) {
                    let products = resp.data?.products?.map(item => {
                        return {
                            url: `/vehicle/${item.id}`,
                            img: item.img,
                            title: item.title,
                            rate: '4.9',
                            votes: 63,
                            isFavorite: item.isFavorite,
                            price: {
                                perDay: `<span>$${item.daily_price}</span>/day`,
                                total: null,
                            }
                        }
                    })
                    setFavoriteProducts(products);
                    return;
                }
                setFavoriteProducts([]);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    }

    const checkProductAvailability = async (useDriver) => {
        setSingleProductBookField({ 'loading': true });
        try {
            await axios.post(ProductsApi.calculateInventoryData, {
                product_id: productForView.id,
                pickup_date: singleProduct_book_date_from,
                pickup_time: singleProduct_book_time_from,
                dropoff_date: singleProduct_book_date_to,
                dropoff_time: singleProduct_book_time_to,
                useDriver: useDriver,
            }, {
                // headers: {
                //     Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                // },
                // params: { id: id }
            }).then((resp) => {
                let fields = {
                    duration_days: 0,
                    duration_hours: 0,
                    duration_daily_amount: 0,
                    duration_hourly_amount: 0,
                    delivery_amount: 0,
                    driver_days: 0,
                    driver_damount: 0,
                    total_amount: 0,
                    loading: false,
                    error: '',
                }
                // console.log(resp);
                if (!!resp?.data?.error) {
                    fields.error = "Vehicle is not available on this dates";
                } else {
                    fields.duration_daily_amount = resp?.data?.price_breakdown?.duration_breakdown?.daily;
                    fields.duration_hourly_amount = resp?.data?.price_breakdown?.duration_breakdown?.hourly;
                    fields.delivery_amount = resp?.data?.price_breakdown?.delivery_amount;
                    fields.driver_days = resp?.data?.driver_days;
                    fields.driver_amount = resp?.data?.driver_amount;
                    fields.total_amount = resp?.data?.price_breakdown?.total;
                    fields.duration_days = resp?.data?.days;
                    fields.duration_hours = resp?.data?.hours;
                }
                setSingleProductBookField(fields);
            });
        } catch (error) {
            console.log('cancelled', error);
            setSingleProductBookField({ 'loading': false });
        }
    }

    const toggleProductFavorite = async (productId, action) => {
        try {
            await axios.post(ProductsApi.toggleProductFavorite, {
                product_id: productId,
                action: action,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                },
            }).then((resp) => {
                if (!!resp?.data) {
                    let newProductForView = {
                        ...productForView,
                        isFavorite: (action == 'add' && !!resp?.data?.add) ? true : false
                    };
                    setProductForView(newProductForView);
                }
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    }

    const addReview = async (review, rate) => {
        console.log('addReview', productForView.id, rate, review);
        try {
            await axios.post(ProductsApi.addReview, {
                product_id: productForView.id,
                rate: rate,
                review: review,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                },
            }).then((resp) => {
                console.log(resp);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    }

    return (
        <Context.Provider
            value={{
                ...props,

                defaultTimeOptions,

                addProduct,
                setNewAddedProductId,
                newAddedProductId,

                getMyProducts,
                myProducts,

                getProductForEdit,
                productForEdit,
                updateProduct,

                getProductForView,
                productForView,
                toggleProductFavorite,
                addReview,

                setProductFilter,
                productListFilters,
                getFilteredProducts,
                productsList,

                setSingleProductBookField,
                singleProduct_book_date_from,
                singleProduct_book_date_to,
                singleProduct_book_time_from,
                singleProduct_book_time_to,
                singleProduct_duration_days,
                singleProduct_duration_hours,
                singleProduct_duration_daily_amount,
                singleProduct_duration_hourly_amount,
                singleProduct_delivery_amount,
                singleProduct_driver_days,
                singleProduct_driver_amount,
                singleProduct_total_amount,
                singleProduct_loading,
                singleProduct_error,
                checkProductAvailability,

                setMapProductFilter,
                mapProductListFilters,
                setMapPlacePosition,
                mapPlacePosition,
                initMapProducts,
                getMapProducts,
                mapProductsList,
                toggleMarkerActive,

                favoriteProducts,
                getFavoriteProducts,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default ProductsProvider;