import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SettingsApi } from '../../api';
import Context from './index';

const SettingsProvider = (props) => {
    const [homeFeaturedProduct, setHomeFeaturedProduct] = useState(null);

    const getHomeFeaturedProduct = async () => {
        try {
            await axios.get(SettingsApi.get_home_featured_product).then((resp) => {
                if (resp?.data?.product) {
                    let d = resp?.data?.product?.daily_price % 1;
                    let f = resp?.data?.product?.daily_price - d;
                    let data = {
                        ...resp?.data?.product,
                        price: {
                            full: f,
                            decimal: d * 100,
                        }
                    }
                    setHomeFeaturedProduct(data);
                } else {
                    setHomeFeaturedProduct(null)
                }
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    useEffect(() => {
        getHomeFeaturedProduct();
    }, [])

    return (
        <Context.Provider
            value={{
                ...props,
                homeFeaturedProduct,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default SettingsProvider;