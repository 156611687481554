import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import "../../assets/css/header-with-search.css";
import logoLight from "../../assets/img/Logo-light.png";
import closeBtn from "../../assets/img/carbon_close.png";
import ProductsContext from './../../_helper/Products/index';
import HeaderMessages from "./HeaderMessages";
import HeaderNavigationMenuOffcanvas from "./HeaderNavigationMenuOffcanvas";
import HeaderNotifications from "./HeaderNotifications";
import HeaderPlacesInput from './HeaderPlacesInput';
import HeaderProfileMenu from "./HeaderProfileMenu";
import headerMove from "../../assets/img/header-move.png"
import VerificationBanner from "../../Components/Common/VerificationBanner";

const Header = () => {
    const {
        productListFilters,
        setProductFilter,
    } = useContext(ProductsContext);
    return <>
        <VerificationBanner />
        <header className="header-with-search">
            <div className="container">
                <div className="row vehicle-header">
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <div className="flex-grow-1 d-flex header-left align-items-center">
                                <Link to="/" className="logo"><img src={logoLight} alt="utrip-logo" /></Link>
                                <div className="filters-wrap d-flex">
                                    <HeaderPlacesInput />
                                    <div className="d-flex align-items-center">
                                        {(!!productListFilters.place) && <div
                                            title="Clear"
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                marginLeft: '-15px',
                                                marginRight: '15px',
                                            }}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setProductFilter({
                                                    'book_date_from': '',
                                                    'book_time_from': '',
                                                    'book_date_to': '',
                                                    'book_time_to': '',
                                                    'lat': '',
                                                    'lng': '',
                                                    'place': '',
                                                })
                                            }}>
                                            <img src={closeBtn} className="w-100" />
                                        </div>
                                        }
                                        <Link to="/vehicle-list">
                                            <img src={headerMove} style={{ width: '42px', height: '42px' }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="header-right d-flex align-items-center">
                                <HeaderNotifications />
                                <HeaderMessages />
                                <HeaderProfileMenu />
                                {/* <HeaderNavigationMenu /> */}
                                <HeaderNavigationMenuOffcanvas />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
}

export default Header