import parse from 'html-react-parser';
import React from "react";

import { Link } from "react-router-dom";
import star from '../../assets/img/Star.png';
import { useTranslation } from "react-i18next";
const VehicleCard = ({ item }) => {
    const { t } = useTranslation();
    return (
        <Link className="vehicle" to={item.url}>
            <div className="vehicle-image">
                <img src={item.img} alt="" />
                <div className={`like-btn ${!!item.isFavorite ? 'liked' : ''}`}>
                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M10.761 18.7528C8.5904 17.4064 6.57111 15.8218 4.73929 14.0273C3.45144 12.7351 2.47101 11.1596 1.8731 9.42168C0.797144 6.05033 2.05393 2.19077 5.57112 1.04857C7.41961 0.448808 9.43845 0.791597 10.9961 1.9697C12.5543 0.793032 14.5725 0.450363 16.4211 1.04857C19.9383 2.19077 21.2041 6.05033 20.1281 9.42168C19.5302 11.1596 18.5498 12.7351 17.2619 14.0273C15.4301 15.8218 13.4108 17.4064 11.2402 18.7528L11.0051 18.9002L10.761 18.7528Z"
                            fill="#3C3C3C" fillOpacity="0.8" stroke="white" strokeWidth="1.2"
                            strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <div className="vehicle-info">
                <div className="head d-flex justify-content-between">
                    <h4>{item.title}</h4>
                    {item.rate > 0 &&
                        <div className="rate-wrapper d-flex align-items-center">
                            <img src={star} alt="" />
                            <div>
                                <span className="rate">{item.rate}</span>
                                (<span className="votes">{item.votes}</span>)
                            </div>
                        </div>
                    }
                </div>
                <div className="horizontal-divider"></div>
                <div className="foot">
                    <div className="price-wrapper d-flex">
                        <b className="per-day">{parse(item.price.perDay ?? '')}</b>
                        {!!item.price.total && (<>
                            <span className="seperator"> · </span>
                            <p className="total-price">{t("VEHICLE_CARD.total_price")}</p>
                            <span>{item.price.total}</span>
                        </>)}
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default VehicleCard;
