import React, { useEffect, useRef, useState, useContext } from "react";

import ProductsContext from "../../_helper/Products";
import MapsJsApiContext from "../../_helper/MapsJsApi";
import GooglePlacesAutocomplete, { geocode, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import DatePickerInline from "../VehicleForm/datepicker-inline";
import DatePicker from './../VehicleForm/datepicker';
import SelectMenu from './../VehicleForm/selectmenu';
import TimepickerInline from './../VehicleForm/timepicker-inline';
import Dropdown from 'react-bootstrap/Dropdown';

import locationPng from "../../assets/img/majesticons_map-marker-line.png";
import dateTimePickerPng from "../../assets/img/Group (1).png";
import searchPng from "../../assets/img/charm_search.png";
import { Link } from "react-router-dom";
import closeBtn from "../../assets/img/carbon_close.png"
import { useTranslation } from "react-i18next";

const FindCarFormMobile = () => {

    const {
        defaultTimeOptions,
        productListFilters,
        setProductFilter,

        mapProductListFilters,
        setMapProductFilter,
        setMapPlacePosition,
    } = useContext(ProductsContext);

    const {
        isLoaded,
    } = useContext(MapsJsApiContext);

    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        if (!!productListFilters?.place) {
            setSearchValue(productListFilters?.place);
            geocodeByPlaceId(productListFilters?.place?.value?.place_id)
                .then(results => {
                    console.log(results);
                    return getLatLng(results[0]);
                })
                .then(({ lat, lng }) => {
                    console.log('Successfully got latitude and longitude', `${lat}, ${lng}`);
                    setProductFilter({ 'lat': lat, 'lng': lng });
                    setMapProductFilter({ 'lat': lat, 'lng': lng });
                    setMapPlacePosition({ 'lat': lat, 'lng': lng });
                }
                );
        }
    }, [productListFilters?.place])

    const setPlaceValue = (value) => {
        setMapProductFilter({ 'place': value });
        setProductFilter({ 'place': value });
    }
    const { t } = useTranslation();

    return (
        <>
            <div className="container px-4">
                <div className="find-car d-flex flex-wrap py-4" style={{ gap: '15px' }}>
                    {!!isLoaded &&
                        <div className="w-100">
                            <div>
                                <p className="filter-title">{t("HOME_FIND_YOUR_CAR.location")}</p>
                                <div className="position-relative">
                                    <GooglePlacesAutocomplete
                                        apiOptions={{ language: 'en', region: 'AM' }}
                                        onLoadFailed={(error) => (
                                            console.error("Could not inject Google script", error)
                                        )}
                                        selectProps={{
                                            placeholder: t("HOME_FIND_YOUR_CAR.rent_location"),
                                            className: 'location p-0',
                                            value: searchValue,
                                            onChange: setPlaceValue,
                                            styles: {
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    zIndex: 101,
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: '1px solid #D8D8D8 !important',
                                                    boxShadow: 'unset!important',
                                                    borderRadius: '5px',
                                                    padding: '9px 15px 9px 30px',
                                                    backgroundImage: `url(${locationPng})`,
                                                    backgroundPosition: '15px center',
                                                    backgroundRepeat: 'no-repeat',
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    fontDamily: "'SF Pro Display', sans-serif",
                                                    fontSize: "14px",
                                                    fontWeight: "700",
                                                    lineHeight: "22px",
                                                    letterSpacing: "0px",
                                                    textAlign: "left",
                                                    color: '#666',
                                                }),
                                                indicatorsContainer: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                }),
                                            }
                                        }}
                                    />
                                    {null != mapProductListFilters.place &&
                                        <div
                                            title="Clear"
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                position: 'absolute',
                                                top: '13px',
                                                right: '10px',
                                                zIndex: '103',
                                            }}
                                            className="cursor-pointer"
                                            onClick={() => { setPlaceValue(null); setSearchValue(null); }}>
                                            <img src={closeBtn} className="w-100" />
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    }
                    <div className="w-100">
                        <div>
                            <p className="filter-title">{t("HOME_FIND_YOUR_CAR.trip_start")}</p>
                            <div className="date-input-group d-flex">
                                <DatePicker
                                    handleOnChange={(value) => { setProductFilter({ 'book_date_from': value }) }}
                                    selected={productListFilters.book_date_from}
                                    placeholder={t("HOME_FIND_YOUR_CAR.pickup_date")}
                                    className={'flex-grow-1'}
                                    max={productListFilters.book_date_to}
                                />
                                <div className="divider"></div>
                                <SelectMenu
                                    id="time-picker-from"
                                    name="time-picker-from"
                                    className={``}
                                    options={defaultTimeOptions}
                                    handleOnChange={(value) => { setProductFilter({ 'book_time_from': value }) }}
                                    selected={productListFilters.book_time_from}
                                    selectValue={t("HOME_FIND_YOUR_CAR.select_time")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <div>
                            <p className="filter-title">{t("HOME_FIND_YOUR_CAR.trip_end")}</p>
                            <div className="date-input-group d-flex" >
                                <DatePicker
                                    handleOnChange={(value) => { setProductFilter({ 'book_date_to': value }) }}
                                    selected={productListFilters.book_date_to}
                                    placeholder={t("HOME_FIND_YOUR_CAR.drop_off_date")}
                                    min={productListFilters.book_date_from}
                                    className={'flex-grow-1'}
                                />
                                <div className="divider"></div>
                                <SelectMenu
                                    id="time-picker-from"
                                    name="time-picker-from"
                                    className={``}
                                    options={defaultTimeOptions}
                                    handleOnChange={(value) => { setProductFilter({ 'book_time_to': value }) }}
                                    selected={productListFilters.book_time_to}
                                    selectValue={t("HOME_FIND_YOUR_CAR.select_time")}
                                />
                            </div>
                        </div>
                    </div>
                    <Link to="/map" className="search-link d-block user-select-none">
                        {t("HOME_FIND_YOUR_CAR.search")}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default FindCarFormMobile
