
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Seo from './../../Components/seo';
import UserContext from "../../_helper/User";
import Loading from "../../Components/Common/Loading";
import SelectMenu from './../../Components/VehicleForm/selectmenu';
import { useTranslation } from "react-i18next";
const DriverLicense = () => {
  const { t } = useTranslation();

  const days = [];
  for (let d = 1; d <= 31; d++) {
    if (d < 10) {
      days.push({ value: '0' + d, name: '0' + d });
    } else {
      days.push({ value: d, name: d });
    }
  }
  const months = [
    { value: '01', name: 'January' },
    { value: '02', name: 'February' },
    { value: '03', name: 'March' },
    { value: '04', name: 'April' },
    { value: '05', name: 'May' },
    { value: '06', name: 'June' },
    { value: '07', name: 'July' },
    { value: '08', name: 'August' },
    { value: '09', name: 'September' },
    { value: '10', name: 'October' },
    { value: '11', name: 'November' },
    { value: '12', name: 'December' },
  ];

  // for (let m = 1; m <= 12; m++) {
  //   if (m < 10) {
  //     months.push({ value: '0' + m, name: '0' + m });
  //   } else {
  //     months.push({ value: m, name: m });
  //   }
  // }
  const years = [];
  for (let y = 2005; y >= 1950; y--) {
    years.push({ value: y, name: y });
  }

  const countries = [
    { value: 'Afghanistan', name: 'Afghanistan' },
    { value: 'Åland Islands', name: 'Åland Islands' },
    { value: 'Albania', name: 'Albania' },
    { value: 'Algeria', name: 'Algeria' },
    { value: 'American Samoa', name: 'American Samoa' },
    { value: 'AndorrA', name: 'AndorrA' },
    { value: 'Angola', name: 'Angola' },
    { value: 'Anguilla', name: 'Anguilla' },
    { value: 'Antarctica', name: 'Antarctica' },
    { value: 'Antigua and Barbuda', name: 'Antigua and Barbuda' },
    { value: 'Argentina', name: 'Argentina' },
    { value: 'Armenia', name: 'Armenia' },
    { value: 'Aruba', name: 'Aruba' },
    { value: 'Australia', name: 'Australia' },
    { value: 'Austria', name: 'Austria' },
    { value: 'Azerbaijan', name: 'Azerbaijan' },
    { value: 'Bahamas', name: 'Bahamas' },
    { value: 'Bahrain', name: 'Bahrain' },
    { value: 'Bangladesh', name: 'Bangladesh' },
    { value: 'Barbados', name: 'Barbados' },
    { value: 'Belarus', name: 'Belarus' },
    { value: 'Belgium', name: 'Belgium' },
    { value: 'Belize', name: 'Belize' },
    { value: 'Benin', name: 'Benin' },
    { value: 'Bermuda', name: 'Bermuda' },
    { value: 'Bhutan', name: 'Bhutan' },
    { value: 'Bolivia', name: 'Bolivia' },
    { value: 'Bosnia and Herzegovina', name: 'Bosnia and Herzegovina' },
    { value: 'Botswana', name: 'Botswana' },
    { value: 'Bouvet Island', name: 'Bouvet Island' },
    { value: 'Brazil', name: 'Brazil' },
    { value: 'British Indian Ocean Territory', name: 'British Indian Ocean Territory' },
    { value: 'Brunei Darussalam', name: 'Brunei Darussalam' },
    { value: 'Bulgaria', name: 'Bulgaria' },
    { value: 'Burkina Faso', name: 'Burkina Faso' },
    { value: 'Burundi', name: 'Burundi' },
    { value: 'Cambodia', name: 'Cambodia' },
    { value: 'Cameroon', name: 'Cameroon' },
    { value: 'Canada', name: 'Canada' },
    { value: 'Cape Verde', name: 'Cape Verde' },
    { value: 'Cayman Islands', name: 'Cayman Islands' },
    { value: 'Central African Republic', name: 'Central African Republic' },
    { value: 'Chad', name: 'Chad' },
    { value: 'Chile', name: 'Chile' },
    { value: 'China', name: 'China' },
    { value: 'Christmas Island', name: 'Christmas Island' },
    { value: 'Cocos (Keeling) Islands', name: 'Cocos (Keeling) Islands' },
    { value: 'Colombia', name: 'Colombia' },
    { value: 'Comoros', name: 'Comoros' },
    { value: 'Congo', name: 'Congo' },
    { value: 'Congo, The Democratic Republic of the', name: 'Congo, The Democratic Republic of the' },
    { value: 'Cook Islands', name: 'Cook Islands' },
    { value: 'Costa Rica', name: 'Costa Rica' },
    { value: 'Cote D\'Ivoire', name: 'Cote D\'Ivoire' },
    { value: 'Croatia', name: 'Croatia' },
    { value: 'Cuba', name: 'Cuba' },
    { value: 'Cyprus', name: 'Cyprus' },
    { value: 'Czech Republic', name: 'Czech Republic' },
    { value: 'Denmark', name: 'Denmark' },
    { value: 'Djibouti', name: 'Djibouti' },
    { value: 'Dominica', name: 'Dominica' },
    { value: 'Dominican Republic', name: 'Dominican Republic' },
    { value: 'Ecuador', name: 'Ecuador' },
    { value: 'Egypt', name: 'Egypt' },
    { value: 'El Salvador', name: 'El Salvador' },
    { value: 'Equatorial Guinea', name: 'Equatorial Guinea' },
    { value: 'Eritrea', name: 'Eritrea' },
    { value: 'Estonia', name: 'Estonia' },
    { value: 'Ethiopia', name: 'Ethiopia' },
    { value: 'Falkland Islands (Malvinas)', name: 'Falkland Islands (Malvinas)' },
    { value: 'Faroe Islands', name: 'Faroe Islands' },
    { value: 'Fiji', name: 'Fiji' },
    { value: 'Finland', name: 'Finland' },
    { value: 'France', name: 'France' },
    { value: 'French Guiana', name: 'French Guiana' },
    { value: 'French Polynesia', name: 'French Polynesia' },
    { value: 'French Southern Territories', name: 'French Southern Territories' },
    { value: 'Gabon', name: 'Gabon' },
    { value: 'Gambia', name: 'Gambia' },
    { value: 'Georgia', name: 'Georgia' },
    { value: 'Germany', name: 'Germany' },
    { value: 'Ghana', name: 'Ghana' },
    { value: 'Gibraltar', name: 'Gibraltar' },
    { value: 'Greece', name: 'Greece' },
    { value: 'Greenland', name: 'Greenland' },
    { value: 'Grenada', name: 'Grenada' },
    { value: 'Guadeloupe', name: 'Guadeloupe' },
    { value: 'Guam', name: 'Guam' },
    { value: 'Guatemala', name: 'Guatemala' },
    { value: 'Guernsey', name: 'Guernsey' },
    { value: 'Guinea', name: 'Guinea' },
    { value: 'Guinea-Bissau', name: 'Guinea-Bissau' },
    { value: 'Guyana', name: 'Guyana' },
    { value: 'Haiti', name: 'Haiti' },
    { value: 'Heard Island and Mcdonald Islands', name: 'Heard Island and Mcdonald Islands' },
    { value: 'Holy See (Vatican City State)', name: 'Holy See (Vatican City State)' },
    { value: 'Honduras', name: 'Honduras' },
    { value: 'Hong Kong', name: 'Hong Kong' },
    { value: 'Hungary', name: 'Hungary' },
    { value: 'Iceland', name: 'Iceland' },
    { value: 'India', name: 'India' },
    { value: 'Indonesia', name: 'Indonesia' },
    { value: 'Iran, Islamic Republic Of', name: 'Iran, Islamic Republic Of' },
    { value: 'Iraq', name: 'Iraq' },
    { value: 'Ireland', name: 'Ireland' },
    { value: 'Isle of Man', name: 'Isle of Man' },
    { value: 'Israel', name: 'Israel' },
    { value: 'Italy', name: 'Italy' },
    { value: 'Jamaica', name: 'Jamaica' },
    { value: 'Japan', name: 'Japan' },
    { value: 'Jersey', name: 'Jersey' },
    { value: 'Jordan', name: 'Jordan' },
    { value: 'Kazakhstan', name: 'Kazakhstan' },
    { value: 'Kenya', name: 'Kenya' },
    { value: 'Kiribati', name: 'Kiribati' },
    { value: 'Korea, Democratic People\'S Republic of', name: 'Korea, Democratic People\'S Republic of' },
    { value: 'Korea, Republic of', name: 'Korea, Republic of' },
    { value: 'Kuwait', name: 'Kuwait' },
    { value: 'Kyrgyzstan', name: 'Kyrgyzstan' },
    { value: 'Lao People\'S Democratic Republic', name: 'Lao People\'S Democratic Republic' },
    { value: 'Latvia', name: 'Latvia' },
    { value: 'Lebanon', name: 'Lebanon' },
    { value: 'Lesotho', name: 'Lesotho' },
    { value: 'Liberia', name: 'Liberia' },
    { value: 'Libyan Arab Jamahiriya', name: 'Libyan Arab Jamahiriya' },
    { value: 'Liechtenstein', name: 'Liechtenstein' },
    { value: 'Lithuania', name: 'Lithuania' },
    { value: 'Luxembourg', name: 'Luxembourg' },
    { value: 'Macao', name: 'Macao' },
    { value: 'Macedonia, The Former Yugoslav Republic of', name: 'Macedonia, The Former Yugoslav Republic of' },
    { value: 'Madagascar', name: 'Madagascar' },
    { value: 'Malawi', name: 'Malawi' },
    { value: 'Malaysia', name: 'Malaysia' },
    { value: 'Maldives', name: 'Maldives' },
    { value: 'Mali', name: 'Mali' },
    { value: 'Malta', name: 'Malta' },
    { value: 'Marshall Islands', name: 'Marshall Islands' },
    { value: 'Martinique', name: 'Martinique' },
    { value: 'Mauritania', name: 'Mauritania' },
    { value: 'Mauritius', name: 'Mauritius' },
    { value: 'Mayotte', name: 'Mayotte' },
    { value: 'Mexico', name: 'Mexico' },
    { value: 'Micronesia, Federated States of', name: 'Micronesia, Federated States of' },
    { value: 'Moldova, Republic of', name: 'Moldova, Republic of' },
    { value: 'Monaco', name: 'Monaco' },
    { value: 'Mongolia', name: 'Mongolia' },
    { value: 'Montserrat', name: 'Montserrat' },
    { value: 'Morocco', name: 'Morocco' },
    { value: 'Mozambique', name: 'Mozambique' },
    { value: 'Myanmar', name: 'Myanmar' },
    { value: 'Namibia', name: 'Namibia' },
    { value: 'Nauru', name: 'Nauru' },
    { value: 'Nepal', name: 'Nepal' },
    { value: 'Netherlands', name: 'Netherlands' },
    { value: 'Netherlands Antilles', name: 'Netherlands Antilles' },
    { value: 'New Caledonia', name: 'New Caledonia' },
    { value: 'New Zealand', name: 'New Zealand' },
    { value: 'Nicaragua', name: 'Nicaragua' },
    { value: 'Niger', name: 'Niger' },
    { value: 'Nigeria', name: 'Nigeria' },
    { value: 'Niue', name: 'Niue' },
    { value: 'Norfolk Island', name: 'Norfolk Island' },
    { value: 'Northern Mariana Islands', name: 'Northern Mariana Islands' },
    { value: 'Norway', name: 'Norway' },
    { value: 'Oman', name: 'Oman' },
    { value: 'Pakistan', name: 'Pakistan' },
    { value: 'Palau', name: 'Palau' },
    { value: 'Palestinian Territory, Occupied', name: 'Palestinian Territory, Occupied' },
    { value: 'Panama', name: 'Panama' },
    { value: 'Papua New Guinea', name: 'Papua New Guinea' },
    { value: 'Paraguay', name: 'Paraguay' },
    { value: 'Peru', name: 'Peru' },
    { value: 'Philippines', name: 'Philippines' },
    { value: 'Pitcairn', name: 'Pitcairn' },
    { value: 'Poland', name: 'Poland' },
    { value: 'Portugal', name: 'Portugal' },
    { value: 'Puerto Rico', name: 'Puerto Rico' },
    { value: 'Qatar', name: 'Qatar' },
    { value: 'Reunion', name: 'Reunion' },
    { value: 'Romania', name: 'Romania' },
    { value: 'Russian Federation', name: 'Russian Federation' },
    { value: 'RWANDA', name: 'RWANDA' },
    { value: 'Saint Helena', name: 'Saint Helena' },
    { value: 'Saint Kitts and Nevis', name: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', name: 'Saint Lucia' },
    { value: 'Saint Pierre and Miquelon', name: 'Saint Pierre and Miquelon' },
    { value: 'Saint Vincent and the Grenadines', name: 'Saint Vincent and the Grenadines' },
    { value: 'Samoa', name: 'Samoa' },
    { value: 'San Marino', name: 'San Marino' },
    { value: 'Sao Tome and Principe', name: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', name: 'Saudi Arabia' },
    { value: 'Senegal', name: 'Senegal' },
    { value: 'Serbia and Montenegro', name: 'Serbia and Montenegro' },
    { value: 'Seychelles', name: 'Seychelles' },
    { value: 'Sierra Leone', name: 'Sierra Leone' },
    { value: 'Singapore', name: 'Singapore' },
    { value: 'Slovakia', name: 'Slovakia' },
    { value: 'Slovenia', name: 'Slovenia' },
    { value: 'Solomon Islands', name: 'Solomon Islands' },
    { value: 'Somalia', name: 'Somalia' },
    { value: 'South Africa', name: 'South Africa' },
    { value: 'South Georgia and the South Sandwich Islands', name: 'South Georgia and the South Sandwich Islands' },
    { value: 'Spain', name: 'Spain' },
    { value: 'Sri Lanka', name: 'Sri Lanka' },
    { value: 'Sudan', name: 'Sudan' },
    { value: 'Suriname', name: 'Suriname' },
    { value: 'Svalbard and Jan Mayen', name: 'Svalbard and Jan Mayen' },
    { value: 'Swaziland', name: 'Swaziland' },
    { value: 'Sweden', name: 'Sweden' },
    { value: 'Switzerland', name: 'Switzerland' },
    { value: 'Syrian Arab Republic', name: 'Syrian Arab Republic' },
    { value: 'Taiwan, Province of China', name: 'Taiwan, Province of China' },
    { value: 'Tajikistan', name: 'Tajikistan' },
    { value: 'Tanzania, United Republic of', name: 'Tanzania, United Republic of' },
    { value: 'Thailand', name: 'Thailand' },
    { value: 'Timor-Leste', name: 'Timor-Leste' },
    { value: 'Togo', name: 'Togo' },
    { value: 'Tokelau', name: 'Tokelau' },
    { value: 'Tonga', name: 'Tonga' },
    { value: 'Trinidad and Tobago', name: 'Trinidad and Tobago' },
    { value: 'Tunisia', name: 'Tunisia' },
    { value: 'Turkey', name: 'Turkey' },
    { value: 'Turkmenistan', name: 'Turkmenistan' },
    { value: 'Turks and Caicos Islands', name: 'Turks and Caicos Islands' },
    { value: 'Tuvalu', name: 'Tuvalu' },
    { value: 'Uganda', name: 'Uganda' },
    { value: 'Ukraine', name: 'Ukraine' },
    { value: 'United Arab Emirates', name: 'United Arab Emirates' },
    { value: 'United Kingdom', name: 'United Kingdom' },
    { value: 'United States', name: 'United States' },
    { value: 'United States Minor Outlying Islands', name: 'United States Minor Outlying Islands' },
    { value: 'Uruguay', name: 'Uruguay' },
    { value: 'Uzbekistan', name: 'Uzbekistan' },
    { value: 'Vanuatu', name: 'Vanuatu' },
    { value: 'Venezuela', name: 'Venezuela' },
    { value: 'Viet Nam', name: 'Viet Nam' },
    { value: 'Virgin Islands, British', name: 'Virgin Islands, British' },
    { value: 'Virgin Islands, U.S.', name: 'Virgin Islands, U.S.' },
    { value: 'Wallis and Futuna', name: 'Wallis and Futuna' },
    { value: 'Western Sahara', name: 'Western Sahara' },
    { value: 'Yemen', name: 'Yemen' },
    { value: 'Zambia', name: 'Zambia' },
    { value: 'Zimbabwe', name: 'Zimbabwe' }
  ]

  const {
    updateDriverLicense,
    driverLicense,
    getCurrentUserData,
  } = useContext(UserContext);

  useEffect(() => {
    getCurrentUserData()
  }, [])

  const [invalid, setInvalid] = useState([]);
  const [frontImage, setFrontImage] = useState(null);
  const [frontImageChanged, setFrontImageChanged] = useState(false);
  const [backImage, setBackImage] = useState(null);
  const [backImageChanged, setBackImageChanged] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [licenseDobDay, setLicenseDobDay] = React.useState('');
  const [licenseDobMonth, setLicenseDobMonth] = React.useState('');
  const [licenseDobYear, setLicenseDobYear] = React.useState('');

  const [region, setRegion] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [verification, setVerification] = useState('');
  const [verificationColor, setVerificationColor] = useState('yellow');

  useEffect(() => {
    if (null == driverLicense) {
      setLicenseLoading(true);
    } else {
      setLicenseLoading(false);
      setFirstName(driverLicense?.first_name);
      setLastName(driverLicense?.last_name);
      setRegion(driverLicense?.region);
      setLicenseNumber(driverLicense?.number);
      setFrontImage(driverLicense?.frontImageUrl);
      setFrontImageChanged(false);
      setBackImage(driverLicense?.backImageUrl);
      setBackImageChanged(false);

      setLicenseDobDay(driverLicense?.licenseDobDay);
      setLicenseDobMonth(driverLicense?.licenseDobMonth);
      setLicenseDobYear(driverLicense?.licenseDobYear);

      switch (driverLicense?.verified) {
        case 'on_verification':
          setVerification('On Verification');
          setVerificationColor('yellow');
          break;
        case 'verified':
          setVerification('Verified');
          setVerificationColor('green');
          break;
        case 'rejected':
          setVerification('Rejected');
          setVerificationColor('red');
          break;
        default:
          break;
      }
    }
  }, [driverLicense])

  const selectFrontFile = async (event) => {
    let url = URL.createObjectURL(event.target.files[0])
    let img = new Image();
    let type = event.target.files[0].type
    await new Promise((resolve, reject) => {
      img.onload = function () {
        let canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        let ctx = canvas.getContext('2d')

        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          img.width,
          img.height
        )
        let base64Image = canvas.toDataURL(type, 1);
        setFrontImage(base64Image);
        setFrontImageChanged(true);
        resolve();
      }
      img.src = url;
    })
  };

  const selectBackFile = async (event) => {
    let url = URL.createObjectURL(event.target.files[0])
    let img = new Image();
    let type = event.target.files[0].type
    await new Promise((resolve, reject) => {
      img.onload = function () {
        let canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        let ctx = canvas.getContext('2d')

        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          img.width,
          img.height
        )
        let base64Image = canvas.toDataURL(type, 1);
        setBackImage(base64Image);
        setBackImageChanged(true);
        resolve();
      }
      img.src = url;
    })
  };

  const setFieldInvalid = (newInvalids, key, valid = false) => {
    if (!!valid) {
      newInvalids = newInvalids.filter(item => item != key);
    } else {
      newInvalids.push(key);
    }
    return newInvalids;
  }

  const [loading, setLoading] = useState(false);
  const [licenseLoading, setLicenseLoading] = useState(false);


  const inputStyle = {
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
    padding: '8px 15px',
  }

  const isFormValid = () => {
    let newInvalids = [...invalid];
    let valid = true;
    if (!!firstName) {
      newInvalids = setFieldInvalid(newInvalids, 'firstName', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'firstName', false);
      valid = false;
    }
    if (!!lastName) {
      newInvalids = setFieldInvalid(newInvalids, 'lastName', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'lastName', false);
      valid = false;
    }

    if (!!licenseDobDay) {
      newInvalids = setFieldInvalid(newInvalids, 'licenseDobDay', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'licenseDobDay', false);
      valid = false;
    }
    if (!!licenseDobMonth) {
      newInvalids = setFieldInvalid(newInvalids, 'licenseDobMonth', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'licenseDobMonth', false);
      valid = false;
    }
    if (!!licenseDobYear) {
      newInvalids = setFieldInvalid(newInvalids, 'licenseDobYear', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'licenseDobYear', false);
      valid = false;
    }

    if (!!region) {
      newInvalids = setFieldInvalid(newInvalids, 'region', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'region', false);
      valid = false;
    }
    if (!!licenseNumber) {
      newInvalids = setFieldInvalid(newInvalids, 'licenseNumber', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'licenseNumber', false);
      valid = false;
    }

    if (!!frontImage) {
      newInvalids = setFieldInvalid(newInvalids, 'frontImage', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'frontImage', false);
      valid = false;
    }
    if (!!backImage) {
      newInvalids = setFieldInvalid(newInvalids, 'backImage', true);
    } else {
      newInvalids = setFieldInvalid(newInvalids, 'backImage', false);
      valid = false;
    }
    setInvalid(newInvalids);
    return valid;

  }

  const handleOnSubmit = async () => {
    setLoading(true);
    let valid = isFormValid();
    if (valid) {

      let payload = {
        front_image: frontImage,
        front_image_changed: frontImageChanged,
        back_image: backImage,
        back_image_changed: backImageChanged,
        first_name: firstName,
        last_name: lastName,
        region: region,
        number: licenseNumber,
        licenseDobDay: licenseDobDay,
        licenseDobMonth: licenseDobMonth,
        licenseDobYear: licenseDobYear,
      }
      let res = await updateDriverLicense(payload);
      setLoading(false);
      if (true == res) {
        toast.success(t("DRIVER_LICENSE.driver_license_updated"));
      } else {
        toast.error(t("DRIVER_LICENSE.went_wrong"));
      }
    } else {
      toast.error(t("DRIVER_LICENSE.required_fields_are_empty"));
      setLoading(false);
    }

  }
  return (
    <Fragment>
      <Seo title={'Driver License'} schema={{}} />
      <section className="author-page-section login-and-security active-section" section-name="login-&-security">
        <div className="info-wrapper">
          <h2>{t("DRIVER_LICENSE.driver_license")} {!!verification && <span className={`custom-pill ${verificationColor}`}>{verification}</span>}</h2>
        </div>
        <div className="container">

          {licenseLoading
            ? <div className="d-flex align-items-center justify-content-center my-5">
              <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
            </div>
            :
            <div className="row">
              <div className="col-xl-10">
                <div className="row pt-3">
                  <div className="col-lg-6 ">
                    <h3 className="mb-1 mt-2">{t("DRIVER_LICENSE.first_name")}</h3>
                    <input type="text" style={inputStyle} className={`form-control ${invalid.includes('firstName') ? 'invalid' : ''}`} value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                  </div>
                  <div className="col-lg-6 mt-3 mt-lg-0">
                    <h3 className="mb-1 mt-2">{t("DRIVER_LICENSE.last_name")}</h3>
                    <input type="text" style={inputStyle} className={`form-control ${invalid.includes('lastName') ? 'invalid' : ''}`} value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                  </div>
                  <div className="col-12 mb-3 mt-2">
                    {t("DRIVER_LICENSE.enter_your_name_exactly")}
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <h6 className="mb-2 col-12">{t("DRIVER_LICENSE.date_of_birth")}</h6>
                      <div className="col-md-4 d-flex justify-content-start">
                        <SelectMenu
                          id="date-day"
                          name="license-dob-day"
                          className={`car-license-dob-day ${invalid.includes('licenseDobDay') ? 'invalid' : ''}`}
                          options={days}
                          handleOnChange={setLicenseDobDay}
                          selected={licenseDobDay}
                          selectValue={'Day'}
                        />
                      </div>
                      <div className="col-md-4 d-flex justify-content-start justify-content-md-center">
                        <SelectMenu
                          id="date-month"
                          name="license-dob-month"
                          className={`car-license-dob-month ${invalid.includes('licenseDobMonth') ? 'invalid' : ''}`}
                          options={months}
                          handleOnChange={setLicenseDobMonth}
                          selected={licenseDobMonth}
                          selectValue={'Month'}
                        />
                      </div>
                      <div className="col-md-4 d-flex justify-content-start justify-content-md-end">
                        <SelectMenu
                          id="date-year"
                          name="license-dob-year"
                          className={`car-license-dob-year ${invalid.includes('licenseDobYear') ? 'invalid' : ''}`}
                          options={years}
                          handleOnChange={setLicenseDobYear}
                          selected={licenseDobYear}
                          selectValue={'Year'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3"></div>
                  <div className="col-lg-6 mb-3">
                    <h3 className="mb-1 mt-2">{t("DRIVER_LICENSE.issuing_country")}</h3>
                    <SelectMenu
                      id="date-year"
                      name="license-region"
                      className={` ${invalid.includes('region') ? 'invalid' : ''}`}
                      options={countries}
                      handleOnChange={setRegion}
                      selected={region}
                      selectValue={'Country'}
                    />
                    {/* <input type="text" style={inputStyle} className={`form-control ${invalid.includes('region') ? 'invalid' : ''}`} value={region} onChange={(e) => { setRegion(e.target.value) }} /> */}
                  </div>
                  <div className="col-lg-6 mb-3">
                    <h3 className="mb-1 mt-2">{t("DRIVER_LICENSE.license_number")}</h3>
                    <input type="text" style={inputStyle} className={`form-control ${invalid.includes('licenseNumber') ? 'invalid' : ''}`} value={licenseNumber} onChange={(e) => { setLicenseNumber(e.target.value) }} />
                  </div>
                  <div className="col-12">
                    <div className="info-wrapper mb-3"></div>
                   {t("DRIVER_LICENSE.info")}
                  </div>
                  <div className="col-lg-6 mb-3">
                    <h3 className="mb-1 mt-2">{t("DRIVER_LICENSE.front_image")}</h3>
                    {!!frontImage
                      ? <div className="position-relative">
                        <img className="preview mw-100" src={frontImage} />
                        <div className="close-btn start-0" style={{}} onClick={() => { setFrontImage(null); }}></div>
                      </div>
                      : <>
                        <label className="save-btn cursor-pointer">
                          <input js-valid="true" type="file" accept="image/*" onChange={selectFrontFile} className="d-none" />
                          {t("DRIVER_LICENSE.select_image")}
                        </label>
                        {invalid.includes('frontImage') ? <b className="d-block text-danger">{t("DRIVER_LICENSE.front_image_is_mandatory")}</b> : ''}
                      </>
                    }
                  </div>
                  <div className="col-lg-6 mb-3">
                    <h3 className="mb-1 mt-2">{t("DRIVER_LICENSE.back_image")}</h3>
                    {!!backImage
                      ? <div className="position-relative">
                        <img className="preview mw-100" src={backImage} />
                        <div className="close-btn start-0" style={{}} onClick={() => { setBackImage(null); }}></div>
                      </div>
                      : <>
                        <label className="save-btn cursor-pointer">
                          <input js-valid="true" type="file" accept="image/*" onChange={selectBackFile} className="d-none" />
                          {t("DRIVER_LICENSE.select_image")}
                        </label>
                        {invalid.includes('backImage') ? <b className="d-block text-danger">{t("DRIVER_LICENSE.back_image_is_mandatory")}</b> : ''}
                      </>
                    }
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-center">
                      <button className="save-btn" disabled={loading} type="button" onClick={handleOnSubmit}>{t("DRIVER_LICENSE.save")}</button>
                      {loading == true
                        ? <div className="d-inline-block">
                          <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                        </div>
                        : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    </Fragment >
  );
}

export default DriverLicense;
