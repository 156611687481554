import React, { Fragment, useContext, useState } from "react";
import FindCarForm from "../Components/Home/FindCarForm";
import HomeFeaturedCar from "../Components/Home/HomeFeaturedCar";
import HomeSlider from "../Components/Home/HomeSlider";
import HeaderHome from "../Layout/Header/HeaderHome";
import Header2 from "../Layout/Header/Header2";
import Seo from './../Components/seo';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

import "../assets/css/custom.css";
import "../assets/css/home.css";
import "../assets/css/home-map.css"
import "../assets/css/home-mobile.css";
import "../assets/css/style.css";
import MapsJsApiContext from "../_helper/MapsJsApi";
import ProductsContext from "../_helper/Products";
import { useEffect } from "react";

import markerPng from "../assets/img/marker.png"
import markerAvticePng from "../assets/img/marker-active.png"
import { Link } from "react-router-dom";
import FindCarFormMobile from "../Components/Home/FindCarFormMobile";
import ChatPopup from "../Layout/Header/ChatPopup";


const HomeMap = () => {

  const {
    isLoaded,
    mapStyles,
  } = useContext(MapsJsApiContext);

  const {
    initMapProducts,
    setMapProductFilter,
    mapProductListFilters,
    mapPlacePosition,
    mapProductsList,
    toggleMarkerActive,
    getMapProducts,
  } = useContext(ProductsContext);

  const containerStyle = {
    width: '100%',
    height: 'inherit'
  };
  const [center, setCenter] = useState({
    lat: parseFloat(40.181616),
    lng: parseFloat(44.513498),
  });

  useEffect(() => {
    initMapProducts();
  }, [])

  useEffect(() => {
    if (null != mapProductListFilters.place) {
      setCenter({
        lat: parseFloat(mapPlacePosition.lat),
        lng: parseFloat(mapPlacePosition.lng),
      })
    }
  }, [mapProductListFilters.place])

  useEffect(() => {
    if (null != gmap) {
      gmap.setCenter(center);
    }
  }, [center])

  const [gmap, setGmap] = useState(null)

  const onLoad = React.useCallback(function callback(mapObj) {
    setGmap(mapObj);

  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setGmap(null)
  }, [])

  const handleDragEnd = React.useCallback(function callback() {
    // console.log('handleDragEnd', gmap);
    if (null != gmap) {
      let newCenter = gmap.getCenter();
      // console.log(newCenter, newCenter.lat(), newCenter.lng());
      setMapProductFilter({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      })
    }
  }, [gmap])

  useEffect(() => {
    if (!!mapProductListFilters.lat && !!mapProductListFilters.lng) {
      getMapProducts();
    }
  }, [
    mapProductListFilters.lat,
    mapProductListFilters.lng
  ])

  // useEffect(() => {
  //   console.log(mapProductsList);
  // }, [mapProductsList])

  // const [markers, setMarkers] = useState({});

  // const toggleMarkerActive = (key, active) => {
  //   let newMarkers = { ...markers }
  //   newMarkers[key] = {
  //     ...markers[key],
  //     isActive: active
  //   }
  //   setMarkers(newMarkers);
  // }


  return (
    <Fragment>
      <Seo title={'Map'} schema={{}} />
      <div className="d-none d-lg-block">
        <HeaderHome />
      </div>
      <div className="d-lg-none">
        <Header2 />
      </div>
      <div className="home d-flex">
        <HomeSlider classes="d-none d-lg-block" />
        <section className="homepage-map-section justify-content-center d-flex flex-column">
          <div className="d-flex flex-column flex-grow-1">
            <div id="map">
              {(isLoaded && center != null) && <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onDragEnd={handleDragEnd}
                options={
                  {
                    disableAutoPan: true,
                    disableDefaultUI: true,
                    mapTypeControlOptions: {
                      mapTypeIds: ['custom_style']
                    },
                    clickable: false,
                    mapTypeId: 'roadmap',
                    styles: mapStyles,
                  }
                }
              >
                {
                  Object.keys(mapProductsList).map((marker, key) => {
                    return <Marker
                      key={key}
                      position={mapProductsList[marker].position}
                      options={{
                        icon: mapProductsList[marker].isActive ? markerAvticePng : markerPng,
                      }}
                      onClick={() => { toggleMarkerActive(marker, !mapProductsList[marker].isActive) }}
                    >
                      {mapProductsList[marker].isActive &&
                        <InfoWindow
                          position={mapProductsList[marker].position}
                          options={{
                            maxWidth: 180,
                            minWidth: 180,
                            enableCloseButton: false,
                          }} >
                          <div className="marker-popup">
                            {!!mapProductsList[marker].title && <h5 className="title">{mapProductsList[marker].title}</h5>}
                            {!!mapProductsList[marker].address && <span className="description">{mapProductsList[marker].address}</span>}
                            {!!mapProductsList[marker].url && <Link className="select-btn" to={mapProductsList[marker].url}>More</Link>}
                          </div>
                        </InfoWindow>
                      }
                    </Marker>
                  })
                }
                {/* <Marker
                  position={center}
                  options={{
                    icon: markerPng,
                  }}
                >
                  <InfoWindow
                    position={center}
                    options={{
                      width: 180,
                      enableCloseButton: false,
                    }} >
                    <div className="marker-popup">
                      <h5 className="title">title</h5>
                      <span className="description">description</span>
                      <button className="select-btn">button</button>
                    </div>
                  </InfoWindow>
                </Marker> */}
                { /* Child components, such as markers, info windows, etc. */}
              </GoogleMap>}
            </div>
          </div>

          <div className="rent-footer d-none d-lg-flex">
            {/* {JSON.stringify(mapProductsList)} */}
            <HomeFeaturedCar />
          </div>
        </section>
        <div className="d-flex d-lg-none w-100">
          <FindCarFormMobile />
        </div>
      </div>
      <ChatPopup />

    </Fragment>
  );
}

export default HomeMap;
