import React, { useContext, useEffect, useState } from "react";

import ProductsContext from './../../_helper/Products/index';
import AttributesContext from "../../_helper/Attributes";
import SelectMenu from "../VehicleForm/selectmenu";
import { useTranslation } from "react-i18next";

const VehicleListSidebar = () => {

    const {
        setProductFilter,
        productListFilters,
        getFilteredProducts,
    } = useContext(ProductsContext);

    const {
        getAllAttributes,
        pa_categories,
        pa_years,
        // pa_makes,
        pa_odometer,
        pa_transmission,
        pa_style,
    } = useContext(AttributesContext);

    const [modelOptions, setModelOptions] = React.useState([]);
    useEffect(() => {
        if (!!productListFilters.make) {
            let makeObj = pa_categories?.find(item => item.term_id == productListFilters.make);
            if (!!makeObj) {
                let models = makeObj?.models?.map(item => { return { ...item } });
                if (undefined == models?.find(item => item.term_id == productListFilters.model)) {
                    setProductFilter({'model': ''});
                }
                if (models?.length > 0) {
                    setModelOptions(models);
                    return;
                }
            }
        }
        setModelOptions([]);
    }, [productListFilters.make, pa_categories]);

    useEffect(() => {
        getAllAttributes();
    }, []);

    const [invalid, setInvalid] = React.useState([]);

    const getAttributeValues = (attr) => {
        return attr?.map(y => {
            return { value: y.term_id, name: y.term_name }
        })
    }

    const [rangeTrackSryle, setRangeTrackSryle] = useState({
        background: ''
    })

    useEffect(() => {
        if (!!productListFilters.year_from && !!productListFilters.year_to) {
            let sliderMinValue = productListFilters.year_min;
            let sliderMaxValue = productListFilters.year_max;

            let slider_line = sliderMaxValue - sliderMinValue;
            let percent1 = ((productListFilters.year_from - sliderMinValue) / slider_line) * 100;
            let percent2 = ((productListFilters.year_to - sliderMinValue) / slider_line) * 100;
            setRangeTrackSryle({ background: `linear-gradient(to right, #D8D8D8 ${percent1}% , #F8CC0D ${percent1}% , #F8CC0D ${percent2}%, #D8D8D8 ${percent2}%)` })
        }
    }, [productListFilters.year_from, productListFilters.year_to])

    useEffect(() => {
        if (!!productListFilters.year_from && !!productListFilters.year_to && pa_years?.length) {
            let value = pa_years?.filter((item) => {
                return (Number(item.term_name) >= productListFilters.year_from && Number(item.term_name) <= productListFilters.year_to);
            })?.map(item => item.term_id);
            setProductFilter({'years': value});
        }
    }, [productListFilters.year_from, productListFilters.year_to, pa_years])

    const { t } = useTranslation();
    return (
        <>
            <h3>{t("FILTER_SIDEBAR.filters")}</h3>
            <div className="filters-form">
                {/* <div className="c-input-group">
                    <label htmlFor="delivery-to-addres">Delivery<button
                        className="clear-input d-none" type="button"
                        data-clear-input="delivery-to-addres"></button>
                    </label>
                    <select name="delivery-to-addres" defaultValue={""} onChange={(e) => { }} >
                        <option value="" disabled hidden>- Select -</option>
                        <option value="Delivery to adress">Delivery to adress</option>
                    </select>
                </div> */}
                <div className="c-input-group">
                    <label htmlFor="transmission">{t("FILTER_SIDEBAR.transmission")}
                        {!!productListFilters.transmission && (
                            <button className="clear-input" type="button" onClick={() => { setProductFilter({'transmission': ''}) }}></button>
                        )}
                    </label>
                    <SelectMenu
                        id="transmission"
                        name="transmission"
                        className={``}
                        options={getAttributeValues(pa_transmission)}
                        handleOnChange={(value) => { setProductFilter({'transmission': value}) }}
                        selected={productListFilters.transmission}
                    />
                </div>
                <div className="c-input-group">
                    <label htmlFor="vehicle-haulage">{t("FILTER_SIDEBAR.vehicle_mileage")}
                        {!!productListFilters.odometer && (
                            <button className="clear-input" type="button" onClick={() => { setProductFilter({'odometer': ''}) }}></button>
                        )}
                    </label>
                    <SelectMenu
                        id="odometer"
                        name="odometer"
                        className={``}
                        options={getAttributeValues(pa_odometer)}
                        handleOnChange={(value) => { setProductFilter({'odometer': value}) }}
                        selected={productListFilters.odometer}
                    />
                </div>
                <div className="c-input-group">
                    <label>{t("FILTER_SIDEBAR.vehicle_years")}<button className="clear-input d-none" type="button"
                        data-clear-input="vehicle-years"></button></label>
                    <div className="car-year-wrapper">
                        <div className="values d-flex">
                            <span id="range1">
                                {productListFilters.year_from}
                            </span>
                            <span>-</span>
                            <span id="range2">
                                {productListFilters.year_to}
                            </span>
                        </div>
                        <div className="slider-track" style={rangeTrackSryle}></div>
                        <input type="range" min={productListFilters.year_min} max={productListFilters.year_max} value={productListFilters.year_from} id="slider-1" onChange={(e) => { if (e.target.value < productListFilters.year_to) { setProductFilter({'year_from': e.target.value}) } }} />
                        <input type="range" min={productListFilters.year_min} max={productListFilters.year_max} value={productListFilters.year_to} id="slider-2" onChange={(e) => { if (e.target.value > productListFilters.year_from) { setProductFilter({'year_to': e.target.value}) } }} />
                    </div>
                </div>
                {/* <div className="c-input-group">
                    <label htmlFor="number-of-seats">Number of seats<button
                        className="clear-input d-none" type="button"
                        data-clear-input="number-of-seats"></button></label>
                    <select name="number-of-seats" defaultValue={""} onChange={(e) => { }}>
                        <option value="" disabled hidden>- Select -</option>
                        <option value="heneral-haulage">General Haulage</option>
                        <option value="heavy-haulage">Heavy Haulage</option>
                    </select>
                </div> */}
                <div className="c-input-group">
                    <label htmlFor="vehicle-types">{t("FILTER_SIDEBAR.vehicle_types")}
                        {!!productListFilters.style && (
                            <button className="clear-input" type="button" onClick={() => { setProductFilter({'style': ''}) }}></button>
                        )}
                    </label>
                    <SelectMenu
                        id="style"
                        name="style"
                        className={``}
                        options={getAttributeValues(pa_style)}
                        handleOnChange={(value) => { setProductFilter({'style': value}) }}
                        selected={productListFilters.style}
                    />

                </div>
                <div className="c-input-group">
                    <label htmlFor="vehicle-makes">{t("FILTER_SIDEBAR.vehicle_makes")}
                        {!!productListFilters.make && (
                            <button className="clear-input" type="button" onClick={() => { setProductFilter({'make': '', 'model': ''}) }}></button>
                        )}
                    </label>
                    <SelectMenu
                        id="make"
                        name="make"
                        className={``}
                        options={getAttributeValues(pa_categories)}
                        handleOnChange={(value) => { setProductFilter({'make': value, 'model': ''}) }}
                        selected={productListFilters.make}
                    />
                </div>
                {modelOptions?.length > 0 && (
                    <div className="c-input-group">
                        <label htmlFor="vehicle-makes">{t("FILTER_SIDEBAR.vehicle_model")}
                            {!!productListFilters.model && (
                                <button className="clear-input" type="button" onClick={() => { setProductFilter({'model': ''}) }}></button>
                            )}
                        </label>

                        <SelectMenu
                            id="model"
                            name="model"
                            className={`car-model`}
                            options={getAttributeValues(modelOptions)}
                            handleOnChange={(value) => { setProductFilter({'model': value}) }}
                            selected={productListFilters.model}
                        />
                    </div>
                )}
                {/* <div className="c-input-group">
                    <label htmlFor="owner-rating">Owner rating<button className="clear-input d-none"
                        type="button" data-clear-input="owner-rating"></button></label>
                    <select name="owner-rating" defaultValue={""} onChange={(e) => { }}>
                        <option value="" disabled hidden>- Select -</option>
                        <option value="heneral-haulage">General Haulage</option>
                        <option value="heavy-haulage">Heavy Haulage</option>
                    </select>
                </div>
                <div className="c-input-group">
                    <label htmlFor="body-color">Body color<button className="clear-input d-none"
                        type="button" data-clear-input="body-color"></button></label>
                    <select name="body-color" defaultValue={""} onChange={(e) => { }}>
                        <option value="" disabled hidden>- Select -</option>
                        <option value="heneral-haulage">General Haulage</option>
                        <option value="heavy-haulage">Heavy Haulage</option>
                    </select>
                </div> */}

                <button className="next-but" onClick={() => { getFilteredProducts(1, 0) }}>{t("FILTER_SIDEBAR.filter")}</button>
            </div>
        </>
    )
}

export default VehicleListSidebar;