
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../../_helper/User";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Loading from "../../../Components/Common/Loading";

const WithdrawFundsBlock = () => {

    const [card_holder, setCardHolder] = useState('');
    const [card_number, setCardNumber] = useState('');
    const [expiration, setExpiration] = useState('');
    const [fundType, setFundType] = useState('');
    const expirationChange = (event) => {
        setExpiration(event.target.value);
    }
    const cardNumberChange = (event) => {
        setCardNumber(event.target.value);
    }
    const holderChange = (event) => {
        setCardHolder(event.target.value);
    }
    const handleRadioChange = (event) => {
        setFundType(event.target.value);
    };
    const {
        withdrawFunds,
        getWithdrawFunds,
        addFunds,
        deleteFunds,
    } = useContext(UserContext);
    const { t } = useTranslation();
    const [payments, setPayments] = useState([]);

    const [deletingFundIndex, setDeletingFundIndex] = useState('');
    const [showDeleteFundModal, setShowDeleteFundModal] = useState(false);
    const [cardHolderValid, setCardHolderValid] = useState(true);
    const [cardNumberValid, setCardNumberValid] = useState(true);
    const [expirationValid, setExpirationValid] = useState(true);

    useEffect(() => {
        getWithdrawFunds();
    }, [])

    useEffect(() => {
        setPayments(withdrawFunds);
    }, [withdrawFunds]);

    const makeDefault = (card_number) => {
        let newPayments = payments.map((item) => {
            let newItem = { ...item };
            if (newItem.card_number == card_number) {
                newItem.default = true;
            } else {
                newItem.default = false;
            }
            return newItem;
        });
        setPayments(newPayments);
    };


    const OpenDeleteFundModal = (index) => {
        setDeletingFundIndex(index)
        setShowDeleteFundModal(true);
    }
    const CloseDeleteFundModal = () => setShowDeleteFundModal(false);
    const handleDeleteFund = async () => {
        if (deletingFundIndex !== '') {
            let res = await deleteFunds(deletingFundIndex);
            setShowDeleteFundModal(false);
        }
    }
    const handleAddWithdrawFund = () => {
        if (isWithdrowMethodAddFormValid()) {
            addFunds({
                'type': fundType,
                'number': '····' + card_number.slice(-4),
                'default': false,
                'expiration': expiration,
                'card_holder': card_holder,
                'card_number': card_number
            })
            setShowAddPaymentModal(false);
            setCardHolder('');
            setCardNumber('');
            setExpiration('');
            setFundType('');
        }
    }
    const isWithdrowMethodAddFormValid = () => {
        let valid = true;

        if (!card_number) {
            valid = false;
            setCardNumberValid(false);
        } else {
            setCardNumberValid(true);
        }

        if (!card_holder) {
            valid = false;
            setCardHolderValid(false);
        } else {
            setCardHolderValid(true);
        }

        if (!expiration) {
            valid = false;
            setExpirationValid(false);
        } else {
            setExpirationValid(true);
        }

        return valid;
    }

    const [showAddPaymentModal, setShowAddPaymentModal] = React.useState(false);
    const handleCloseAddPaymentModal = () => setShowAddPaymentModal(false);
    const handleShowAddPaymentModal = () => {
        setShowAddPaymentModal(true);
        setFundType('card');
        setCardHolder('');
        setCardNumber('');
        setExpiration('');
        setCardNumberValid(true);
        setCardHolderValid(true);
        setExpirationValid(true);
    };

    return (
        <>
            <div className="info-wrapper">
                <h3 className="mt-30">{t("PAYMENTS.withdraw_funds")}</h3>
                <div className="wrapper-header">
                    <div className="added-cards-wrapper">
                        {payments?.length > 0 ? <>
                            {payments?.map((item, key) => {
                                return <div className="added-card d-flex justify-content-between" key={key}>
                                    <div className="d-flex card-info-wrapper">
                                        <div><img src={item.image} alt="" /></div>
                                        <div>
                                            <h6>{item.type} {item.number}</h6>
                                            <p>{t("PAYMENTS.expiration")}: {item.expiration}</p>
                                        </div>
                                        <div>
                                            <div className={`${item.default ? '' : 'd-none'} default`}>{t("PAYMENTS.default")}</div>
                                        </div>
                                    </div>
                                    <Dropdown drop='start'>
                                        <Dropdown.Toggle className="def-btn" variant="" id="dropdown-basic" title="">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="">
                                            <li>
                                                <button className="def-btn-item w-100" onClick={() => { makeDefault(item.card_number) }}>{t("PAYMENTS.default")}</button>
                                            </li>
                                            <li>
                                                <button className="del-card-btn w-100" onClick={() => { OpenDeleteFundModal(key) }}>{t("PAYMENTS.delete")}</button>
                                            </li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            })}
                        </> : ''}
                    </div>
                    <div className="buttons-wrapper">
                        <button className="save-btn info-btn mt-50" onClick={handleShowAddPaymentModal}>{t("PAYMENTS.add_withdraw_funds")}</button>
                    </div>
                </div>
                <Modal id="addPaymentMethodModal" className="" show={showAddPaymentModal} onHide={handleCloseAddPaymentModal} centered scrollable>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className="modal-title" id="addPaymentMethodModalCenteredScrollableTitle">
                            {t("PAYMENTS.add_card_details")}
                        </h5>
                        <div className="d-flex justify-content-center option-wrapper">
                            <label className="option-group">
                                <div className="img">Card</div>
                                <input type="radio" name="card_type" checked={fundType == 'card' ? true : false} value="card" onChange={handleRadioChange} />
                            </label>
                            <label className="option-group">
                                <div className="img">Bank account</div>
                                <input type="radio" name="card_type" checked={fundType == 'account' ? true : false} value="account" onChange={handleRadioChange} />
                            </label>
                        </div>
                        <div className="card-info-group d-flex flex-column">
                            <label>{t("PAYMENTS.card_number")}</label>
                            <input type="text" placeholder="0000 0000 0000 0000" value={card_number} onChange={cardNumberChange} className={!!cardNumberValid ? '' : 'invalid'} />
                        </div>
                        <div className="card-info-group d-flex flex-column">
                            <label>{t("PAYMENTS.card_holder")}</label>
                            <input type="text" placeholder="" name="card-holder" value={card_holder} onChange={holderChange} className={!!cardHolderValid ? '' : 'invalid'} />
                        </div>
                        <div className="d-flex input-group-wrapper">
                            <div className="card-info-group d-flex flex-column">
                                <label>{t("PAYMENTS.expiration")}</label>
                                <input type="text" placeholder="MM / YY" value={expiration} onChange={expirationChange} className={!!expirationValid ? '' : 'invalid'} />

                            </div>
                            {/* <div className="card-info-group d-flex flex-column">
                                                <label>{t("PAYMENTS.cvv")}</label>
                                                <input type="text" placeholder="XXX" name="cvv" />
                                            </div> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="" className="img-btn" onClick={handleCloseAddPaymentModal}>
                            {t("PAYMENTS.cancel")}
                        </Button>
                        <Button variant="" className="save-btn" onClick={handleAddWithdrawFund}>
                            {t("PAYMENTS.done")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal id="deleteFundModal" className="" show={showDeleteFundModal} onHide={CloseDeleteFundModal} centered scrollable>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className="modal-title" id="deleteFundModalCenteredScrollableTitle">
                            {t("FINANCES.delete_fund")}
                        </h5>
                        <div>
                            <p>{t("FINANCES.delete_fund_alert")}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="" className="img-btn" onClick={CloseDeleteFundModal}>
                            {t("LOGIN_SECURITY.cancel")}
                        </Button>
                        <Button variant="" className="save-btn" onClick={handleDeleteFund}>
                            {t("LOGIN_SECURITY.confirm")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default WithdrawFundsBlock;