import React from "react";
import { useContext } from "react";

import { Link } from "react-router-dom";
import UserContext from "../../_helper/User";
import { useTranslation } from "react-i18next";
const VerificationBanner = () => {
    const { t } = useTranslation();
    const { verified, id } = useContext(UserContext);

    return (
        <>
            {(!!id && !verified) ?
                <div className="verification-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 text-center">
                                {t("VERIFICATION.banner_title")}
                                <Link to={`${process.env.PUBLIC_URL}/verification/`} className="d-inline-block primary-button py-1 text-white text-decoration-none ms-2">{t("VERIFICATION.verificy_account")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>
            }
        </>
    );
}

export default VerificationBanner;
