import React, { Fragment, useContext, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Spinner } from '../../AbstractElements';
import { EmailAddress, Save } from '../../Constant';
import UserContext from '../../_helper/User';

const EditPersonalData = ({ closePersonalDataModal }) => {


  const { firstName, lastName, email, phone, updateCurrentUserPersonalData } = useContext(UserContext);

  const [firstNameValue, setFirstName] = useState('');
  const [lastNameValue, setLastName] = useState('');
  const [emailValue, setEmail] = useState('');
  const [phoneValue, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmail(email || '');
    setPhone(phone || '');
    setFirstName(firstName || '');
    setLastName(lastName || '');
  }, [firstName]);

  const onSubmit = async () => {
    setLoading(true);

    let result = await updateCurrentUserPersonalData(firstNameValue, lastNameValue, emailValue, phoneValue,);
    setLoading(false);
    if (true == result) {
      toast.success("Personal Data updated");
      closePersonalDataModal();
    } else {
      toast.error('Something went wrong...');
    }
    // closeBankDataModal();
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form >
            <FormGroup className='mb-3'>
              <Label className='form-label'>First Name</Label>
              <Input className='form-control' placeholder='First Name' value={firstNameValue} onChange={(e) => { setFirstName(e.target.value) }} />
            </FormGroup>
            <FormGroup className='mb-3'>
              <Label className='form-label'>Last Name</Label>
              <Input className='form-control' placeholder='Last Name' value={lastNameValue} onChange={(e) => { setLastName(e.target.value) }} />
            </FormGroup>
            <FormGroup className='mb-3'>
              <Label className='form-label'>{EmailAddress}</Label>
              <Input className='form-control' placeholder='your-email@domain.com' value={emailValue} onChange={(e) => { setEmail(e.target.value) }} />
            </FormGroup>
            <FormGroup className='mb-3'>
              <Label className='form-label'>Phone</Label>
              <Input className='form-control' placeholder='Phone' value={phoneValue} onChange={(e) => { setPhone(e.target.value) }} />
            </FormGroup>
            <div className='form-footer d-flex align-items-center'>
              <Btn attrBtn={{ onClick: onSubmit, className: 'btn-block', color: 'primary' }}>{Save}</Btn>
              {loading ?
                <div className="loader-box" style={{ width: '40px', height: '40px' }}>
                  <Spinner attrSpinner={{ style: { width: '25px', height: '25px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                </div>
                : ''}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default EditPersonalData;
