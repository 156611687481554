
import React, { Fragment, useContext, useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from "react-i18next";

import arrow from '../../assets/img/rotate-left.svg';

import { CustomStatus } from "../../Components/Common/Status";
import NotificationsContext from "../../_helper/Notification";
import Loading from './../../Components/Common/Loading';
import Seo from './../../Components/seo';

import document from '../../assets/img/profile/Document.png';
import profileKey from "../../assets/img/profile/key-24.png";
import creditCard from '../../assets/img/profile/credit-card.png';
import discovery from '../../assets/img/profile/Discovery.png';

const Notifications = () => {
  const { t } = useTranslation();
  const [allItems, setAllItems] = useState(null);
  const [unreadItems, setUnreadItems] = useState(null);
  const [readItems, setReadItems] = useState(null);

  const {
    getNotifications,
    notifications,
    loading,
    markAllNotificationsRead,
  } = useContext(NotificationsContext)

  useEffect(() => {
    getNotifications();
  }, [])

  useEffect(() => {
    if (notifications != null) {
      setAllItems(notifications);
      setUnreadItems(notifications.filter((item) => !!item?.new));
      setReadItems(notifications.filter((item) => !item?.new));
    }
  }, [notifications])

  const markAllAsRead = async () => {
    setUnreadItems(null);
    markAllNotificationsRead();
  }

  return (
    <Fragment>
      <Seo title={'Notifications'} schema={{}} />
      <section className="author-page-section trips-section active-section" section-name="all-trips">
        <div className="d-flex align-items-center">
          <h2 className="m-0">{t("NOTIFICATIONS.notifications")}</h2>
          <img src={arrow} alt="" className="cursor-pointer ms-2" onClick={getNotifications} />
        </div>
        {loading
          ? <div className="d-flex align-items-center justify-content-center my-5">
            <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
          </div>
          : <Tabs
            defaultActiveKey="unread"
            id="uncontrolled-tab-example"
            className="mb-3 trips-tab-wrapper"
          >
            <Tab eventKey="unread" title={`${t("NOTIFICATIONS.new")} (${unreadItems?.length ?? 0})`}>
              {null != unreadItems
                ? <>
                  {unreadItems?.length > 0
                    ? <>
                      <button className="btn btn-outline-success btn-sm mb-3" onClick={markAllAsRead} title={t("NOTIFICATIONS.mark_all_as_read")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                          <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                          <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                        </svg>
                      </button>
                      {unreadItems.map((t, key) => {
                        return <Notification item={t} key={key} />
                      })}
                    </>
                    : 'No New Notifications'
                  }
                </>
                : <div className="d-flex align-items-center justify-content-center my-5">
                  <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                </div>
              }
            </Tab>
            <Tab eventKey="read" title={`${t("NOTIFICATIONS.read")} (${readItems?.length ?? 0})`}>
              {null != readItems && <>
                {readItems?.length > 0
                  ? <>
                    {readItems.map((t, key) => {
                      return <Notification item={t} key={key} />
                    })}
                  </>
                  : 'No Read Notifications'
                }
              </>
              }
            </Tab>
          </Tabs>
        }
      </section>
    </Fragment >
  );
}

const Notification = ({ item }) => {
  const { t } = useTranslation();

  const icons = {
    'driver_license': document,
    'vehicle': profileKey,
    'transaction': creditCard,
    'booking': discovery,
  };

  const {
    markNotificationRead,
  } = useContext(NotificationsContext)

  const [loading, setLoading] = useState(false);

  const markAsRead = async () => {
    setLoading(true);
    await markNotificationRead(item?.id);
    setLoading(false);
  }

  const [border, setBorder] = useState('');
  const [headingBg, setHeadingBg] = useState('');
  useEffect(() => {
    switch (item?.status) {
      case 'success':
        setBorder('border-success');
        setHeadingBg('rgba(0, 196, 140, 0.12)');
        break;
      case 'fail':
        setBorder('border-danger');
        setHeadingBg('rgba(236, 78, 95, 0.12)');
        break;
      case 'info':
      default:
        setBorder('border-warning');
        setHeadingBg('rgba(255, 193, 7, 0.12)');
        break;
    }
  }, [item])

  return <>
    <div className={`card mb-3 ${border}`}>
      <div className="card-header d-flex justify-content-between" style={{ backgroundColor: headingBg }}>
        <div className="d-flex align-items-center">
          {(!!icons[item.type] ) && <img src={icons[item.type]} alt="" className="me-2" style={{marginLeft: '-10px'}} />}
          <h6>{t(`NOTIFICATIONS.types.${item?.type}`)}</h6>
        </div>
        <span>{item?.created}</span>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <p className="card-text">{item?.description}</p>
          {item?.new
            ? <>
              {loading
                ? <Loading style={{ minWidth: '32.5px', width: '32.5px', height: '32.5px' }} spinnerStyle={{ borderWidth: '2px' }} />
                : <button className="btn btn-outline-success btn-sm" onClick={markAsRead} title={t("NOTIFICATIONS.mark_as_read")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                  </svg>
                </button>
              }
            </>
            : <></>
          }
        </div>
      </div>
    </div>
  </>
}

export default Notifications;
