import React from 'react';


const Loading = ({style, spinnerStyle}) => {

  return (
    <div className='loading' style={style}>
        <div className='spinner' style={spinnerStyle}></div>
    </div>
  );
};

export default Loading;