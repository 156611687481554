
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Seo from './../../Components/seo';
import UserContext from "../../_helper/User";
import Loading from "../../Components/Common/Loading";
import { useTranslation } from "react-i18next";

const LoginSecurity = () => {
    const { t } = useTranslation();
    const {
        updateCurrentUserPassword
    } = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [passwordFormInvalid, setPasswordFormInvalid] = useState({
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
    });
    const handleClosePasswordForm = () => setShowPasswordForm(false);
    const handleShowPasswordForm = () => setShowPasswordForm(true);
    const handleSubmitPasswordForm = async () => {
        setLoading(true);
        let valid = true;
        let newPasswordInvalidForm = { ...passwordFormInvalid };
        if (!oldPassword) {
            newPasswordInvalidForm.oldPassword = 'Current password is required';
            valid = false;
        } else {
            newPasswordInvalidForm.oldPassword = '';
        }
        if (!newPassword) {
            newPasswordInvalidForm.newPassword = 'New password is required';
            valid = false;
        } else {
            newPasswordInvalidForm.newPassword = '';
        }

        if (!repeatNewPassword) {
            newPasswordInvalidForm.repeatNewPassword = 'Confirm password is required';
            valid = false;
        } else {
            if (repeatNewPassword != newPassword) {
                newPasswordInvalidForm.repeatNewPassword = 'Confirm password not equal to new password';
                valid = false;
            } else {
                newPasswordInvalidForm.repeatNewPassword = '';
            }
        }

        setPasswordFormInvalid(newPasswordInvalidForm);

        if (valid) {
            let result = await updateCurrentUserPassword(newPassword, oldPassword);
            console.log(result);
            if (true == result) {
                // setLoading(false);
                setShowPasswords(false);
                setOldPassword('');
                setNewPassword('');
                setRepeatNewPassword('');
                setShowPasswordForm(false);
                toast.success('Password Updated');
            } else {
                toast.error('Something went wrong...');
            }
            setLoading(false);
        }
    };

    const [showGoogleModal, setShowGoogleModal] = useState(false);
    const handleCloseGoogleModal = () => setShowGoogleModal(false);
    const handleShowGoogleModal = () => setShowGoogleModal(true);

    const [showDeactivateAccountModal, setShowDeactivateAccountModal] = useState(false);
    const handleCloseDeactivateAccountModal = () => setShowDeactivateAccountModal(false);
    const handleShowDeactivateAccountModal = () => setShowDeactivateAccountModal(true);

    return (
        <Fragment>
            <Seo title={'Login & security'} schema={{}} />
            <section className="author-page-section login-and-security active-section" section-name="login-&-security">
                <div className="info-wrapper">
                    <h2>{t("LOGIN_SECURITY.login_security")}</h2>
                    <div className={`${showPasswordForm ? 'd-none' : ''} wrapper-header`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("LOGIN_SECURITY.password")}</h3>
                            <div className="buttons-wrapper">
                                <button className="edit info-btn" onClick={handleShowPasswordForm}>{t("LOGIN_SECURITY.update")}</button>
                            </div>
                        </div>
                        <p>{t("LOGIN_SECURITY.last_updated")} 14 hours ago</p>
                    </div>
                    <div className={`${showPasswordForm ? '' : 'd-none'} editing-wrapper change-password-wrapper`}>
                        <div className="group">
                            <div className="d-flex justify-content-between">
                                <h3>{t("LOGIN_SECURITY.password")}</h3>
                                <button id="cancel-editing" className="info-btn align-self-end cancel-btn" onClick={handleClosePasswordForm}>{t("LOGIN_SECURITY.cancel")}</button>
                            </div>
                            <div className="inp-group d-flex flex-column">
                                <label htmlFor="current-password">{t("LOGIN_SECURITY.current_password")}</label>
                                <input type={showPasswords ? "text" : 'password'} className={!!passwordFormInvalid.oldPassword ? 'border-danger' : ''} placeholder="" id="current-password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                {!!passwordFormInvalid.oldPassword ? <small className="text-danger">{passwordFormInvalid.oldPassword}</small> : ''}
                            </div>
                            <div className="inp-group d-flex flex-column">
                                <label htmlFor="new-password">{t("LOGIN_SECURITY.new_password")}</label>
                                <input type={showPasswords ? "text" : 'password'} className={!!passwordFormInvalid.newPassword ? 'border-danger' : ''} placeholder="" id="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                {!!passwordFormInvalid.newPassword ? <small className="text-danger">{passwordFormInvalid.newPassword}</small> : ''}
                            </div>
                            <div className="inp-group d-flex flex-column">
                                <label htmlFor="confirm-password">{t("LOGIN_SECURITY.confirm_password")}</label>
                                <input type={showPasswords ? "text" : 'password'} className={!!passwordFormInvalid.repeatNewPassword ? 'border-danger' : ''} placeholder="" id="confirm-password" value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)} />
                                {!!passwordFormInvalid.repeatNewPassword ? <small className="text-danger">{passwordFormInvalid.repeatNewPassword}</small> : ''}
                            </div>
                        </div>
                        <div className="d-flex align-items-end">
                            <input type="submit" value={t("LOGIN_SECURITY.update_password")} className="save-btn" id="save-btn" onClick={handleSubmitPasswordForm} />
                            <button className="img-btn ms-3" onClick={() => { setShowPasswords(!showPasswords) }}>{showPasswords ? t('LOGIN_SECURITY.hide_passwords') : t('LOGIN_SECURITY.show_passwords')}</button>
                            {loading == true
                                ? <div className="d-inline-block ms-3">
                                    <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="info-wrapper">
                    <h2 className="mt-30">{t("LOGIN_SECURITY.social_accounts")}</h2>
                    <div className="wrapper-header">
                        <div className="d-flex justify-content-between">
                            <h3>Facebook</h3>
                            <div className="buttons-wrapper">
                                <button className="edit info-btn">{t("LOGIN_SECURITY.connect")}</button>
                            </div>
                        </div>
                        <p>{t("LOGIN_SECURITY.not_connected")}</p>
                    </div>
                    <div className="d-none editing-wrapper">
                        <div className="group">
                            <div className="d-flex justify-content-between">
                                <h3>Facebook</h3>
                                <button id="cancel-editing"
                                    className="info-btn align-self-end cancel-btn">{t("LOGIN_SECURITY.cancel")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-wrapper">
                    <div className="wrapper-header">
                        <div className="d-flex justify-content-between">
                            <h3>Google</h3>
                            <div className="buttons-wrapper">
                                <button className="alert-btn info-btn" onClick={handleShowGoogleModal}>{t("LOGIN_SECURITY.disconnect")}</button>
                            </div>
                        </div>
                        <p>{t("LOGIN_SECURITY.connected")}</p>
                    </div>
                    <Modal id="disconnectModal" className="" show={showGoogleModal} onHide={handleCloseGoogleModal} centered scrollable>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className="modal-title" id="disconnectModalCenteredScrollableTitle">
                                {t("LOGIN_SECURITY.disconnect_google_account")}
                            </h5>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="" className="img-btn" onClick={handleCloseGoogleModal}>
                                {t("LOGIN_SECURITY.cancel")}
                            </Button>
                            <Button variant="" className="save-btn" onClick={handleCloseGoogleModal}>
                                {t("LOGIN_SECURITY.confirm")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="info-wrapper">
                    <h2 className="mt-30">{t("LOGIN_SECURITY.account")}</h2>
                    <div className="wrapper-header">
                        <div className="d-flex justify-content-between">
                            <p>{t("LOGIN_SECURITY.deactivate_your_account")}</p>
                            <div className="buttons-wrapper">
                                <button className="del info-btn" onClick={handleShowDeactivateAccountModal}>{t("LOGIN_SECURITY.deactivate")}</button>
                            </div>
                        </div>
                    </div>
                    <Modal id="deactivateAccountModal" className="" show={showDeactivateAccountModal} onHide={handleCloseDeactivateAccountModal} centered scrollable>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className="modal-title" id="deactivateAccountModalCenteredScrollableTitle">
                                {t("LOGIN_SECURITY.deactivate_account")}
                            </h5>
                            <div className="list">
                                <div className="list-item">
                                    <div><span></span></div>
                                    <p>{t("LOGIN_SECURITY.account_will_disappear")}</p>
                                </div>
                                <div className="list-item">
                                    <div><span></span></div>
                                    <p>{t("LOGIN_SECURITY.access_the_account_info")}</p>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="" className="img-btn" onClick={handleCloseDeactivateAccountModal}>
                                {t("LOGIN_SECURITY.cancel")}
                            </Button>
                            <Button variant="" className="save-btn" onClick={handleCloseDeactivateAccountModal}>
                                {t("LOGIN_SECURITY.confirm")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
        </Fragment >
    );
}

export default LoginSecurity;
