import axios from 'axios';
import React, { useState } from 'react';
import { AttributesApi } from '../../api';
import Context from './index';

const AttributesProvider = (props) => {
    const [attributes, setAttributes] = useState({});

    const [pa_years, setYears] = useState([]);
    const [pa_categories, setCategories] = useState([]);
    const [pa_makes, setMakes] = useState([]);
    const [pa_model, setModel] = useState([]);
    const [pa_odometer, setOdometer] = useState([]);
    const [pa_transmission, setTransmission] = useState([]);
    const [pa_trim, setTrim] = useState([]);
    const [pa_style, setStyle] = useState([]);
    const [pa_license_country, setLicenseCountry] = useState([]);
    const [pa_plate_numer_states, setPlateNumerStates] = useState([]);
    const [pa_features, setFeatures] = useState([]);

    const getAllAttributes = async () => {
        try {
            await axios.get(AttributesApi.getAll, {
                // headers: {
                //     Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                // }
            }).then((resp) => {
                setAttributes(resp.data ?? {});

                let years = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_years';
                })
                if (!!years && years?.terms?.length > 0) {
                    setYears(years?.terms);
                }

                let makes = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_makes';
                })
                if (!!makes && makes?.terms?.length > 0) {
                    setMakes(makes?.terms);
                }

                let model = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_model';
                })
                if (!!model && model?.terms?.length > 0) {
                    setModel(model?.terms);
                }

                let categories = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'product_cat';
                })
                if (!!categories && categories?.terms?.length > 0) {
                    setCategories(categories?.terms);
                }

                let odometer = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_odometer';
                })
                if (!!odometer && odometer?.terms?.length > 0) {
                    setOdometer(odometer?.terms);
                }

                let transmission = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_transmission';
                })
                if (!!transmission && transmission?.terms?.length > 0) {
                    setTransmission(transmission?.terms);
                }

                let trim = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_trim';
                })
                if (!!trim && trim?.terms?.length > 0) {
                    setTrim(trim?.terms);
                }

                let style = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_style';
                })
                if (!!style && style?.terms?.length > 0) {
                    setStyle(style?.terms);
                }


                let license_country = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_license_country';
                })
                if (!!license_country && license_country?.terms?.length > 0) {
                    setLicenseCountry(license_country?.terms);
                }



                let plate_numer_states = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_plate_numer_states';
                })
                if (!!plate_numer_states && plate_numer_states?.terms?.length > 0) {
                    setPlateNumerStates(plate_numer_states?.terms);
                }

                let features = resp?.data?.find((attr) => {
                    return attr.attribute_slug == 'pa_features';
                })
                if (!!features && features?.terms?.length > 0) {
                    setFeatures(features?.terms);
                }


            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };


    return (
        <Context.Provider
            value={{
                ...props,
                getAllAttributes,
                attributes,
                pa_years,
                pa_categories,
                pa_makes,
                pa_model,
                pa_odometer,
                pa_transmission,
                pa_trim,
                pa_style,
                pa_license_country,
                pa_plate_numer_states,
                pa_features,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default AttributesProvider;