import React, { Fragment, useContext, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import UserContext from '../../_helper/User';
import EditBankData from './EditBankData';

const UserBanking = () => {

  const { bankName, bankAccount } = useContext(UserContext);


  const [bankDataModal, setBankDataModal] = useState(false);
  const openBankDataModal = () => {
    setBankDataModal(true);
  };

  const closeBankDataModal = () => {
    setBankDataModal(false);
  };

  return (
    <Fragment>
      <Col sm='12'>
        <Card className='hovercard'>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <h4 className='card-title mb-0 text-start'>Bank Data</h4>
            <button className='btn btn-info' type='button' onClick={() => { openBankDataModal() }}>Edit</button>
          </CardHeader>
          <CardBody>
            <div className='info p-0'>
              <Row className='step3' style={{ rowGap: '15px' }} data-intro='This is the your details'>
                <Col xs='12' md='6'>
                  <div className='ttl-info text-start'>
                    <h5>
                      <i className='icofont icofont-bank me-2' style={{ fontSize: '20px' }}></i> Bank
                    </h5>
                    <span>{bankName}</span>
                  </div>
                </Col>
                <Col xs='12' md='6'>
                  <div className='ttl-info text-start'>
                    <h5>
                      <i className='icofont icofont-credit-card me-2' style={{ fontSize: '20px' }}></i> Bank Account
                    </h5>
                    <span>{bankAccount}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={bankDataModal} toggle={closeBankDataModal} size='lg' centered>
        <ModalHeader toggle={closeBankDataModal}>
          Edit Bank Data
        </ModalHeader>
        <ModalBody>
          <EditBankData closeBankDataModal={closeBankDataModal} />
        </ModalBody>
      </Modal>
    </Fragment >
  );
};

export default UserBanking;
