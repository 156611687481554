const data = {
    "General": "General eng",
    "Dashboards,Widgets": "Dashboards,Widgets",
    "Dashboard": "Dashboard",
    "Default": "Default",
    "Ecommerce": "Ecommerce",
    "Widgets": "Widgets",
    "Chart": "Chart",
    "Applications": "Applications",
    "Ready to use Apps": "Ready to use Apps",
    "MENU": {
        "homepage": "Homepage",
        "explore_vehicles": "Explore Vehicles",
        "contact": "Contact",
        "about_us": "About Us",
        "faq": "FAQ",
        "terms": "Terms",
        "privacy_policy": "Privacy Policy",
        "rental_terms": "Rental Terms",
        "favorites": "Favorites",
        "profile": "Profile",
        "list_your_car": "List your car",
        "language_and_currency": "Language & Currency",
        "support": "Support",
        "logout": "Log out",
        "login_or_register": "LOGIN or REGISTER",
        "my_profile": "MY PROFILE",
        "copyright": "Copyright © 2022 All right reserved.",
    },
    "ACCOUNT_SIDEBAR": {
        "profile": "Profile",
        "personal_info": "Personal info",
        "login_and_security": "Login & security",
        "driver_license": "Driver License",
        "payments_and_payouts": "Payments & Payouts",
        "rewiew_payments": "Rewiew payments",
        "finances": "Finances",
        "trips": "Trips",
        "all_trips": "All Trips",
        "bookings_of_my_cars": "Bookings of my cars",
        "notifications": "Notifications",
        "notifications_about": `Notifications about discounts,
        promotions and special offers`,
        "system_notifications": "System notifications"
    },
    "HOME_SLIDER": {
        "0": {
            "title": "Enjoy<br />the journey<br />with your family!",
            "description": "Find the perfect car to complement your vacation vibe"
        },
        "1": {
            "title": "Enjoy<br />the journey<br />with your family!",
            "description": "Find the perfect car to complement your vacation vibe"
        },
        "2": {
            "title": "Enjoy<br />the journey<br />with your family!",
            "description": "Find the perfect car to complement your vacation vibe"
        },
        "3": {
            "title": "Enjoy<br />the journey<br />with your family!",
            "description": "Find the perfect car to complement your vacation vibe"
        }
    },
    "HOME_FIND_YOUR_CAR": {
        "section_title": "Find your car",
        "pickup": "Pickup",
        "pickup_date": "Pickup date",
        "drop_off": "Drop Off",
        "drop_off_date": "Drop Off date",
        "deliver_at_different_point": "Deliver at different point",
        "rent_location": "RENT LOCATION",
        "location": "Location",
        "trip_start": "Trip start",
        "trip_end": "Trip end",
        "search": "Search",
        "select_time": "Select Time"
    },
    "VEHICLE_LIST": {
        "title": "Vehicles list",
        "of": "of",
        "cars": "cars",
        "show_by": "Show by",
        "sort_by": "Sort by",
        "price_low_to_high": "Price: low to high",
        "relevance": "Relevance",
        "distance_away": "Distance away",
        "price_high_to_low": "Price: high to low",
        "no_vehicles_matching_your_filters": "No vehicles matching your filters",
        "more_filters": "More filters"
    },
    "FILTER_SIDEBAR": {
        "filters": "Filters",
        "transmission": "Transmission",
        "vehicle_mileage": "Vehicle mileage",
        "vehicle_years": "Vehicle Years",
        "vehicle_types": "Vehicle types",
        "vehicle_makes": "Vehicle makes",
        "vehicle_model": "Vehicle model",
        "filter": "Filter"
    },
    "ABOUT": {
        "about_trip": "About-trip",
        "description": "Take a look under the hood of the world’s largest car sharing marketplace",
        "learn_more": "Learn More",
        "find_your_drive": "Find your drive!",
        "company_mission": "Company mission",
        "mission_description": "To put the world’s 1.5 billion cars to better use",
        "utrip_by_the_numbers": "Utrip by the numbers",
        "utrip_by_the_numbers_items": {
            "0": {
                "title": "7500+ cities",
                "description": "across the US, Canada, & the UK"
            },
            "1": {
                "title": "1,300+ makes & models",
                "description": "often delivered directly to you"
            },
            "2": {
                "title": "Up to $10K/year/car",
                "description": "earned by the average US host"
            },
            "3": {
                "title": "Cars from $25/day",
                "description": "for all kinds of occasions and budgets"
            },
            "4": {
                "title": "Up to $750,000",
                "description": "in liability insurance under a policy issued to Utrip by Travelers*"
            }
        },
        "sustainable": "Sustainable car travel on Utrip",
        "learn_more_about_the_program": "Learn more about the program"
    },
    "CONTACT": {
        "title": "Get in touch with us for more information",
        "description": "If you need help or have a question, we are here for you"
    },
    "ERROR_PAGE": {
        "error_text": `The page you are attempting to reach is currently not available.
                        <br/> This may be because the page does not exist or has been moved.`,
        "back_btn": "BACK TO HOME PAGE"
    },
    "FAVORITES": {
        "title": "Favorites",
        "your_favorites_list_is_empty": "Your Favorites List is Empty",
        "explore_vehicles": "Explore Vehicles"
    },
    "HELP_CENTER": {
        "title": "Help cente",
        "show_all": "Show all",
        "articles": "articles"
    },
    "HOME_FEATURED_CAR": {
        "rent_now": "Rent Now",
        "start_from": "Start from",
        "daily": "/Daily"
    },
    "MY_VEHICLES": {
        "title": "Let your car work while you relax",
        "description": "Share your car whenever you're not using it and earn an average of $700 per month on Utrip",
        "learn_more": "Learn More",
        "my_vehicles": "My vehicles",
        "add_vehicle": "Add vehicle",
        "there_is_no_vehicles": "There is no vehicles",
        "add_your_first_vehicle": "Add Your first vehicle",
        "edit": "Edit",
        "hide": "Hide",
        "active": "Active",
        "delete": "Delete",
        "cancel": "Cancel",
        "done": "Done",
        "delete_alert": "Are you sure you want to delete this vehicle?"
    },
    "PAYMENT_RESULT": {
        "your_order_has_been": "Your order has been",
        "your_order_is": "Your order is",
        "your_order_was": "Your order was",
        "paid": "Paid!",
        "failed": "Failed",
        "awaiting_payment": "Awaiting Payment!",
        "to_create_order": "to create order!",
        "cancelled": "Cancelled!",
        "rejected": "Rejected!",
        "awaiting_payment": "Awaiting Payment!",
        "not_found": "Not Found!",
        "order_n": "Order N",
        "your_journey_start": "Your journey start",
        "at": "at",
        "find_more": "You can find more details in your account by <br /> clicking the link below",
        "learn_more": "Learn more"
    },
    "SIGN_IN": {
        "don_t_have_an_account": "Don't have an account?",
        "sign_up": "Sign Up",
        "remember_me": "Remember me",
        "sign_in": "Sign In",
        "forgot_password": "Forgot password?",
        "welcome_back": "Welcome back",
        "sign_in_to_continue": "Sign in to continue",
        "error_alert": "You enter wrong password or username!..",
        "success_alert": "Successfully logged in!.."
    },
    "SIGN_UP": {
        "welcome_user": "Welcome User",
        "sign_up_to_join": "Sign up to join",
        "register_as_renter": "Register As Renter",
        "register_as_owner": "Register As Owner",
        "first_name": "First Name",
        "last_name": "Last Name",
        "email": "Email",
        "password": "Password",
        "terms_of_service": "Terms of Service",
        "sign_up": "Sign Up",
        "have_an_account": "Have an account?",
        "sign_in": "Sign In",
        "went_wrong": "Something went wrong...",
        "show": "Show",
        "hide": "Hide",
        "successfully_signed_up": "Successfully Signed Up! Sign in with your credentials"
    },
    "VEHICLE_SINGLE": {
        "hosted_by": "Hosted by",
        "trips": "trips",
        "member_since": "Member since",
        "contact_info": "Contact info",
        "email": "Email",
        "feedback": "Feedback",
        "make_a_call": "Make a Call",
        "phone_number": "Phone Number",
        "description": "Description",
        "equipment": "Equipment",
        "persons": "Persons",
        "gps": "GPS",
        "air_conditioning": "Air Conditioning",
        "technical_features": "Technical Features",
        "year": "Year",
        "engine": "Engine",
        "mileometer": "Mileometer",
        "doors": "Doors",
        "consumption": "Consumption",
        "color": "Color",
        "transmission": "Transmission",
        "show_more": "Show More",
        "location": "Location",
        "recommendation_car": "Recommendation Car",
        "view_all": "View all"
    },
    "VERIFICATION": {
        "code_send": "Code was sended to your email",
        "please_verify_your_account": "Please verify your account",
        "will_send_verification_code": "We will send verification code to your email",
        "send_code": "Send Code",
        "successfully_verified": "Your account successfully verified",
        "went_wrong": "Something went wrong...",
        "verification_code": "Verification Code",
        "verify": "Verify",
        "was_sent": "Code was sent to your email",
        "please_check_it": "Please check it and fill in the form",
        "resend_code": "Resend Code",
        "resended": "Code was resended",
        "banner_title": "Some site functions will be unavailable without account verification",
        "verificy_account": "Verificy Account",
        "cant_verify": "Can't verify user. Entered Code don't matching"
    },
    "REVIEW": {
        "fill_text": "Please Fill Review Text",
        "select_rate": "Please select Review Rate",
        "review_published": "Review Published",
        "went_wrong": "Something went wrong...",
        "add_review": "Add review",
        "rate": "Rate",
        "your_review": "Your Review",
        "submit": "Submit",
        "reviews": "Reviews"
    },
    "VEHICLE_SIDEBAR": {
        "booked_succesfully": "Booked succesfully",
        "went_wrong": "Something went wrong...",
        "day": "/ day",
        "hour": "/ hour",
        "days": "days",
        "hours": "hours",
        "vehicle_is_not_verification": "Vehicle is not Verification",
        "verification_required": "Profile Verification Required For Booking",
        "verified_driver_license_required": "Verified Driver License Required For Booking",
        "trip_start": "Trip start",
        "date": "Date",
        "time": "Select Time",
        "pickup_return_location": "Pickup & return location",
        "pick_up_location": "Pick Up Location",
        "delivery": "Delivery",
        "driver": "Driver",
        "with_driver": "With Driver",
        "select_delivery_point": "Select delivery point",
        "deliver_at_different_point": "Deliver at different point",
        "total": "Total",
        "book_now": "Book Now"
    },
    "DRIVER_LICENSE": {
        "driver_license": "Driver License",
        "first_name": "First Name",
        "last_name": "Last Name",
        "enter_your_name_exactly": "Enter your name exactly as it appears on your driver’s license",
        "date_of_birth": "Date of birth",
        "issuing_country": "Issuing country",
        "license_number": "License Number",
        "info": `Please take a photo of your driver's license, we remind you that the original document is required.
        The first photo is the front side, the second is the back. Hold your Driver License upright for clear, glare-free photos.`,
        "front_image": "Front Image",
        "select_image": "Select image",
        "front_image_is_mandatory": "Front image is mandatory",
        "back_image": "Back Image",
        "back_image_is_mandatory": "Back image is mandatory",
        "save": "Save",
        "driver_license_updated": "Driver License Updated",
        "went_wrong": "Something went wrong...",
        "required_fields_are_empty": "Some of the required fields are empty. Please fill them and try again"
    },
    "FINANCES": {
        "finances": "Finances",
        "balance": "Balance",
        "upcoming_payments": "Upcoming Payments",
        "on_hold": "On Hold",
        "withdrawal_amount": "Withdrawal Amount",
        "enter_withdrawal_amount": "Enter Withdrawal Amount",
        "accept": "Accept",
        "cancel": "Cancel",
        "name": "Name",
        "order_n": "Order #",
        "status": "Status",
        "amount": "Amount",
        "withdraw": "Withdraw",
        "date": "Date",
        "no_withdrawal_requests": "No Withdrawal Requests.",
        "history": "History",
        "overview": "Overview",
        "delete_fund": "Delete Fund",
        "delete_fund_alert": "Are you sure you want to delete fund?"
    },
    "LOGIN_SECURITY": {
        "login_security": "Login & security",
        "password": "Password",
        "last_updated": "Last updated",
        "current_password": "Current password",
        "new_password": "New Password",
        "confirm_password": "Confirm password",
        "hide_passwords": "Hide Passwords",
        "show_passwords": "Show Passwords",
        "update_password": "Update password",
        "social_accounts": "Social accounts",
        "connect": "Connect",
        "not_connected": "Not connected",
        "connected": "Connected",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "update": "Update",
        "disconnect_google_account": "Are you sure you want to disconnect your Google account?",
        "disconnect": "Disconnect",
        "account": "Account",
        "deactivate_your_account": "Deactivate your account",
        "deactivate": "Deactivate",
        "deactivate_account": "Deactivate account?",
        "account_will_disappear": `The profile and listings associated with this account will
                                  disappear.`,
        "access_the_account_info": `You won’t be able to access the account info or past
                                  reservations.`,
    },
    "PAYMENTS": {
        "payments_payouts": "Payments & payouts",
        "payment_methods": "Payment methods",
        "description": `Add a payment method using our secure payment system, then start planning your next
            trip`,
        "withdraw_funds": "Withdraw Funds",
        "withdraw_funds_desc": "Choose which card you want to withdraw, indicate the amount then confirm the withdrawal",
        "expiration": "Expiration:",
        "default": "Default",
        "delete": "Delete",
        "add_payment_method": "Add payment method",
        "add_withdraw_funds": "Add Withdraw Funds",
        "add_card_details": "Add card details",
        "card_number": "Card number",
        "card_holder": "Card holder",
        "cvv": "CVV",
        "cancel": "Cancel",
        "done": "Done",
        "coupons": "Coupons",
        "add_coupon_and_save": "Add a coupon and save on your next trip.",
        "your_coupons": "Your coupons",
        "add_coupon": "Add coupon",
        "use_coupon": "Use coupon",
        "enter_coupon": "Enter a coupon code",
    },
    "NOTIFICATIONS" : {
        "notifications": "Notifications",
        "notifications_list_is_empty": "Notifications list is empty",
        "all": "All",
        "all_notifications": "All Notifications",
        "new": "New",
        "read": "Read",
        "mark_as_read": "Mark As Read",
        "mark_all_as_read": "Mark All As Read",
        "types": {
            "driver_license": "Driver License Verification",
            "vehicle": "Vehicle Verification",
            "booking": "Booking",
            "transaction": "Transaction",
        },
    },
    "PERSONAL_INFO": {
        "avatar_updated": "Avatar Updated",
        "something_went_wrong": "Something went wrong...",
        "name_and_last_name_updated": "Name and Last name Updated",
        "email_updated": "Email Updated",
        "phone_updated": "Phone Updated",
        "gender_updated": "Gender Updated",
        "role_update_to_owner": "Role update to Owner",
        "personal_info": "Personal info",
        "photo": "Photo",
        "delete": "Delete",
        "update": "Update",
        "cancel": "Cancel",
        "upload_file": "Upload file",
        "save": "Save",
        "name": "Name",
        "edit": "Edit",
        "last_name": "Last name",
        "first_name": "First name",
        "legal_name": `Please, add legal name. This is the name on your travel document, which cold be a
        license or a passport. `,
        "gender": "Gender",
        "not_specified": "Not specified",
        "male": "Male",
        "female": "Female",
        "email_adress": "Email adress",
        "your_email": "Your email",
        "phone_numbers": "Phone numbers",
        "role": "Role",
        "became_owner": "Became Owner",
        "role_change_alert": "You won’t be able to undo your role change",
        "confirm": "Confirm"
    },
    "TRIPS": {
        "trips": "Trips",
        "bookings_of_my_cars": "Bookings of my cars",
        "active": "Active",
        "booked": "Booked",
        "history": "History",
        'pending': "Pending",
        "completed": "Completed",
        "in_progress": "In Progress",
        "reserved": "Reserved",
        "all": "All",
        "order_n": "Order #",
        "total": "Total",
    },
    "STATUSES": {
        'pending': "Pending",
        "reserved": "Reserved",
        "in_progress": "In Progress",
        "failed": "Failed",
        "rejected": "Rejected",
        "cancelled": "Cancelled",
        "completed": "Completed",
        "confirmed": "Confirmed",
    },
    "BOOKED_DATE": {
        "start_date": "Start date",
        "start_time": "Start time",
        "end_date": "End date",
        "end_time": "End time",
        "add": "Add",
        "clear": "Clear",
        "edit": "Edit",
        "delete": "Delete",
        "update": "Update",
        "select_time": "Select Time"
    },
    "VEHICLE_ADD": {
        "required_fields_empty": "Some of the required fields are empty. Please fill them and try again",
        "vehicle_published": "Vehicle Published",
        "something_went_wrong": "Something went wrong...",
        "list_your_car": "List your car",
        "add": "Add",
        "where_is_your_car_located": "Where is your car located?",
        "daily_price": "Daily price",
        "hourly_price": "Hourly price",
        "has_driver": "Has Driver",
        "driver_required": "Driver Required",
        "driver_daily_price": "Driver Daily price",
        "year": "Year",
        "odometer": "Odometer",
        "make": "Make",
        "model": "Model",
        "transmission": "Transmission",
        "trim": "Trim",
        "style": "Style",
        "personal_vehicle": "I’m listing a personal vehicle",
        "commercial_rental_insurance": `This applies to everyone except companies with commercial rental insurance`,
        "next": "Next",
        "car_details": "Car details",
        "license_plate_number": "License plate number",
        "state": "State",
        "license_plate_information": "Your license plate information won’t be publicly visible",
        "car_description": "Car description",
        "car_features": "Car Features",
        "car_photos": "Car photos",
        "car_photo_guide": `Please take 10 - 15 photos to document the car's condition. Be sure to take photos of 
            the exterior and interior. Walk around the car and take photos of all sides. High quality
            photos increase your earning potential by attracting more guests.`,
        "please_add_images": "Please add images",
        "safety_quality_standarts": "Safety & quality standarts",
        "publish": "Publish",
        "agree_and_continue": "Agree and continue",
        "privacy_guide": `Velit at vehicula sollicitudin amet, magna morbi a mollis. Vitae maecenas ac facilisi augue in consequat. Lorem tempus, molestie non nunc non varius augue ut. Tincidunt eget vulputate vel ut eget sapien elit. Egestas integer netus ut maecenas. Rhoncus, nisi, feugiat rhoncus lacus congue nec faucibus. Ipsum sollicitudin elit consectetur urna.`,
        "vehicle_updated": "Vehicle Updated",
        "edit_your_car": "Edit your car",
        "went_wrong": "Something went wrong",
        "enter_address": "Enter address",
        "closed_dates": "Closed dates",
        "closed_dates_info": "On the selected dates, your car will be unavailable in the search results and it will not be possible to book it",
        "update": "Update"
    },
    "VEHICLE_CARD": {
        "total_price": "total price"
    },
    "FOOTER": {
        "footer_description": `Browse the best car for your daily, anywhere, anytime easily and
        without difficulty`,
        "pages": {
            "menu_title": "Pages",
            "about_us": "About us",
            "explore_vehicles": "Explore Vehicles",
            "news": "News",
            "contact": "Contact",
        },
        "information": {
            "menu_title": "Information",
            "faq": "FAQ",
            "rental_terms": "Rental Terms",
            "privacy_policy": "Privacy Policy",
        },
        "app_download": {
            "menu_title": "Mobile App Download",
            "description": "Download now to get more discounts through your mobile app!",
        },
        "subscribe": {
            "menu_title": "Subscribe",
            "description": "Enter email to receive updates and newsletter",
            'email': "Email",
            "button": "Subscribe"
        },
        "copyright": "Copyright © 2022 All right reserved.",
        "made_by": "Made by FRIGATE STUDIO"
    },
    "HEADER": {
        "take_date": "Take date",
        "return_date": "Return date",
        "take_time": "Take time",
        "return_time": "Return time",
        "search_placeholder": "Search by rent location",
        "online_chat": "Online Chat",
        "chat_desc": `How can <br />
            I help you?`,
        "notifications": "Notifications",
        "general": "General",
        "recommendations": "Recommendations",
        "no_new_notifications": "No New Notifications",
        "profile": {
            "favorites": "Favorites",
            "profile": "Profile",
            "list_your_car": "List your car",
            "language_currency": {
                "title": "Language & Currency",
                "modal_title": "Choose a language & currency",
                "language_and_region": "Language and region",
                "currency": "Currency",
                "currency_content": "Currency Content",
            },
            "support": "Support",
            "log_out": {
                "title": "Log out",
                "modal_title": "Log out",
                "modal_alert": "Do you sure you want to log out?",
                "confirm_btn": "Yes, log out"
            },
        }
    }
}

export default data;