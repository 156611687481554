import axios from 'axios';
import React, { useState } from 'react';
import { OrdersApi, ProductsApi } from '../../api';
import Context from './index';
import { useNavigate } from "react-router-dom";

const OrdersProvider = (props) => {

    const [orderData, setOrderData] = useState(null)
    const [trips, setTrips] = useState(null)
    const [bookings, setBookings] = useState(null)

    const addOrder = async (payload) => {
        try {
            await axios.post(OrdersApi.add, {
                ...payload
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp?.data) {
                    // console.log(resp?.data);
                    if (!!resp?.data?.redirect) {
                        window.location.href = resp?.data?.redirect
                    }
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    };

    const checkOrderStatus = async (id) => {
        try {
            await axios.get(OrdersApi.checkOrderStatus, {
                params: {
                    id: id
                },
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp?.data?.status) {
                    setOrderData({
                        status: resp?.data?.status,
                        dates: {
                            pickup_date: resp?.data?.items[0].pickup_date,
                            pickup_time: resp?.data?.items[0].pickup_time,
                        }
                    })
                } else {
                    setOrderData({
                        status: false,
                        dates: {
                            pickup_date: null,
                            pickup_time: null,
                        }
                    })
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            setOrderData({
                status: false,
                dates: {
                    pickup_date: null,
                    pickup_time: null,
                }
            })
            return false;
        }
    };

    const getTrips = async () => {

        try {
            await axios.get(OrdersApi.getTrips, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                // console.log(resp?.data);
                setTrips(resp?.data);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            setTrips([]);
            return false;
        }
    }

    const getBookings = async () => {

        try {
            await axios.get(OrdersApi.getBookings, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                // console.log(resp?.data);
                setBookings(resp?.data);
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            setBookings([]);
            return false;
        }
    }

    const updateBookingStage = async (id, newStage) => {
        try {
            await axios.post(OrdersApi.updateStage, {
                id: id,
                new_stage: newStage,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            }).then((resp) => {
                if (!!resp?.data) {
                    // console.log(resp?.data);
                    if (!!resp?.data?.updated) {
                        let currentBookings = bookings.map((b) => {
                            if (b.id == resp?.data?.updated?.order_id) {
                                b.order_stage = resp?.data?.updated?.new_stage;
                            }
                            return {...b}
                        });
                        setBookings(currentBookings);
                    }
                    // update order(booking) stage in state
                }
            });
            return true;
        } catch (error) {
            console.log('cancelled', error);
            return false;
        }
    }

    return (
        <Context.Provider
            value={{
                ...props,
                addOrder,
                checkOrderStatus,
                orderData,

                getTrips,
                trips,

                updateBookingStage,
                getBookings,
                bookings
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default OrdersProvider;