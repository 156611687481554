import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Footer from "../Layout/Footer/Footer";
import Header from "../Layout/Header/Header";
import "../assets/css/create-car.css";
import "../assets/css/my-vehicles.css";
import "../assets/css/custom.css";
import "../assets/css/style.css";

import rangeRover from "../assets/img/vehicles/range-rover.png";
import { Link } from "react-router-dom";
import Seo from "../Components/seo";
import ProductsContext from "../_helper/Products";
import Loading from "../Components/Common/Loading";
import { useTranslation } from "react-i18next";

const MyVehicles = () => {

    const [vehicles, setVehicles] = useState([]);

    const contentRef = useRef();

    const vehiclesMock = [
        {
            id: 1,
            image: rangeRover,
            title: 'Land Rover Range Rover Sport 1',
            description: `vehicle haulage, transmission, vehicle type, year, color, number of
            seats vehiclehaulage, transmission, vehicle type, year, color, number of seats...`,
            price: {
                perDay: '$105/day',
                total: '',
            },
            active: true,
        },
        {
            id: 2,
            image: rangeRover,
            title: 'Land Rover Range Rover Sport 2',
            description: `vehicle haulage, transmission, vehicle type, year, color, number of
            seats vehiclehaulage, transmission, vehicle type, year, color, number of seats...`,
            price: {
                perDay: '$105/day',
                total: '',
            },
            active: true,
        },
        {
            id: 3,
            image: rangeRover,
            title: 'Land Rover Range Rover Sport 3',
            description: `vehicle haulage, transmission, vehicle type, year, color, number of
            seats vehiclehaulage, transmission, vehicle type, year, color, number of seats...`,
            price: {
                perDay: '$105/day',
                total: '',
            },
            active: true,
        },
    ];

    const {
        myProducts,
        getMyProducts,
    } = useContext(ProductsContext);

    useEffect(() => {
        setVehicles(vehiclesMock);
        getMyProducts();
    }, []);
    const { t } = useTranslation();
    return (
        <Fragment>
            <Seo title={'My Vehicles'} schema={{}} />
            <Header />
            <main className="p-0">
                <section className="create-car-banner">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-7 text-center text-white">
                                <h2>{t("MY_VEHICLES.title")}</h2>
                                <p>{t("MY_VEHICLES.description")}
                                </p>
                                <button onClick={() => { contentRef.current.scrollIntoView() }} className="ab-1-content-button">{t("MY_VEHICLES.learn_more")}</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="my-vehicles" ref={contentRef}>
                    <div className="container">
                        <h1>My vehicles</h1>
                        <div className="vehicles-wrapper">
                            {myProducts == null
                                ? <div className="d-flex align-items-center justify-content-center my-5">
                                    <Loading style={{ width: '84px', height: '84px' }} spinnerStyle={{ borderWidth: '5px' }} />
                                </div>
                                : <>
                                    {myProducts?.length > 0
                                        ? <>
                                            <Link to="/add-vehicle" className="save-btn info-btn mb-3 d-inline-block">Add vehicle</Link>
                                            {myProducts?.map((item, key) => {
                                                return (<div className="vehicle-item d-flex justify-content-between" item-id="1" key={item.id}>
                                                    <div className="d-flex vehicle-info-wrapper">
                                                        <div>
                                                            <Link to={`/vehicle/${item.id}`}>
                                                                <img src={item.image} alt="" />
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <div className="d-flex flex-wrap align-items-center mb-1" style={{gap:'8px'}}>
                                                                <Link className="title m-0" to={`/vehicle/${item.id}`}>{item.title}</Link>
                                                                {item.post_status == 'publish'
                                                                    ? <span className="custom-pill py-0">Verified</span>
                                                                    : <span className="custom-pill yellow py-0">On Verification</span>
                                                                }
                                                            </div>
                                                            <p>${item.daily_price}/day</p>
                                                            <p className="tags">{item.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="btn-group dropstart">
                                                        <Dropdown drop='start'>
                                                            <Dropdown.Toggle className="def-btn my-vehicle-actions-menu-toggler p-0 border-0 " variant="" id="dropdown-basic" title="">
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="my-vehicle-actions-menu">
                                                                <li className="def-btn-item">
                                                                    <Link to={`/edit-vehicle/${item.id}`}>{t("MY_VEHICLES.edit")}</Link>
                                                                </li>
                                                                {item.status == 'active' && (<li className="def-btn-item hide-btn">{t("MY_VEHICLES.hide")}</li>)}
                                                                {item.status == 'hidden' && (<li className="def-btn-item active-btn">{t("MY_VEHICLES.active")}</li>)}
                                                                <li className="def-btn-item del-vehicle-btn" data-bs-toggle="modal"
                                                                    data-bs-target="#deleteVehicleModal">{t("MY_VEHICLES.delete")}</li>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>)
                                            })}
                                            <Link to="/add-vehicle" className="save-btn info-btn mt-5 d-inline-block">{t("MY_VEHICLES.add_vehicle")}</Link>
                                        </>
                                        : <div className="d-flex flex-column align-items-center">
                                            <h2>{t("MY_VEHICLES.there_is_no_vehicles")}</h2>
                                            <Link to="/add-vehicle" className="save-btn info-btn mt-5">{t("MY_VEHICLES.add_your_first_vehicle")}</Link>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <div className="modal fade" id="deleteVehicleModal" tabIndex="-1" aria-labelledby="deleteVehicleModalLabel"
                            aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <h5 className="modal-title" id="deleteVehicleModalCenteredScrollableTitle">
                                            {t("MY_VEHICLES.delete_alert")}
                                        </h5>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="img-btn" data-bs-dismiss="modal"
                                            aria-label="Close">{t("MY_VEHICLES.cancel")}</button>
                                        <button type="button" className="save-btn delete-my-car" data-bs-dismiss="modal"
                                            aria-label="Close">{t("MY_VEHICLES.done")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>
    );
}

export default MyVehicles;
