
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import avatar from "../../assets/img/profile/profile-default.png";
import ReactCrop, { makeAspectCrop, convertToPixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Seo from './../../Components/seo';
import UserContext from "../../_helper/User";
import { toast } from "react-toastify";
import Loading from './../../Components/Common/Loading';
import { useTranslation } from "react-i18next";
const PersonalInfo = () => {
    const { t } = useTranslation();

    const {
        updateCurrentUserAvatar,
        imageUrl,
        getCurrentUserData,
        updateCurrentUserNameData,
        firstName,
        lastName,
        updateCurrentUserEmailData,
        email,
        updateCurrentUserPhoneData,
        phone,
        updateCurrentUserGenderData,
        gender,
        makeCurrentUserOwner,
        role,
    } = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        gender: '',
        email: '',
        phone: '',
        gender: '',
    });

    const setFormValues = (items) => {
        let newForm = { ...form, ...items };
        setForm(newForm);
    }

    useEffect(() => {
        getCurrentUserData();
    }, []);

    useEffect(() => {
        setFormValues({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            gender: gender,
        });
        $(genderSelect.current).selectmenu({
            change: function (event, ui) {
                setFormValues({ 'gender': event.target.value });
            }
        });
    }, [firstName]);


    const genderSelect = useRef();
    const handleGenderSelectChange = () => {
        $(genderSelect.current).selectmenu('refresh');
    }



    const [editing, setEditing] = useState('');

    const setEditingScreen = (screen) => {
        if ('gender' == screen) {
            $(genderSelect.current).selectmenu("refresh");
        }
        setEditing(screen);
    }

    const saveValue = async (name) => {
        setLoading(true);
        let res = false;
        switch (name) {
            case 'avatar':
                res = await updateCurrentUserAvatar(croppedImage);
                if (true == res) {
                    setFileUrl(null);
                    setCroppedImage(null);
                    toast.success(t("PERSONAL_INFO.avatar_updated"));
                    setEditing('');
                } else {
                    toast.error(t("PERSONAL_INFO.something_went_wrong"));
                }
                setLoading(false);
                break;
            case 'name':
                res = await updateCurrentUserNameData(form.firstName, form.lastName);
                if (true == res) {
                    toast.success(t("PERSONAL_INFO.name_and_last_name_updated"));
                    setEditing('');
                } else {
                    toast.error(t("PERSONAL_INFO.something_went_wrong"));
                }
                setLoading(false);
                break;
            case 'email':
                res = await updateCurrentUserEmailData(form.email);
                if (true == res) {
                    toast.success(t("PERSONAL_INFO.email_updated"));
                    setEditing('');
                } else {
                    toast.error(t("PERSONAL_INFO.something_went_wrong"));
                }
                setLoading(false);
                break;
            case 'phone':
                res = await updateCurrentUserPhoneData(form.phone);
                if (true == res) {
                    toast.success(t("PERSONAL_INFO.phone_updated"));
                    setEditing('');
                } else {
                    toast.error(t("PERSONAL_INFO.something_went_wrong"));
                }
                setLoading(false);
                break;
            case 'gender':
                res = await updateCurrentUserGenderData(form.gender);
                if (true == res) {
                    toast.success(t("PERSONAL_INFO.gender_updated"));
                    setEditing('');
                } else {
                    toast.error(t("PERSONAL_INFO.something_went_wrong"));
                }
                setLoading(false);
                break;
            default:
                setLoading(false);
                break;
        }

        // console.log('save', name);
    }

    const closeEditing = (name) => {
        switch (name) {
            case 'avatar':
                setFileUrl(null);
                setCroppedImage(null);
                break;
            case 'name':
                break;
            case 'email':
                break;
            case 'phone':
                break;
            case 'gender':
                break;
            default:
                break;
        }

        // console.log('save', name);
        setEditing('');
    }

    // image cropping

    const imagePreview = useRef();

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);

    const [crop, setCrop] = useState({
        x: 0,
        y: 0,
        width: 150,
        height: 150,
        unit: 'px',
    });

    const [croppedImage, setCroppedImage] = useState(null);

    const cropImage = (c) => {
        setCrop(c);
        getCroppedImage(c);
    }

    const readUrl = (event) => {
        if (event.target.files.length === 0) return;
        var mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            setFileUrl(reader.result);
        };
    };

    const readFile = (event) => {
        if (event.target.files.length === 0) return;
        setSelectedFile(event.target.files[0]);
        readUrl(event);
    }

    const getCroppedImage = (pixelCrop) => {

        const canvas = document.createElement('canvas')

        const scaleX = imagePreview.current.naturalWidth / imagePreview.current.width;
        const scaleY = imagePreview.current.naturalHeight / imagePreview.current.height;

        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            imagePreview.current,
            pixelCrop.x * scaleX,
            pixelCrop.y * scaleY,
            pixelCrop.width * scaleX,
            pixelCrop.height * scaleY,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )
        const base64Image = canvas.toDataURL('image/jpeg', 1);
        setCroppedImage(base64Image);
    }


    const [showBecameOwnerModal, setShowBecameOwnerModal] = useState(false);
    const handleCloseBecameOwnerModal = () => setShowBecameOwnerModal(false);
    const handleShowBecameOwnerModal = () => setShowBecameOwnerModal(true);

    const handleBecameOwnerRequest = async () => {
        setLoading(true);
        let res = await makeCurrentUserOwner(croppedImage);
        if (true == res) {
            setFileUrl(null);
            setCroppedImage(null);
            toast.success(t("PERSONAL_INFO.role_update_to_owner"));
            setEditing('');
        } else {
            toast.error(t("PERSONAL_INFO.something_went_wrong"));
        }
        setLoading(false);
    }

    return (
        <Fragment>
            <Seo title={'Personal info'} schema={{}} />
            <section className="author-page-section personal-info active-section" section-name="personal-info">
                <h2>{t("PERSONAL_INFO.personal_info")}</h2>
                <div className={`info-wrapper ${(!!editing && editing != 'avatar') ? 'none-editable' : ''}`}>
                    <div className={`${editing == 'avatar' ? 'd-none' : ''} wrapper-header`}>
                        <h3>{t("PERSONAL_INFO.photo")}</h3>
                        <div className="d-flex justify-content-between user-image-wrapper">
                            <div className="author-image">
                                <img src={!!imageUrl ? imageUrl : avatar} alt="" id="author-image" />
                            </div>
                            <div className="buttons-wrapper">
                                <button id="delete_image" className="del info-btn">{t("PERSONAL_INFO.delete")}</button>
                                <button id="update_image" className="upd info-btn" onClick={() => { setEditingScreen('avatar') }}>{t("PERSONAL_INFO.update")}</button>
                            </div>
                        </div>
                    </div>
                    {editing == 'avatar' ?
                        <div className={`${editing == 'avatar' ? '' : 'd-none'} image-editing-view`}>
                            <div className="d-flex flex-column">
                                <button id="cancel-image-editing" className="info-btn align-self-end" onClick={() => { closeEditing('avatar') }}>{t("PERSONAL_INFO.cancel")}</button>
                                <div className="d-flex image-editing-view-wrapper">
                                    <div className="image-preview">
                                        <ReactCrop
                                            crop={crop}
                                            onChange={c => cropImage(c)}
                                            ruleOfThirds={true}
                                            minWidth={100}
                                            minHeight={100}
                                            maxWidth={250}
                                            maxHeight={250}
                                            aspect={1}
                                            keepSelection={true}
                                            style={{
                                                maxWidth: '350px',
                                                maxHeight: '350px',
                                            }}
                                        >
                                            <img src={fileUrl} alt="" ref={imagePreview} />
                                        </ReactCrop>
                                    </div>
                                    <div className="align-self-end img-btns-wrapper d-flex">
                                        <label id="upload-file" className="img-btn">
                                            <input type="file" accept="image/*" id="image-upload-input" hidden onChange={(e) => readFile(e)} />
                                            {t("PERSONAL_INFO.upload_file")}
                                        </label>
                                        <input type="submit" value={t("PERSONAL_INFO.save")} className="save-btn img-btn" disabled={!croppedImage}
                                            id="save-image-btn" onClick={() => { saveValue('avatar') }} />
                                        {loading == true
                                            ? <div className="d-inline-block">
                                                <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        : ''
                    }
                </div>
                <div className={`info-wrapper ${(!!editing && editing != 'name') ? 'none-editable' : ''}`}>
                    <div className={`${editing == 'name' ? 'd-none' : ''} wrapper-header`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.name")}</h3>
                            <div className="buttons-wrapper">
                                <button className="edit info-btn" onClick={() => { setEditingScreen('name') }}>{t("PERSONAL_INFO.edit")}</button>
                            </div>
                        </div>
                        <p>{firstName} {lastName}</p>
                    </div>
                    <div className={`${editing == 'name' ? '' : 'd-none'} name-editing-wrapper editing-wrapper`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.name")}</h3>
                            <button id="cancel-editing" className="info-btn align-self-end cancel-btn" onClick={() => { setEditingScreen('') }}>{t("PERSONAL_INFO.cancel")}</button>
                        </div>
                        <p>{t("PERSONAL_INFO.legal_name")}</p>
                        <div className="group d-flex">
                            <div className="inp-group d-flex flex-column">
                                <label >{t("PERSONAL_INFO.name")}</label>
                                <input type="text" placeholder={t("PERSONAL_INFO.first_name")} value={form.firstName} onChange={(e) => { setFormValues({ 'firstName': e.target.value }) }} />
                            </div>
                            <div className="inp-group d-flex flex-column">
                                <label>{t("PERSONAL_INFO.last_name")}</label>
                                <input type="text" placeholder={t("PERSONAL_INFO.last_name")} value={form.lastName} onChange={(e) => { setFormValues({ 'lastName': e.target.value }) }} />
                            </div>
                        </div>
                        <div className=" d-flex align-items-end">
                            <input type="submit" value={t("PERSONAL_INFO.save")} className="save-btn" onClick={() => { saveValue('name') }} />
                            {loading == true
                                ? <div className="d-inline-block">
                                    <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                </div>
                                : ''
                            }
                        </div>
                    </div>

                </div>
                <div className={`info-wrapper ${(!!editing && editing != 'gender') ? 'none-editable' : ''}`}>
                    <div className={`${editing == 'gender' ? 'd-none' : ''} wrapper-header`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.gender")}</h3>
                            <div className="buttons-wrapper">
                                <button className="edit info-btn" onClick={() => { setEditingScreen('gender') }}>{t("PERSONAL_INFO.edit")}</button>
                            </div>
                        </div>
                        <p>{!!gender ? gender : t("PERSONAL_INFO.not_specified")}</p>
                    </div>
                    <div className={`${editing == 'gender' ? '' : 'd-none'} editing-wrapper`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.gender")}</h3>
                            <button id="cancel-editing"
                                className="info-btn align-self-end cancel-btn" onClick={() => { setEditingScreen('') }}>{t("PERSONAL_INFO.cancel")}</button>
                        </div>
                        <div className="group d-flex">
                            <select name="gender" id="gender-select" value={form.gender} onChange={(e) => { handleGenderSelectChange() }} ref={genderSelect}>
                                <option value="" >{t("PERSONAL_INFO.gender")}</option>
                                <option value="Female">{t("PERSONAL_INFO.female")}</option>
                                <option value="Male">{t("PERSONAL_INFO.male")}</option>
                                <option value="Not specified">{t("PERSONAL_INFO.not_specified")}</option>
                            </select>
                        </div>
                        <div className=" d-flex align-items-end">
                            <input type="submit" value={t("PERSONAL_INFO.save")} className="save-btn" onClick={() => { saveValue('gender') }} />
                            {loading == true
                                ? <div className="d-inline-block">
                                    <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                <div className={`info-wrapper ${(!!editing && editing != 'email') ? 'none-editable' : ''}`}>
                    <div className={`${editing == 'email' ? 'd-none' : ''} wrapper-header`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.email_adress")}</h3>
                            <div className="buttons-wrapper">
                                <button className="edit info-btn" onClick={() => { setEditingScreen('email') }}>{t("PERSONAL_INFO.edit")}</button>
                            </div>
                        </div>
                        <p>{email}</p>
                    </div>
                    <div className={`${editing == 'email' ? '' : 'd-none'} editing-wrapper`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.email_adress")}</h3>
                            <button id="cancel-editing"
                                className="info-btn align-self-end cancel-btn" onClick={() => { setEditingScreen('') }}>{t("PERSONAL_INFO.cancel")}</button>
                        </div>
                        <div className="group d-flex">
                            <input type="email" placeholder={t("PERSONAL_INFO.your_email")} value={form.email} onChange={(e) => { setFormValues({ 'email': e.target.value }) }} />
                        </div>
                        <div className=" d-flex align-items-end">
                            <input type="submit" value={t("PERSONAL_INFO.save")} className="save-btn" onClick={() => { saveValue('email') }} />
                            {loading == true
                                ? <div className="d-inline-block">
                                    <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                <div className={`info-wrapper ${(!!editing && editing != 'phone') ? 'none-editable' : ''}`}>
                    <div className={`${editing == 'phone' ? 'd-none' : ''} wrapper-header`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.phone_numbers")}</h3>
                            <div className="buttons-wrapper">
                                <button className="edit info-btn" onClick={() => { setEditingScreen('phone') }}>{t("PERSONAL_INFO.edit")}</button>
                            </div>
                        </div>
                        <p>{!!phone ? phone : 'Add a number so confirmed guests can get in touch'}</p>
                    </div>
                    <div className={`${editing == 'phone' ? '' : 'd-none'} editing-wrapper`}>
                        <div className="d-flex justify-content-between">
                            <h3>{t("PERSONAL_INFO.phone_numbers")}</h3>
                            <button id="cancel-editing"
                                className="info-btn align-self-end cancel-btn" onClick={() => { setEditingScreen('') }}>{t("PERSONAL_INFO.cancel")}</button>
                        </div>
                        <div className="group d-flex">
                            <input type="text" placeholder="+44 (__) ___ + ____" value={form.phone} onChange={(e) => { setFormValues({ 'phone': e.target.value }) }} />
                        </div>
                        <div className=" d-flex align-items-end">
                            <input type="submit" value={t("PERSONAL_INFO.save")} className="save-btn" onClick={() => { saveValue('phone') }} />
                            {loading == true
                                ? <div className="d-inline-block">
                                    <Loading style={{ width: '44px', height: '44px' }} spinnerStyle={{ borderWidth: '3px' }} />
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                {role == 'renter' &&
                    <div className="info-wrapper">
                        <h2 className="mt-30">{t("PERSONAL_INFO.role")}</h2>
                        <div className="wrapper-header">
                            <div className="d-flex justify-content-between">
                                <div className="buttons-wrapper">
                                    <button className="del info-btn" onClick={handleShowBecameOwnerModal}>{t("PERSONAL_INFO.became_owner")}</button>
                                </div>
                            </div>
                        </div>
                        <Modal id="BecameOwnerModal" className="" show={showBecameOwnerModal} onHide={handleCloseBecameOwnerModal} centered scrollable>
                            <Modal.Header closeButton>
                                <h5 className="modal-title" id="BecameOwnerModalCenteredScrollableTitle">
                                {t("PERSONAL_INFO.became_owner")}?
                                </h5>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="list mt-2">
                                    <div className="list-item">
                                        <div><span></span></div>
                                        <p>{t("PERSONAL_INFO.role_change_alert")}</p>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="" className="img-btn btn-light" onClick={handleCloseBecameOwnerModal}>
                                {t("PERSONAL_INFO.cancel")}
                                </Button>
                                <Button variant="" className="save-btn btn-secondary" onClick={handleBecameOwnerRequest}>
                                {t("PERSONAL_INFO.confirm")}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                }
            </section>
        </Fragment >
    );
}

export default PersonalInfo;
