import parse from 'html-react-parser';
import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import star from '../../assets/img/reviews/Star.png';
const Reviews = ({ items }) => {
    const stars = [1, 2, 3, 4, 5];
    const { t } = useTranslation();
    return (
        <div className="reviews">
            <h5>{t("REVIEW.reviews")} <span className="reviews-count">({items?.length})</span></h5>
            <div className="reviews-wrapper">
                {items.map((item, key) => {
                    return (<div className="review-item" key={key}>
                        <div className="d-flex review-item-wrapper">
                            <div className="review-author-image">
                                <img src={item.image} alt="" />
                            </div>
                            <div className="d-flex flex-column review-info">
                                <div>
                                    <div className="stars d-flex">
                                        {stars.map((st, skey) => {
                                            if (st <= item.rate) {
                                                return <div className="star" key={skey}>
                                                    <img src={star} alt="" />
                                                </div>
                                            }
                                        })}
                                        {/* <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div>
                                        <div className="star">
                                            <img src={star} alt="" />
                                        </div> */}
                                    </div>
                                    <div>
                                        <p className="review-name me-1">{item.author}</p>
                                        <span className="review-date">{item.date}</span>
                                    </div>
                                </div>
                                <div>
                                    <p className="review-text">{item.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>)
                })}
            </div>
        </div>
    );
}

export default Reviews;
