
import React, { Fragment } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Seo from '../Components/seo';
import Footer from "../Layout/Footer/Footer";
import Header from "../Layout/Header/Header";

import "../assets/css/help-center.css";
import "./../assets/css/custom.css";
import "./../assets/css/style.css";
import { useTranslation } from "react-i18next";

const HelpCenter = () => {

    const guestMockData = [
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
    ];

    const hostMockData = [
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
        {
            title: 'Getting started',
            items: [
                'Age requirements for drivers',
                'Booking a car',
                'Not specified',
                'Not specified',
            ],
            opened: false,
        },
    ];

    const [guestData, setGuestData] = React.useState([]);
    const [hostData, setHostData] = React.useState([]);

    React.useEffect(() => {
        setGuestData(guestMockData);
        setHostData(hostMockData);
    }, []);

    const openGuestMore = (index) => {
        let newData = guestData.map((item, i) => {
            let newItem = {
                ...item,
                opened: index == i ? true : item.opened
            };
            return newItem;
        });
        setGuestData(newData);
    }

    const openHostMore = (index) => {
        let newData = hostData.map((item, i) => {
            let newItem = {
                ...item,
                opened: index == i ? true : item.opened
            };
            return newItem;
        });
        setHostData(newData);
    }
    const { t } = useTranslation();

    return (
        <Fragment>
            <Seo title={'Help Center'} schema={{}} />
            <Header />
            <main className="p-0">
                <section className="help-center">
                    <div className="container">
                        <h1>{t("HELP_CENTER.title")}</h1>

                        <Tabs
                            defaultActiveKey="guest"
                            id="help-center-tab"
                            className="mb-3 help-center-tabs-wrapper"
                        >
                            <Tab eventKey="guest" title="Guest" className="modal-tab-item">
                                <div className="row row-gap-58">
                                    {guestData?.length > 0
                                        ? <>
                                            {guestData?.map((item, key) => {
                                                return (<div className="col-md-3" key={key}>
                                                    <h2>{item.title}</h2>
                                                    <div className="accordion" id="accordionGettingStarted">
                                                        <ul className="accordion-header" id="GettingStarted">
                                                            {item.items?.length > 0
                                                                ? <>
                                                                    {item.items?.map((li, i) => {
                                                                        if (i < 3) {
                                                                            return (<li key={i}>{li}</li>)
                                                                        }
                                                                        return;
                                                                    })}
                                                                </>
                                                                : ''
                                                            }

                                                            {item.items?.length > 3
                                                                ? <>
                                                                    {!item.opened
                                                                        ? <li className="accordion-toggler" onClick={() => { openGuestMore(key) }}><span>{t("HELP_CENTER.show_all")} {item.items?.length} {t("HELP_CENTER.articles")}</span></li>
                                                                        : <>
                                                                            {item.items?.map((li, i) => {
                                                                                if (i >= 3) {
                                                                                    return (<li key={i}>{li}</li>)
                                                                                }
                                                                                return;
                                                                            })}
                                                                        </>
                                                                    }
                                                                </>
                                                                : ''
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>)
                                            })}
                                        </>
                                        : ''
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="host" title="Host" className="modal-tab-item">
                                <div className="row row-gap-58">
                                    {hostData?.length > 0
                                        ? <>
                                            {hostData?.map((item, key) => {
                                                return (<div className="col-md-3" key={key}>
                                                    <h2>{item.title}</h2>
                                                    <div className="accordion" id="accordionGettingStarted">
                                                        <ul className="accordion-header" id="GettingStarted">
                                                            {item.items?.length > 0
                                                                ? <>
                                                                    {item.items?.map((li, i) => {
                                                                        if (i < 3) {
                                                                            return (<li key={i}>{li}</li>)
                                                                        }
                                                                        return;
                                                                    })}
                                                                </>
                                                                : ''
                                                            }

                                                            {item.items?.length > 3
                                                                ? <>
                                                                    {!item.opened
                                                                        ? <li className="accordion-toggler" onClick={() => { openHostMore(key) }}><span>{t("HELP_CENTER.show_all")} {item.items?.length} {t("HELP_CENTER.articles")}</span></li>
                                                                        : <>
                                                                            {item.items?.map((li, i) => {
                                                                                if (i >= 3) {
                                                                                    return (<li key={i}>{li}</li>)
                                                                                }
                                                                                return;
                                                                            })}
                                                                        </>
                                                                    }
                                                                </>
                                                                : ''
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>)
                                            })}
                                        </>
                                        : ''
                                    }
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>
    );
}

export default HelpCenter;
